import { useCallback } from 'react';
import { cx } from '@emotion/css';
import { TitleBar } from '@procurenetworks/procure-component-library';
import { SnackbarService } from 'app/components/Snackbar';
import routes from 'app/consts/routes';
import Manufacturer from 'app/i18n/Manufacturers';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import { titlebarStyles } from 'app/modules/components/EntityManager/styles';
import { CreateManufacturerInput } from 'app/types/schema';
import Box from 'app/ui-components';

import ManufacturerForm from '../../components/ManufacturerForm';
import { useCreateManufacturerMutation } from './graphql/mutations/generated/addManufacturer';

const DEFAULT_VALUES: CreateManufacturerInput = {
  name: '',
  url: '',
  phoneNo: '',
  emailId: '',
};

function AddManufacturer({ history }: RouteComponentProps) {
  const [{ fetching }, createManufacturer] = useCreateManufacturerMutation();

  const onSubmit = useCallback(
    ({ name, url, phoneNo, emailId }: CreateManufacturerInput) => {
      createManufacturer({ input: { url, phoneNo, emailId, name } }).then((response) => {
        if (response.data?.createManufacturer?.manufacturer?.id) {
          SnackbarService.show({ message: 'This manufacturer has been successfully created.' });
          history.replace(routes.Manufacturers());
        }
      });
    },
    [createManufacturer, history],
  );

  const onCancel = useCallback(() => {
    history.replace(routes.Manufacturers());
  }, [history]);

  return (
    <Box className="bg-white px-24 pt-0 pb-16">
      <Box className={cx('space-y-40', titlebarStyles)}>
        <TitleBar title={Manufacturer.NewManufacturer} />
        <Box className="!mt-[24px] w-full sm:max-w-[382px]  md:min-w-[382px] lg:max-w-[382px] xl:max-w-[382px]">
          <ManufacturerForm
            defaultValues={DEFAULT_VALUES}
            disabled={fetching}
            onCancel={onCancel}
            onSubmit={onSubmit}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default withRouter(AddManufacturer);
