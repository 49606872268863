import { useCallback } from 'react';
import { Box, Grid } from '@mui/material';
import { Button } from '@procurenetworks/procure-component-library';
import { SnackbarService } from 'app/components/Snackbar';
import routes from 'app/consts/routes';
import Assets from 'app/i18n/Assets';
import Common from 'app/i18n/Common';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import EntityManager from 'app/modules/components/EntityManager';

import { useCreateReserveTransactionsMutation } from '../../graphql/mutations/generated/createReserveTransactions';
import useReserveState from '../../hook/useReserveState';
import { getReserveAssetsTableColumn } from '../../utils/columns';
import ReserveForm from '../ReserveForm';

const ReserveAssetFormAndTable = (Props: RouteComponentProps) => {
  const { history } = Props;
  const [{ fetching: createAssetReserveTransaction }, onCreateAssetReserveTransaction] =
    useCreateReserveTransactionsMutation();

  const {
    formState,
    rows,
    table,
    count,
    onFormSubmit,
    resetTableAndForm,
    transactions,
    onRemoveRow,
    itemInStock,
  } = useReserveState();

  const onReserveClick = useCallback(() => {
    onCreateAssetReserveTransaction({
      input: {
        entities: transactions,
      },
    }).then((response) => {
      if (response?.data?.createReserveTransactions?.success) {
        SnackbarService.show({
          message: Assets.SuccessMessages.ReserveTransactionCreated,
        });
        // resetTableAndForm();
        const createdTransactions = response?.data?.createReserveTransactions.transactions;
        if (createdTransactions?.length) {
          // TODO: Remove this timeout and use the proper way to redirect to the confirmation page
          // Currently kept since notification component reloads on tab changes and it needs to be updated.
          // To remove this, app header needs to be removed from individual layouts and needs to be centralized.
          setTimeout(() => {
            history.push(
              routes.ConfirmationAssetEditTransactions(createdTransactions[0].parentTransactionId),
            );
          }, 1500);
        }
      } else {
        console.error('[Failed to Reserve Assets]', response);
      }
    });
  }, [transactions, onCreateAssetReserveTransaction, resetTableAndForm, history]);

  return (
    <EntityManager className="border-grey300 !p-t-[0px] rounded-bl-[12px] pt-[0px]">
      <EntityManager.Title
        documentTitle={Assets.ReserveAsset}
        title={Assets.ReserveAsset}
        hasTopDivider
      />
      <Box className="flex flex-wrap gap-[24px] lg:w-full xl:w-full">
        <Box
          className="
            w-full
            pt-6 
            md:m-[0px]
            md:min-w-[416px]
            md:max-w-[416px]
            lg:m-[0px]
            lg:min-w-[416px] 
            lg:min-w-[416px]
            xl:m-[0px]
            xl:max-w-[416px]
            xl:max-w-[416px]
        ">
          <ReserveForm formState={formState} onSubmit={onFormSubmit} itemInStock={itemInStock} />
        </Box>
        <Box className="mt-[24px] flex-1">
          <Box
            className="h-full rounded-[12px] border border-grey-300"
            sx={{
              boxShadow:
                '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
            }}>
            <EntityManager.Table
              pagination
              actions={table.actions}
              columns={getReserveAssetsTableColumn()}
              data={rows}
              extraProps={{
                onRemoveRow,
              }}
              loading={createAssetReserveTransaction}
              persistKey="assetsReserve_table"
              setState={table.setState}
              state={table.state}
              total={count}
              paginationWrapperClass="p-[24px]"
            />

            <Box className="flex justify-end gap-[16px] p-[24px]">
              <Button
                disabled={transactions.length === 0 || createAssetReserveTransaction}
                theme="success"
                onClick={onReserveClick}
                classes="min-w-[119px] h-[44px]">
                {Assets.Actions.ReserveAssets}
              </Button>
              <Button
                disabled={transactions.length === 0 || createAssetReserveTransaction}
                onClick={resetTableAndForm}
                classes="min-w-[94px] h-[44px]">
                {Common.Actions.Cancel}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </EntityManager>
  );
};

export default withRouter(ReserveAssetFormAndTable);
