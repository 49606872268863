import { useCallback, useContext, useEffect } from 'react';
import { SnackbarService } from 'app/components/Snackbar';
import Orders from 'app/i18n/Orders';
import OrderSteps from 'app/modules/orders/components/OrderSteps';
import { ORDER_STEP } from 'app/modules/orders/components/OrderSteps/utils';
import useOrderRequestState, {
  UseOrderRequestStateReturnType,
} from 'app/modules/orders/hook/useOrderRequestState';
import OrderDeliveryDetails from 'app/modules/orders/views/OrderRequest/components/OrderDeliveryDetails';

import { DeliveryDetailInput } from '../../components/OrderDeliveryDetailForm/types';
import OrderItemRequest from './components/OrderItemRequest';
import { useCreateOrderRequestMutation } from '../../graphql/mutations/generated/createOrderRequest';
import { createExternalOrderDto, createInternalOrderDto } from './utils/dto';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import {
  OrderRequestContext,
  OrderRequestContextService,
} from '../../provider/OrderRequestContextProvider/OrderRequestContextProvider';
import routes from '../../../../consts/routes';
import { useCreateExternalOrderRequestMutation } from '../../graphql/mutations/generated/createExternalOrderRequest';
import { OrderRequestInput } from './components/OrderRequestForm/type';
import _ from 'lodash';
import { WarningService } from '../../../../components/WarningAlert';
import { Box, Divider } from '@mui/material';

function OrderRequest(props: RouteComponentProps) {
  const { history } = props;
  const { isRecreateOrder, orderItemLength } = useContext(OrderRequestContext);
  const {
    state,
    orderItemRequest,
    onPrevStep,
    onNextStep,
    deliveryDetailState,
    deliveryDetailsAction,
    onResetOrderRequest,
    getOrderRequestDtoData,
  } = useOrderRequestState();

  const [{ fetching: creatingOrder }, onCreateOrderRequest] = useCreateOrderRequestMutation();
  const [{ fetching: creatingExternalOrder }, onCreateExternalOrderRequest] =
    useCreateExternalOrderRequestMutation();

  const handlePostOrderCreateFlow = useCallback(() => {
    SnackbarService.show({
      message: Orders.SuccessMessages.OrderCreated,
    });
    onResetOrderRequest();

    if (isRecreateOrder) {
      history.push(routes.OrderRequest());
    }
  }, [isRecreateOrder, onResetOrderRequest, history]);

  const createExternalOrder = useCallback(
    async (
      deliveryDetails: DeliveryDetailInput,
      orderRequestItems: OrderRequestInput[],
      partnerTenantId: string,
    ) => {
      const response = await onCreateExternalOrderRequest({
        input: createExternalOrderDto({
          deliveryDetails,
          orderRequestItems,
          partnerTenantId: partnerTenantId,
        }),
      });
      if (response?.data?.createExternalOrderRequest?.orderRequest?.id) {
        handlePostOrderCreateFlow();
      }
    },
    [onCreateExternalOrderRequest, handlePostOrderCreateFlow],
  );

  const createInternalOrder = useCallback(
    async (deliveryDetails: DeliveryDetailInput, orderRequestItems: OrderRequestInput[]) => {
      const response = await onCreateOrderRequest({
        input: createInternalOrderDto({
          deliveryDetails,
          orderRequestItems,
        }),
      });

      if (response?.data?.createOrderRequest?.orderRequest?.id) {
        handlePostOrderCreateFlow();
      }
    },
    [onCreateOrderRequest, handlePostOrderCreateFlow],
  );

  const onCreateOrder = useCallback(
    async (deliveryDetails: DeliveryDetailInput) => {
      const { isExternalOrder, orderRequestItems, partnerTenantId } = getOrderRequestDtoData();

      if (creatingOrder || creatingExternalOrder) {
        return;
      }

      if (isExternalOrder) {
        await createExternalOrder(deliveryDetails, orderRequestItems, partnerTenantId);
      } else {
        await createInternalOrder(deliveryDetails, orderRequestItems);
      }
    },
    [
      createExternalOrder,
      createInternalOrder,
      getOrderRequestDtoData,
      creatingOrder,
      creatingExternalOrder,
    ],
  );

  const renderOrderStep = (state: UseOrderRequestStateReturnType['state']) => {
    switch (state.activeOrderStep) {
      case ORDER_STEP.ORDER_REQUEST:
        return (
          <OrderItemRequest
            actions={orderItemRequest.actions}
            state={orderItemRequest.state}
            onNextStep={onNextStep}
          />
        );

      case ORDER_STEP.DELIVERY_DETAILS:
        return (
          <OrderDeliveryDetails
            actions={deliveryDetailsAction}
            disabled={creatingOrder || creatingExternalOrder}
            state={deliveryDetailState}
            onCreateOrder={onCreateOrder}
            onPrevStep={onPrevStep}
          />
        );
      default:
        return undefined;
    }
  };

  return (
    <>
      <Box className='w-full bg-white border-x-[1px] border-grey-300'>
        <Divider className='mx-[24px]'/>
      </Box>
      
      <OrderSteps activeStep={state.activeOrderStep} />
      
      {renderOrderStep(state)}
    </>
  );
}

export default withRouter(OrderRequest);
