import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  useForm,
  UseFormGetValues,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';
import { Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Button, TitleBar } from '@procurenetworks/procure-component-library';
import { omit } from 'lodash';
import { useQuery } from 'urql';
import FormSelectInput from 'app/components/ProcureForm/FormSelectInput';
import FormTextInput from 'app/components/ProcureForm/FormTextInput';
import routes from 'app/consts/routes';
import Common from 'app/i18n/Common';
import Contact from 'app/i18n/Contact';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import DeleteConfirmationModal from 'app/modules/components/EntityManager/DeleteConfirmationModal';
import { ButtonSize } from 'app/types/button';
import { CheckEditDuplicateEnum } from 'app/types/contact';
import Box from 'app/ui-components/Box';
import { removeMultipleSpaces } from 'app/utils/removeMultipleSpaces';
import ScrollToError from 'app/utils/ScrollToError';

import { useAccessControl } from '../../../../components/AccessControl';
import FormPhoneInput from '../../../../components/ProcureForm/FormPhoneInput';
import { SnackbarService } from '../../../../components/Snackbar';
import {
  AllowedPermissionActionsEnum,
  AllowedPermissionsSubjectEnum,
  ContactSchema,
  CreateContactInput,
  SortOrderEnum,
} from '../../../../types/schema';
import useCurrentUser from '../../../auth/hooks/useCurrentUser';
import { INVENTORY_FORM_RULES } from '../../utils/validation';
import AddressFormModal from '../addressFormModal';
import { useContactListQuery } from '../contactSearch/graphql/queries/generated/contatct';
import { useCreateContactMutation } from './graphql/mutations/generated/createContact';
import { useDeleteContactsMutation } from './graphql/mutations/generated/deleteContact';
import { useUpdateContactMutation } from './graphql/mutations/generated/updateConact';
import { AddressListDropDownDocument } from './graphql/queries/generated/address';
import { useCompnayListDropDownQuery } from './graphql/queries/generated/company';
import { useContactDetailsQuery } from './graphql/queries/generated/contact';
import { CONTACT_FORM_RULES } from './utils';

export type ContactFormData = {
  companyId: string;
  firstName: string;
  lastName: string;
  title: string;
  email: string;
  officeContactNumber: string;
  homeContactNumber: string;
  mobileContactNumber: string;
  fax: string;
  addressId: string;
};

type ContactsProps = RouteComponentProps & {
  onCloseModal?: () => void;
  id?: string;
  onCheckDuplicate: (
    input: CreateContactInput,
    checkType: string,
    contacts: ContactSchema[],
  ) => boolean;
  control?: Control;
  reset?: any;
  setValue?: UseFormSetValue<CreateContactInput>;
  getValues?: UseFormGetValues<CreateContactInput>;
  errors?: FieldErrors;
  hideTitle?: boolean;
  hideActions?: boolean;
  formClassName?: string;
  defaultValues?: CreateContactInput;
  isContactModal?: boolean;
  isDuplicate?: boolean;
  hideActionsEdit?: boolean;
  setIsEditDuplicate?: any;
  addressOpen?: boolean;
  setAddressOpen?: any;
  setErrors?: UseFormSetError<CreateContactInput>;
};

type FormWrapperProps = {
  isModal?: boolean;
  formControl?: Control;
  formClassName?: string;
  children?: React.ReactNode;
  onFormSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
};

const FormWrapper = ({
  isModal,
  formControl,
  formClassName,
  children,
  onFormSubmit,
}: FormWrapperProps) => {
  if (formControl) {
    return <Box className={`w-full pt-12 ${formClassName}`}>{children}</Box>;
  } else {
    return (
      <form className={`${isModal ? 'ml-0 md:w-[560px]' : 'w-full'} pt-12`} onSubmit={onFormSubmit}>
        {children}
      </form>
    );
  }
};

const ContactForm = (props: RouteComponentProps<{ id: string }> & ContactsProps) => {
  const [isViewMode, setIsViewMode] = useState(false);
  const [isOpenDeleteConfirm, setIsOpenDeleteConfirm] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const {
    history,
    match,
    onCloseModal: onHandleContactModal,
    onCheckDuplicate,
    hideActions,
    defaultValues,
    hideTitle = hideActions,
    formClassName,
    control: formControl,
    errors: formErrors,
    reset: formReset,
    setValue: formSetValue,
    getValues: formGetValues,
    isContactModal = false,
    isDuplicate,
    id,
    setIsEditDuplicate,
    hideActionsEdit,
    addressOpen,
    setAddressOpen,
  } = props;
  const { workspacePermissions } = useCurrentUser();
  const contactId = isContactModal ? id ?? '' : match.params.id || '';
  const companyId = isContactModal ? match.params.id ?? '' : '';
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);

  const canCreate = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Create,
    AllowedPermissionsSubjectEnum.Contact,
  );

  const canEdit = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Edit,
    AllowedPermissionsSubjectEnum.Contact,
  );

  const canDelete = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Delete,
    AllowedPermissionsSubjectEnum.Contact,
  );

  const canCreateAddress = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Create,
    AllowedPermissionsSubjectEnum.Address,
  );

  const [{ fetching, data: dataList }] = useContactListQuery({
    variables: {
      limit: 10000,
    },
  });
  const contacts = useMemo(() => {
    return (
      dataList?.contacts?.edges?.map((contact: any) => {
        return { ...contact.node };
      }) || []
    );
  }, [dataList]);

  const [{ data }] = useContactDetailsQuery({
    variables: { filters: { contactIds: [String(contactId)] } },
    pause: !Boolean(contactId),
  });

  const [{ data: companyList }] = useCompnayListDropDownQuery({
    variables: {
      sorts: [
        {
          sortField: 'companyName',
          sortOrder: SortOrderEnum.Asc,
        },
      ],
      limit: 10000,
    },
  });

  const [{ fetching: onUpdateFetching }, onUpdateContact] = useUpdateContactMutation();

  const [{ fetching: onCreateFetching }, onCreateContact] = useCreateContactMutation();

  const [{ fetching: deleteContactLoading }, executeDeleteContact] = useDeleteContactsMutation();

  const condition = onHandleContactModal ? false : contactId ? true : false;

  useEffect(() => {
    setIsViewMode(condition);
  }, [contactId]);

  const canEditOrDelete = canEdit || canDelete;

  const contactData = useMemo(() => {
    const contact = contactId && data?.contacts?.edges[0]?.node;
    if (hideActionsEdit && contact) {
      const {
        companyId,
        fax,
        firstName,
        lastName,
        email,
        homeContactNumber,
        mobileContactNumber,
        officeContactNumber,
        title,
        address,
        id,
      } = contact;
      return {
        companyId: companyId,
        firstName: firstName || '',
        lastName: lastName || '',
        title: title || '',
        email: email || '',
        officeContactNumber: officeContactNumber || '',
        homeContactNumber: homeContactNumber || '',
        mobileContactNumber: mobileContactNumber || '',
        fax: fax || '',
        addressId: address?.id || '',
        id: id || '',
      };
    }
    return undefined;
  }, [contactId, data, hideActionsEdit]);

  const companies = useMemo(() => {
    return companyList?.companies?.edges?.map(({ node }) => node) || [];
  }, [companyList?.companies?.edges]);

  const contactFormDefaultValues = useMemo(() => {
    return (
      contactData ||
      defaultValues || {
        companyId: '',
        firstName: '',
        lastName: '',
        title: '',
        email: '',
        officeContactNumber: '',
        homeContactNumber: '',
        mobileContactNumber: '',
        fax: '',
        addressId: '',
      }
    );
  }, [contactData, defaultValues]);

  const [newCompanyId, setNewCompanyId] = useState(contactFormDefaultValues.companyId);

  const initialCountryCode = { home: {}, mobile: {}, office: {}, fax: {} };

  const [countryCodes, setCountryCodes] = useState<{
    home: any;
    mobile: any;
    office: any;
    fax: any;
  }>(initialCountryCode);

  const [result, getAddressDropdown] = useQuery({
    query: AddressListDropDownDocument,
    variables: {
      filters: { companyIds: [isContactModal && !isDuplicate ? companyId : newCompanyId] },
      sorts: [{ sortField: 'addressName', sortOrder: SortOrderEnum.Asc }],
      limit: 10000,
    },
    pause: !Boolean(newCompanyId || companyId),
  });

  const addresses = useMemo(() => {
    return result?.data?.addresses?.edges?.map(({ node }: { node: any }) => node) || [];
  }, [result?.data?.addresses?.edges]);

  useEffect(
    () => setNewCompanyId(contactFormDefaultValues.companyId),
    [contactFormDefaultValues.companyId],
  );

  useEffect(() => {
    if (newCompanyId || (isContactModal && companyId)) {
      getAddressDropdown();
    }
  }, [isContactModal, companyId, newCompanyId, getAddressDropdown]);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    mode: 'all',
    defaultValues: contactFormDefaultValues,
    reValidateMode: 'onChange',
  });

  const controller = (formControl ?? control) as Control;
  const errorList = formErrors ?? errors;
  const reseter = formReset ?? reset;
  const setValues = (formSetValue ?? setValue) as UseFormSetValue<CreateContactInput>;
  const getValue = (formGetValues ?? getValues) as UseFormGetValues<CreateContactInput>;
  const formRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    reseter(contactFormDefaultValues);
  }, [contactFormDefaultValues, contactId, contactData]);

  useEffect(() => {
    if (!isDuplicate) {
      setValues('companyId', companyId);
    } else {
      setValues('companyId', contactFormDefaultValues.companyId);
    }
  }, [isDuplicate, companyId]);

  useEffect(() => {
    const address = addresses.find(
      (addressData: any) => addressData.id === contactFormDefaultValues.addressId,
    );
    setValues('addressId', getValue('addressId') || address?.id || '');
  }, [addresses, contactFormDefaultValues.addressId]);

  const onSubmit = useCallback(
    (input: CreateContactInput) => {
      input = {
        ...input,
        email: input?.email?.trim(),
      };
      const inputValue = omit({ ...input }, 'id') as ContactSchema;

      const _isDuplicate = onCheckDuplicate(
        input,
        !hideActionsEdit ? CheckEditDuplicateEnum.SUBMIT : CheckEditDuplicateEnum.EDIT,
        contacts,
      );

      if (!_isDuplicate) {
        if (contactId) {
          onUpdateContact({ input: { ...inputValue, contactId } }).then((response) => {
            if (response.data?.updateContact.contact?.id) {
              setIsViewMode(true);
              SnackbarService.show({
                message: Contact.SuccessMessages.ContactUpdated,
              });
              if (onHandleContactModal) {
                return onHandleContactModal();
              }
              history.replace(routes.Contacts(response.data?.updateContact.contact?.id));
            } else {
              console.error('[Update Category] Failed', response);
            }
          });
        } else {
          onCreateContact({ input }).then((response) => {
            if (response.data?.createContact.contact?.id) {
              SnackbarService.show({
                message: Contact.SuccessMessages.ContactCreated,
              });
              if (onHandleContactModal) {
                return onHandleContactModal();
              }
              history.replace(routes.Contacts(response.data?.createContact.contact?.id));
            } else {
              console.error('[Create Contact] Failed', response);
            }
          });
        }
      }
    },
    [history, contactId, contacts, hideActionsEdit],
  );

  const onFormSubmit = useMemo(
    () =>
      handleSubmit(
        (values) => {
          const updatedValues = {
            ...values,
            firstName: removeMultipleSpaces(values.firstName),
            lastName: removeMultipleSpaces(values.lastName),
            title: removeMultipleSpaces(values?.title || ''),
            email: removeMultipleSpaces(values?.email || ''),
          };
          onSubmit(updatedValues);
        },
        (errors) => {
          // @ts-ignore
          ScrollToError(errors, formRef);
        },
      ),
    [handleSubmit, onSubmit],
  );

  const onCloseModal = () => {
    setIsOpen(false);
  };

  const onOpenModal = () => {
    setIsOpen(true);
  };

  const onCancelConfirm = () => {
    setIsOpenDeleteConfirm(false);
  };

  const onCompanyChangeHandle = (companyId: string) => {
    setNewCompanyId(companyId);
    setValues('companyId', companyId);
    setValues('addressId', '');
  };

  const onHandleCancel = (event: any) => {
    event.preventDefault();
    setNewCompanyId(contactFormDefaultValues.companyId);
    reseter(contactFormDefaultValues);
    if (contactId) {
      setIsViewMode(true);
    }

    if (onHandleContactModal) {
      return onHandleContactModal();
    }
    if (contactId) {
      history.replace(routes.Contacts(contactId));
    }
  };

  const onHandleEdit = useCallback(() => {
    setIsViewMode(false);
    if (setIsEditDuplicate) {
      setIsEditDuplicate(true);
    }
  }, [setIsViewMode]);

  const onDelete = useCallback(async () => {
    const response = await executeDeleteContact({ input: { contactIds: [contactId] } });
    if (response.data?.deleteContacts?.success) {
      SnackbarService.show({
        message: Contact.SuccessMessages.ContactDeleted,
      });
    }
    setIsOpenDeleteConfirm(false);
    history.replace(routes.Contacts(''));
  }, [executeDeleteContact, contactId]);

  const isDisabledInput =
    onCreateFetching || onUpdateFetching || hideActionsEdit ? isViewMode : hideActionsEdit;
  const formPadding = isContactModal && { '&.MuiGrid-item': { paddingLeft: 0 } };
  const canHideCreate = !canCreate && !match.params?.id;

  if (canHideCreate) {
    return null;
  }
  return (
    <div ref={formRef} className="min-w-full">
      <Box className="flex min-h-[54px] items-center">
        {!hideTitle && !isContactModal && (
          <Typography className="text-[18px] font-semibold text-grey-900 ">
            {Contact.Contact}
          </Typography>
        )}
      </Box>
      <Divider />
      {hideActionsEdit && contactId && canCreate && !isContactModal && (
        <Button
          classes="max-w-[204px] h-[44px] my-[16px]"
          theme="success"
          onClick={() => history.replace(routes.Contacts(''))}>
          {Contact.AddNewContact}
        </Button>
      )}
      <FormWrapper
        formClassName={formClassName}
        formControl={formControl}
        isModal={isContactModal}
        onFormSubmit={onFormSubmit}>
        <>
          <Grid item sx={{ ...formPadding }} xs={12}>
            {hideActionsEdit && isViewMode && canEditOrDelete && (
              <Box className=" mb-[24px] flex justify-start gap-[16px]">
                {canEdit ? (
                  <Button classes="min-w-[94px] h-[44px]" theme="info" onClick={onHandleEdit}>
                    {Common.Actions.Edit}
                  </Button>
                ) : undefined}
                {canDelete ? (
                  <Button
                    classes="min-w-[94px] h-[44px]"
                    theme="danger"
                    onClick={() => setIsOpenDeleteConfirm(true)}>
                    {Common.Actions.Delete}
                  </Button>
                ) : undefined}
              </Box>
            )}
          </Grid>
          <Grid
            container
            alignItems="center"
            direction="row"
            justifyContent="center"
            paddingTop={1}
            spacing={2}
            sx={{ ...(isContactModal && { '&.MuiGrid-container': { marginLeft: 0 } }) }}
            xs={12}>
            <Grid item sx={{ ...formPadding }} xs={12}>
              <Controller
                control={controller}
                name="companyId"
                render={({ field, fieldState }) => (
                  <FormSelectInput
                    {...field}
                    isRequired
                    disabled={
                      isContactModal
                        ? isContactModal
                        : isDisabledInput || (!isDuplicate && isContactModal)
                    }
                    dropDownOpen={isDropDownOpen}
                    error={fieldState.error}
                    formLabel="Company Name"
                    items={companies}
                    label={Contact.FormLabels.CompanyName}
                    labelText="companyName"
                    valueText="id"
                    onChange={onCompanyChangeHandle}
                    onClose={() => setIsDropDownOpen(false)}
                    onOpen={() => setIsDropDownOpen(true)}
                  />
                )}
                rules={INVENTORY_FORM_RULES.companyId}
              />
            </Grid>

            <Grid item sx={{ ...formPadding }} xs={12}>
              <Controller
                control={controller}
                name="firstName"
                render={({ field, fieldState }) => (
                  <FormTextInput
                    {...field}
                    isRequired
                    disabled={isDisabledInput}
                    endAdornment={() => (
                      <h2
                        className="tetx-Gray-800 text-[12px] font-semibold"
                        style={{ paddingRight: '10px' }}>{`${field.value?.length || 0}/30`}</h2>
                    )}
                    error={fieldState.error}
                    formLabel={Contact.FormLabels.FirstName}
                    inputProps={{
                      maxLength: 30,
                    }}
                    label={Contact.FormLabels.FirstName}
                  />
                )}
                rules={INVENTORY_FORM_RULES.firstName}
              />
            </Grid>

            <Grid item sx={{ ...formPadding }} xs={12}>
              <Controller
                control={controller}
                name="lastName"
                render={({ field, fieldState }) => (
                  <FormTextInput
                    {...field}
                    isRequired
                    disabled={isDisabledInput}
                    endAdornment={() => (
                      <h2
                        className="tetx-Gray-800 text-[12px] font-semibold"
                        style={{ paddingRight: '10px' }}>{`${field.value?.length || 0}/30`}</h2>
                    )}
                    error={fieldState.error}
                    formLabel={Contact.FormLabels.LastName}
                    inputProps={{
                      maxLength: 30,
                    }}
                    label={Contact.FormLabels.LastName}
                  />
                )}
                rules={INVENTORY_FORM_RULES.lastName}
              />
            </Grid>
            <Grid item sx={{ ...formPadding }} xs={12}>
              <Controller
                control={controller}
                name="title"
                render={({ field, fieldState }) => (
                  <FormTextInput
                    {...field}
                    disabled={isDisabledInput}
                    error={fieldState.error}
                    formLabel={Contact.FormLabels.Title}
                    label={Contact.FormLabels.Title}
                  />
                )}
              />
            </Grid>
            <Grid item sx={{ ...formPadding }} xs={12}>
              <Controller
                control={controller}
                name="email"
                render={({ field, fieldState }) => (
                  <FormTextInput
                    {...field}
                    disabled={isDisabledInput}
                    error={fieldState.error}
                    formLabel={Contact.FormLabels.Email}
                    label={Contact.FormLabels.Email}
                  />
                )}
                rules={CONTACT_FORM_RULES.email}
              />
            </Grid>
            <Grid item sx={{ ...formPadding }} xs={12}>
              <Controller
                control={controller}
                name="officeContactNumber"
                render={({ field, fieldState }) => (
                  <FormPhoneInput
                    {...field}
                    disabled={isDisabledInput}
                    error={fieldState.error}
                    // label={Contact.FormLabels.OfficePhone}
                    formLabel={Contact.FormLabels.OfficePhone}
                    name="officeContactNumber"
                    placeholder={Contact.FormLabels.OfficePhone}
                    onCountryCodeChange={(updatedCountryCodes) => {
                      setCountryCodes({
                        ...countryCodes,
                        office: updatedCountryCodes,
                      });
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item sx={{ ...formPadding }} xs={12}>
              <Controller
                control={controller}
                name="homeContactNumber"
                render={({ field, fieldState }) => (
                  <FormPhoneInput
                    {...field}
                    disabled={isDisabledInput}
                    error={fieldState.error}
                    // label={Contact.FormLabels.HomePhone}
                    formLabel={Contact.FormLabels.HomePhone}
                    name="homeContactNumber"
                    placeholder={Contact.FormLabels.HomePhone}
                    onCountryCodeChange={(updatedCountryCodes) => {
                      setCountryCodes({
                        ...countryCodes,
                        home: updatedCountryCodes,
                      });
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item sx={{ ...formPadding }} xs={12}>
              <Controller
                control={controller}
                name="mobileContactNumber"
                render={({ field, fieldState }) => (
                  <FormPhoneInput
                    {...field}
                    disabled={isDisabledInput}
                    error={fieldState.error}
                    // label={Contact.FormLabels.MobilePhone}
                    formLabel={Contact.FormLabels.MobilePhone}
                    name="mobileContactNumber"
                    placeholder={Contact.FormLabels.MobilePhone}
                    onCountryCodeChange={(updatedCountryCodes) => {
                      setCountryCodes({
                        ...countryCodes,
                        mobile: updatedCountryCodes,
                      });
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item sx={{ ...formPadding }} xs={12}>
              <Controller
                control={controller}
                name="fax"
                render={({ field, fieldState }) => (
                  <FormPhoneInput
                    {...field}
                    disabled={isDisabledInput}
                    error={fieldState.error}
                    // label={Contact.FormLabels.Fax}
                    formLabel={Contact.FormLabels.Fax}
                    placeholder={Contact.FormLabels.Fax}
                    onCountryCodeChange={(updatedCountryCodes) => {
                      setCountryCodes({
                        ...countryCodes,
                        fax: updatedCountryCodes,
                      });
                    }}
                  />
                )}
                rules={CONTACT_FORM_RULES.fax}
              />
            </Grid>
            <Grid container paddingLeft={2} paddingTop={2} spacing={1} sx={{ ...formPadding }}>
              <Grid item xs>
                <Controller
                  control={controller}
                  name="addressId"
                  render={({ field, fieldState }) => (
                    <FormSelectInput
                      {...field}
                      isRequired
                      disabled={isDisabledInput}
                      dropDownOpen={addressOpen}
                      error={fieldState.error}
                      formLabel={Contact.FormLabels.Address}
                      // label={Contact.FormLabels.Address}
                      items={addresses}
                      labelText="addressName"
                      valueText="id"
                      onClose={() => setAddressOpen(false)}
                      onOpen={() => setAddressOpen(true)}
                    />
                  )}
                  rules={INVENTORY_FORM_RULES.address}
                />
              </Grid>
              {canCreateAddress ? (
                <Grid item className="mt-[36px] ml-[24px] !p-[0px]" xs="auto">
                  <Button
                    classes="min-w-[80px] h-[48px]"
                    disabled={!getValue('companyId') || isDisabledInput}
                    height={0}
                    size={ButtonSize.medium}
                    theme="success"
                    onClick={onOpenModal}>
                    {Common.Actions.Add}
                  </Button>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
            <Grid item sx={{ ...formPadding }} xs={12}>
              {!hideActions && !isViewMode && canCreate && (
                <Box className="mt-20 mb-20 flex justify-end gap-[16px]">
                  <Button
                    classes="min-w-[94px] h-[44px]"
                    disabled={onCreateFetching || onUpdateFetching}
                    loading={onCreateFetching || onUpdateFetching}
                    theme="success"
                    onClick={onFormSubmit}>
                    {Common.Actions.Save}
                  </Button>
                  <Button classes="min-w-[94px] h-[44px]" onClick={onHandleCancel}>
                    {Common.Actions.Cancel}
                  </Button>
                </Box>
              )}
              <DeleteConfirmationModal
                open={isOpenDeleteConfirm}
                onCancel={onCancelConfirm}
                onConfirm={onDelete}
                isLoading={deleteContactLoading}
              />
            </Grid>
          </Grid>
          {isOpen && (
            <AddressFormModal
              addressId={''}
              companyId={getValue('companyId')}
              isOpen={isOpen}
              onCancel={onCloseModal}
              onSavedAddress={(id) => {
                setValues('addressId', id || '');
                getAddressDropdown();
                onCloseModal();
              }}
            />
          )}
        </>
      </FormWrapper>
    </div>
  );
};
export default withRouter(ContactForm);
