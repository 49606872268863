import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, CustomIcons } from '@procurenetworks/procure-component-library';
import usePagination from 'app/hooks/usePagination';
import Contact from 'app/i18n/Contact';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import EntityManager from 'app/modules/components/EntityManager';
import useEntityManager from 'app/modules/components/EntityManager/useEntityManager';
import Stack from 'app/ui-components/Stack';

import { useAccessControl } from '../../../../components/AccessControl';
import { SnackbarService } from '../../../../components/Snackbar';
import {
  AllowedPermissionActionsEnum,
  AllowedPermissionsSubjectEnum,
} from '../../../../types/schema';
import useCurrentUser from '../../../auth/hooks/useCurrentUser';
import useContactSearchSuggestion from '../../hook/useContactSearchSuggestion';
import { useDeleteContactsMutation } from '../contactForm/graphql/mutations/generated/deleteContact';
import ContactFormModal from '../contactFormModel';
import { useContactsQuery } from './graphql/queries/generated/contact';
import { getContactTableColumns } from './utils/columns';
import { Box, InputAdornment, Typography } from '@mui/material';

const columns = getContactTableColumns();

const ContactList = (props: RouteComponentProps<{ id: string }>) => {
  const { match } = props;
  const [isOpen, setIsOpen] = useState(false);

  const { workspacePermissions } = useCurrentUser();

  const canCreate = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Create,
    AllowedPermissionsSubjectEnum.Contact,
  );

  const canEdit = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Edit,
    AllowedPermissionsSubjectEnum.Contact,
  );

  const canCreateOrEdit = canCreate || canEdit;

  const disabled = false;
  const onSubmit = () => {};
  const onCloseModal = () => {
    setIsOpen(false);
    setContactId('');
  };

  const onOpenModal = () => {
    setIsOpen(true);
  };
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const [editContactId, setContactId] = useState('');

  const onFormSubmit = useMemo(() => handleSubmit(onSubmit), [handleSubmit, onSubmit]);

  const [{ fetching: deleteContactLoading }, executeDeleteContact] = useDeleteContactsMutation();
  const onDelete = useCallback(
    (id: string) => {
      executeDeleteContact({ input: { contactIds: [id] } }).then((response) => {
        if (response.data?.deleteContacts?.success) {
          SnackbarService.show({
            message: Contact.SuccessMessages.ContactDeleted,
          });
        }
      });
    },
    [executeDeleteContact],
  );

  const onEdit = useCallback((id: string) => {
    setContactId(id);
    onOpenModal();
  }, []);

  const { search, table } = useEntityManager({ onEdit, onDelete });

  const { fetching, data, onNextPage, onPrevPage, onReset } = usePagination(
    useContactsQuery,
    {
      filters: { search: search.debouncedSearchText, companyIds: [match.params.id] },
      sorts: table.state.sorts,
      limit: 10000,
    },
    { edgeKey: 'contacts', pageSize: table.state.numberOfRowsPerPage },
  );

  const rows = useMemo(() => {
    return (
      data?.contacts.edges?.map(({ node }) => {
        return { ...node, name: `${node.firstName} ${node.lastName}` };
      }) || []
    );
  }, [data?.contacts.edges]);

  const { searchSuggestions, setSearchValue } = useContactSearchSuggestion(match.params.id);

  return (
    <>
      <EntityManager subject={AllowedPermissionsSubjectEnum.Contact} className="!px-[0px]">
        <Box className="flex flex-col border-b-[1px] border-grey-300 py-[16px] ">
          <Typography className="text-[18px] font-semibold text-grey-900">{Contact.New}</Typography>
          {canCreate ? (
            <Button
              theme="success"
              onClick={onOpenModal}
              classes="max-w-[204px] h-[44px] mt-[16px]">
              {Contact.New}
            </Button>
          ) : undefined}
        </Box>
        <Box
          className="mt-[24px] rounded-[12px] border border-grey-300"
          sx={{
            boxShadow:
              '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
          }}>
          <EntityManager.Table
            ignoreRelayPagination
            actions={table.actions}
            columns={columns}
            data={rows}
            loading={fetching || disabled}
            pagination={true}
            persistKey="contact_list_table"
            setState={table.setState}
            state={table.state}
            total={data?.contacts?.totalCount}
            onNextPage={onNextPage}
            onPrevPage={onPrevPage}
            onReset={onReset}
            filterNodes={
              <Box className="flex !w-[100%] gap-[16px] md:flex-wrap md:gap-[24px]">
                <EntityManager.Search
                  {...search}
                  autoFocus
                  isDropdown
                  removedSearchQoutedValue
                  suggestionData={searchSuggestions}
                  onChangeSearch={setSearchValue}
                  placeholder="Search"
                  startAdornment={
                    <InputAdornment position="start" className="mt-[4px] ml-[5px]">
                      <CustomIcons.SearchIcon />
                    </InputAdornment>
                  }
                  searchFieldWrapper="w-full md:w-[345px] lg:w-[345px]"
                />
              </Box>
            }
            paginationWrapperClass="p-[24px] flex-wrap md:gap-[24px]"
            footerWrapperClass="py-[16px] px-[24px]"
          />
        </Box>
      </EntityManager>

      {canCreateOrEdit ? (
        <ContactFormModal contactId={editContactId} isOpen={isOpen} onCancel={onCloseModal} />
      ) : undefined}
    </>
  );
};
export default withRouter(ContactList);
