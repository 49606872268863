import Loader from 'app/components/Loader';

import { getWarehouseLocations, parseWarehouseLocationName, WAREHOUSE_LABEL_CONFIG } from '../../../WarehouseDetails/utils';
import { useWarehouseLocationsQuery } from '../../graphql/queries/generated/warehouseLocations';
import { WarehouseLocationsProps } from '../../types';
import WarehouseItems from '../WarehouseItems';
import { WarehouseTypeEnum } from 'app/types/schema';

const WarehouseRacks = (props: WarehouseLocationsProps) => {
  const { warehouseId, selectedItems, warehouseType, onSelectItem } = props;

  const item = selectedItems[1];
  const { zone, aisle, rack } = parseWarehouseLocationName(item);

  const [{ fetching, data }] = useWarehouseLocationsQuery({
    variables: {
      filters: { warehouseIds: [warehouseId] },
      input: warehouseType === WarehouseTypeEnum.RackAndShelf ? {} : { zoneName: zone, aisleNumber: Number(aisle), maxChildIdentifier: rack },
    },
    requestPolicy: 'cache-and-network',
  });

  if (fetching) {
    return <Loader />;
  }

  const racks = getWarehouseLocations(data);

  return (
    <WarehouseItems
      itemKey="racks"
      items={racks}
      warehouseType={warehouseType || ''}
      label={WAREHOUSE_LABEL_CONFIG.aisles.getLabelFromLocation(item)}
      subItemKey="shelves"
      {...props}
    />
  );
};

export default WarehouseRacks;
