import { useEffect, useMemo } from 'react';
import Loader from 'app/components/Loader';
import { SnackbarService } from 'app/components/Snackbar';
import Partner from 'app/i18n/Partner';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import { getRedirectToBasedOnPermission } from 'app/modules/auth/utils/redirectTo';

import { TokenData } from '../../type';
import { useCreatePartnerTenantMutation } from './graphql/mutations/generated/createPartnerTenantInput';

type Props = {
  tokenData: TokenData;
} & RouteComponentProps;

function InvitePartner(props: Props) {
  const { tokenData, history } = props;
  const { tenantId, workspacePermissions } = useCurrentUser();
  const [{ fetching }, onCreatePartnerTenant] = useCreatePartnerTenantMutation();

  const redirectTo = useMemo(() => {
    return getRedirectToBasedOnPermission(workspacePermissions);
  }, [workspacePermissions]);

  useEffect(() => {
    if (fetching) {
      return;
    }

    onCreatePartnerTenant({
      input: {
        accessibleCategoryIds: [],
        allowedUserIds: [],
        parentTenantId: tokenData.tenantId,
        childTenantId: tenantId,
      },
    }).then((response) => {
      if (response?.data?.createPartnerTenant?.success) {
        SnackbarService.show({
          message: Partner.SuccessMessages.InvitationAccepted,
        });
        history.push(redirectTo);
      }
    });
  }, [history, redirectTo, onCreatePartnerTenant, tokenData]);

  if (fetching) {
    return <Loader />;
  }

  return null;
}

export default withRouter(InvitePartner);
