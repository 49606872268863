import { OrderRequestItemStatusEnum } from '../../../../../../types/schema';
import { useCallback, useState } from 'react';

interface OrderHistoryFilters {
  createdByIds: string[];
  destinationSiteIds: string[];
  deliverToIds: string[];
  statues: OrderRequestItemStatusEnum[];
}

const useOrderHistoryFilters = () => {
  const [filtersState, _setFiltersState] = useState<OrderHistoryFilters>({
    createdByIds: [],
    deliverToIds: [],
    destinationSiteIds: [],
    statues: [],
  });

  const setFilterState = useCallback(
    (nextState: Partial<OrderHistoryFilters>) => {
      _setFiltersState((prevState) => ({ ...prevState, ...nextState }));
    },
    [_setFiltersState],
  );

  return {
    filtersState,
    setFilterState,
  };
};

export type UseOrderHistoryFiltersReturnType = ReturnType<typeof useOrderHistoryFilters>;

export default useOrderHistoryFilters;
