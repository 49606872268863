import { useCallback } from 'react';
import { cx } from '@emotion/css';
import { TitleBar } from '@procurenetworks/procure-component-library';
import { SnackbarService } from 'app/components/Snackbar';
import routes from 'app/consts/routes';
import Location from 'app/i18n/Location';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import { titlebarStyles } from 'app/modules/components/EntityManager/styles';
import { CreateLocationInput, LocationTypeEnum } from 'app/types/schema';
import Box from 'app/ui-components/Box';

import AddLocationForm from '../../components/AddLocationForm';
import { useCreateLocationMutation } from './graphql/mutations/generated/createLocation';

const DEFAULT_VALUES: CreateLocationInput = {
  name: '',
  description: '',
  locationCode: '',
  type: LocationTypeEnum.Location,
  canStoreAsset: false,
  canStoreInventory: false,
  siteId: '',
};

function AddLocation(props: RouteComponentProps) {
  const { history } = props;
  const [{ fetching }, onCreateLocation] = useCreateLocationMutation();

  const onSubmit = useCallback(
    (input: CreateLocationInput) => {
      onCreateLocation({ input: { ...input, type: LocationTypeEnum.Location } }).then(
        (response) => {
          if (response.data?.createLocation.location?.id) {
            SnackbarService.show({ message: 'This location has been successfully created.' });
            history.replace(routes.Locations());
          }
        },
      );
    },
    [history, onCreateLocation],
  );

  const onCancel = useCallback(() => {
    history.replace(routes.Locations());
  }, [history]);

  return (
    <Box className="bg-white px-24 pt-0 pb-16">
      <Box className={cx('space-y-40', titlebarStyles)}>
        <TitleBar title={Location.NewLocation} />
        <Box className="!mt-[24px] w-full sm:max-w-[382px]  md:min-w-[382px] lg:max-w-[382px] xl:max-w-[382px]">
          <AddLocationForm
            defaultValues={DEFAULT_VALUES}
            disabled={fetching}
            onCancel={onCancel}
            onSubmit={onSubmit}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default withRouter(AddLocation);
