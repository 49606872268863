const {
  REACT_APP_API_URL,
  REACT_APP_CORE_API_URL,
  REACT_APP_ORDER_API_URL,
  REACT_APP_SHIP_API_URL,
  REACT_APP_CONTACT_API_URL,
  REACT_APP_SHOP_API_URL,
  REACT_APP_HOVER_API_URL,
  REACT_APP_UPLOAD_SIGN_URL,
  REACT_APP_CORS_IMAGE_PROXY_URL,
  REACT_APP_INVENTORY_API_URL,
  REACT_APP_CONTACT_UI_URL,
  REACT_APP_CORE_UI_URL,
  REACT_APP_SHIP_UI_URL,
  REACT_APP_DEPLOYMENT_ENVIRONMENT,
  REACT_APP_SENTRY_DSN,
  REACT_APP_REDIRECT_DOMAIN,
  REACT_APP_ZIP_CODE_CLIENT_KEY,
  REACT_APP_CHROME_EXTENSION_LINK,
  REACT_APP_SESSION_STORAGE_KEY,
  REACT_APP_COOKIE_ACCESS_TOKEN_KEY,
  REACT_APP_COOKIE_REFRESH_TOKEN_KEY,
  REACT_APP_COOKIE_ID_KEY,
  REACT_APP_COOKIE_TENANT_KEY,
  REACT_APP_COOKIE_DOMAIN,
  REACT_APP_WSS_URL,
  REACT_APP_CONFIG_CAT_KEY,
  REACT_APP_GEOPIFY_API_KEY,
  REACT_APP_GEOPIFY_URL,
  REACT_APP_MUI_LICENSE_KEY,
  REACT_APP_DOWNLOAD_AGENT_SIGN_URL,
  REACT_APP_PROCURE_AGENT_LINUX,
  REACT_APP_PROCURE_AGENT_MAC,
  REACT_APP_PROCURE_AGENT_WINDOWS,
  REACT_APP_ZENDESK_UI_URL
} = process.env;

export const APP_CONFIG = {
  apiUrls: {
    api: REACT_APP_API_URL,
    core: REACT_APP_CORE_API_URL,
    contact: REACT_APP_CONTACT_API_URL,
    order: REACT_APP_ORDER_API_URL,
    ship: REACT_APP_SHIP_API_URL,
    shop: REACT_APP_SHOP_API_URL,
    inventory: REACT_APP_INVENTORY_API_URL,
    hover: REACT_APP_HOVER_API_URL || '',
    wssAPI: REACT_APP_WSS_URL,
    corsProxy: REACT_APP_CORS_IMAGE_PROXY_URL,
  },
  uiUrls: {
    core: REACT_APP_CORE_UI_URL,
    contact: REACT_APP_CONTACT_UI_URL,
    inventory: REACT_APP_CORE_UI_URL,
    order: REACT_APP_CORE_UI_URL,
    ship: REACT_APP_CORE_UI_URL,
    shop: REACT_APP_CORE_UI_URL,
    zendesk: REACT_APP_ZENDESK_UI_URL
  },
  rewriteAPIUrls: {
    core: REACT_APP_API_URL,
  },
  deploymentEnvironment: REACT_APP_DEPLOYMENT_ENVIRONMENT,
  sentryDSN: REACT_APP_SENTRY_DSN,
  cookieKeys: {
    accessToken: REACT_APP_COOKIE_ACCESS_TOKEN_KEY || '',
    refreshToken: REACT_APP_COOKIE_REFRESH_TOKEN_KEY || '',
    id: REACT_APP_COOKIE_ID_KEY || '',
    domain: REACT_APP_COOKIE_DOMAIN || '',
    tenant: REACT_APP_COOKIE_TENANT_KEY || '',
  },
  uploadSignUrl: REACT_APP_UPLOAD_SIGN_URL || '',
  context: '',
  enablets: false,
  configCatKey: REACT_APP_CONFIG_CAT_KEY,
  redirectDomain: REACT_APP_REDIRECT_DOMAIN,
  chromeExtensionLink: REACT_APP_CHROME_EXTENSION_LINK,
  zipcodeClientKey: REACT_APP_ZIP_CODE_CLIENT_KEY,
  geopifyApiKey: REACT_APP_GEOPIFY_API_KEY,
  goopify: REACT_APP_GEOPIFY_URL,
  muiProLicenseKey: REACT_APP_MUI_LICENSE_KEY || '',
  muiPremiumLicenseKey: REACT_APP_MUI_LICENSE_KEY || '',
  downloadAgentSignUrl: REACT_APP_DOWNLOAD_AGENT_SIGN_URL || '',
  procureAgentLinux: REACT_APP_PROCURE_AGENT_LINUX || '',
  procureAgentMac: REACT_APP_PROCURE_AGENT_MAC || '',
  procureAgentWindows: REACT_APP_PROCURE_AGENT_WINDOWS || '',
};

export const API_URL = REACT_APP_API_URL;

export const SESSION_STORAGE_KEY = REACT_APP_SESSION_STORAGE_KEY || '';
export const SESSION_STORAGE_VALUE =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9jb3JlLXNlcnZpY2UtMDEucWEucHJvY3VyZW5ldHdvcmtzLmNvbVwvYXBpXC90ZW5hbnRzIiwiaWF0IjoxNjU3NDM2NTYwLCJleHAiOjE2NTgwNDEzNjAsIm5iZiI6MTY1NzQzNjU2MCwianRpIjoiTzU0V1Z5YlNMd0F6ak5sTiIsImVtYWlsIjoiaGFyc2hpdGRhdmRhNzdAZ21haWwuY29tIiwic3ViIjoiYXV0aDB8NjI2M2NkYzQxZDE3NTkwMDY5Njc0YjkyIiwidXNlcl9pZCI6ODkyLCJ0ZW5hbnRfaWQiOjIsInRlbmFudF9jb2RlIjoiRTE1LUY0MS1DMkQtQTkxQiIsInRlbmFudF9uYW1lIjoiUHJvY3VyZSBOZXR3b3JrcyJ9.KlFi2nHsuuNXldmqJd7d-gZrSdZhxCSR_Oo5KIZ2plY';

export const disableVirtualization = process.env.NODE_ENV === 'test';
