import { useCallback, useEffect, useMemo, useState } from 'react';
import { SnackbarService } from 'app/components/Snackbar';
import routes from 'app/consts/routes';
import Wishlist from 'app/i18n/Wishlist';
import { WishlistVendorEnum } from 'app/types/schema';

import { GetAmazonChunksData } from '../../components/utils/helper';
import { useCreateWishlistMutation } from '../../graphql/mutations/generated/createWishlist';
import { useUpdateWishlistMutation } from '../../graphql/mutations/generated/updateWishlist';
import { useWishlistsQuery } from '../../graphql/queries/generated/wishlist';
import {
  CreateListStepOneInput,
  PaginationState,
  SelectedProducts,
  VendorTypeEnum,
  WishlistActionsType,
  WishListDetailType,
} from '../../types';
import useGetImpactProductData from '../useGetImpactProductData';
import { removeExtraSpacesAndNewlines } from 'app/utils/removeMultipleSpaces';

const useWishlistActions = (props: WishlistActionsType) => {
  const { isEditinglist, listView, history, search, wishlistId, isCreatingProduct } = props;

  const [selectedProducts, setSelectedProducts] = useState<SelectedProducts[]>([]);
  const [wishlistName, setWishlistName] = useState<string>();
  const [isAddingProduct, setIsAddingProduct] = useState<boolean>(true);
  const [isProductSaving, setProductSaving] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(isEditinglist || false);
  const [isInitialRender, setIsInitialRender] = useState<boolean>(true);
  const [isProductUpdate, setProductUpdate] = useState<boolean>(false);
  const [isListVisible, setIsListVisible] = useState<boolean>(listView || false);
  const [shareLink, setShareLink] = useState<string>('');
  const [isShowProducts, setIsShowProducts] = useState(false);
  const [formattedDescription, setFormattedDescription] = useState<string>('');
  const [firstStepData, setFirstStepData] = useState<CreateListStepOneInput>({
    firstName: '',
    lastName: '',
    wishlistName: '',
    shippingAddress: {
      address: '',
      city: '',
      state: '',
      zipCode: '',
    },
    shareId: '',
    description: '',
    eventDate: null,
  });
  const [stepper, setStepper] = useState(wishlistId ? 2 : 1);

  const [paginationState, setPaginationState] = useState<PaginationState>({
    pageSize: 8,
    page: 1,
  });

  useEffect(() => {
    if (listView && !isCreatingProduct) {
      setIsListVisible(listView);
      setIsAddingProduct(false);
    }
  }, [listView, isCreatingProduct]);

  const [{ fetching: fetchingCreateWishlist }, onCreateWishlist] = useCreateWishlistMutation();
  const [{ fetching: fetchingUpdateWishlist }, onUpdateWishlist] = useUpdateWishlistMutation();

  const isProductInArray = (products: SelectedProducts[], product: SelectedProducts) => {
    return products.some((p) => p.id === product.id);
  };

  const onAddProductHandler = (card: SelectedProducts) => {
    setProductUpdate(true);

    if (isProductInArray(selectedProducts, card)) {
      const updatedProducts = selectedProducts.map((p) =>
        p.id === card.id ? { ...p, quantity: card.quantity } : p,
      );
      setSelectedProducts(updatedProducts);
    } else {
      // Add the product to the array
      setSelectedProducts((prev) => [...prev, card]);
    }
  };

  const removeProductHandler = (index: number) => {
    setProductUpdate(true);
    const updatedProducts = [...selectedProducts];
    updatedProducts.splice(index, 1);
    setSelectedProducts(updatedProducts);
  };

  const itemQuantityUpdate = (id: string, quantity: number) => {
    setSelectedProducts((prevData) => {
      const newData = prevData.map((product) => {
        if (product.hooverProductId === id) {
          return { ...product, quantity: quantity };
        }
        return product;
      });
      return newData;
    });
  };

  const itemPurchasedQuantityUpdate = (id: string, quantity: number) => {
    setSelectedProducts((prevData) => {
      const newData = prevData.map((product) => {
        if (product.hooverProductId === id) {
          return { ...product, purchasedQuantity: quantity };
        }
        return product;
      });
      return newData;
    });
  };

  const searchState = {
    ...search,
    onChange: (value: string) => {
      search.onChange(value);
      if (listView && !wishlistId) {
        history.push(routes.WishlistCreateList());
      } else {
        setIsListVisible(false);
        setIsAddingProduct(true);
      }
    },
  };

  const editListHandler = (products: SelectedProducts[]) => {
    setSelectedProducts(products);
    setIsAddingProduct(false);
    setIsListVisible(false);
  };

  const saveHandler = useCallback(
    async (data?: WishListDetailType) => {
      setProductSaving(true);

      const WishlistProductInput = selectedProducts.map((item) => ({
        hooverProductId: item.hooverProductId.toString() || '',
        quantity: item.quantity || 0,
        currentPurchasedQuantity: item.purchasedQuantity || 0,
        vendor: item.vendor?.toUpperCase() as WishlistVendorEnum,
      }));

      try {
        if (wishlistId) {
          const response = await onUpdateWishlist({
            input: {
              name: data?.name || firstStepData.wishlistName || '',
              description: removeExtraSpacesAndNewlines(data?.description || ''),
              formattedDescription: data?.description || '',
              lastName: data?.lastName || '',
              shippingAddress: data?.shippingAddress || {
                address: '',
                city: '',
                state: '',
                zipCode: '',
              },
              firstName: data?.firstName || '',
              shareId: data?.shareId || '',
              productQuantityConfig: WishlistProductInput,
              wishlistId: wishlistId,
              eventDate: data?.eventDate,
              collaborators: data?.collaborators || [],
            },
          });

          const { id: wishListId, name: wishlistName } =
            response.data?.updateWishlist?.wishlist || {};

          if (wishListId) {
            setWishlistName(wishlistName);
            SnackbarService.show({ message: Wishlist.SuccessMessages.WishlistUpdatedSuccessfully });
            history.push(routes.WishlistListView());
          }
        } else {
          const eventDate = new Date(
            new Date(firstStepData.eventDate || '').setHours(23, 59, 59, 999),
          ).toUTCString();
          const response = await onCreateWishlist({
            input: {
              name: firstStepData.wishlistName || '',
              products: WishlistProductInput,
              description: firstStepData.description,
              formattedDescription: formattedDescription,
              shippingAddress: firstStepData.shippingAddress || {
                address: '',
                city: '',
                state: '',
                zipCode: '',
              },
              shareId: firstStepData.shareId,
              firstName: firstStepData.firstName,
              lastName: firstStepData.lastName,
              eventDate: eventDate,
              collaborators: firstStepData.collaborator,
            },
          });

          const { id: wishlistId, name: wishlistName } =
            response.data?.createWishlist?.wishlist || {};

          if (wishlistId) {
            setWishlistName(wishlistName);
            SnackbarService.show({
              message: Wishlist.SuccessMessages.WishlistCreatedSuccessfully,
            });

            if (!WishlistProductInput && !isEditing) {
              history.push(routes.WishlistListView());
              return;
            }

            history.push(routes.CreateWishlistProducts(wishlistId));
          }
        }
      } catch (error) {
        console.log('Something went wrong while saving wishlist', error);
        SnackbarService.showError({ message: Wishlist.ErrorMessages.WishlistSavingError });
      } finally {
        setProductSaving(false);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [firstStepData, onCreateWishlist, selectedProducts],
  );

  const [{ data: wishlistData, fetching: wishlistDetailsFetching }, refetchWishListDetails] =
    useWishlistsQuery({
      variables: { filters: { wishlistIds: wishlistId ? [wishlistId] : [] } },
      requestPolicy: 'network-only',
    });

  const refetchWishlist = () => {
    setProductUpdate(false);
    refetchWishListDetails();
  };

  useEffect(() => {
    if (!wishlistData?.wishlists.edges?.length && !wishlistDetailsFetching && wishlistId) {
      SnackbarService.showError({ message: Wishlist.ErrorMessages.WishlistNotFound });
      history.push(routes.WishlistListView());
    }
  }, [wishlistData]);

  const filteredWalmartProductIds = useMemo(() => {
    const products = wishlistData?.wishlists.edges?.length
      ? wishlistData?.wishlists.edges[0].node.products
      : [];

    return products
      ? products
          .filter((product) => product.vendor === WishlistVendorEnum.Walmart)
          .map((product) => product.hooverProductId)
      : [];
  }, [wishlistData]);

  const filteredAmazonProductIds = useMemo(() => {
    const products = wishlistData?.wishlists.edges?.length
      ? wishlistData?.wishlists.edges[0].node.products
      : [];

    return products
      ? products
          .filter((product) => product.vendor === WishlistVendorEnum.Amazon)
          .map((product) => product.hooverProductId)
      : [];
  }, [wishlistData]);

  const { data: impactData } = useGetImpactProductData({
    productIds: filteredWalmartProductIds,
    page: 1,
    pageSize: filteredWalmartProductIds.length,
    vendorType: VendorTypeEnum.WALMART,
  });

  const amazonData = GetAmazonChunksData({ amazonProductIds: filteredAmazonProductIds });

  useEffect(() => {
    if (wishlistId) {
      const products = wishlistData?.wishlists.edges?.length
        ? wishlistData?.wishlists.edges[0].node.products
        : [];

      const updateMap = new Map(products.map((item) => [item.hooverProductId, item.quantity]));

      const allProducts = [...impactData, ...amazonData];

      const updatedData = allProducts.map((item: SelectedProducts) => ({
        ...item,
        quantity: updateMap.get(item.hooverProductId.toString()) || item.quantity,
        purchasedQuantity:
          products.find((product) => product.hooverProductId.toString() === item.hooverProductId.toString())
            ?.currentPurchasedQuantity || 0,
      }));

      const uniqueUpdatedData = updatedData.reduce((acc: any, item) => {
        const existingItemIndex = acc.findIndex(
          (x: any) => x.hooverProductId === item.hooverProductId,
        );

        if (existingItemIndex === -1) {
          acc.push(item);
        } else {
          acc[existingItemIndex] = { ...acc[existingItemIndex], ...item };
        }

        return acc;
      }, []);

      if (!isProductUpdate) {
        setSelectedProducts(uniqueUpdatedData);
      }

      setWishlistName(
        wishlistData?.wishlists?.edges?.length ? wishlistData?.wishlists?.edges[0].node.name : '',
      );

      if (isInitialRender && !isCreatingProduct) {
        setIsAddingProduct(false);
      } else if (isCreatingProduct) {
        setIsEditing(true);
      }

      if (isInitialRender) setIsInitialRender(false);

      setIsListVisible(false);
      setShareLink(window.location.href);
    }
    if (listView && !wishlistId) {
      setIsListVisible(true);
      setShareLink('');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    impactData,
    amazonData,
    wishlistId,
    wishlistData,
    setSelectedProducts,
    setIsAddingProduct,
    setIsListVisible,
    listView,
  ]);

  return {
    wishlistName,
    saveHandler,
    editListHandler,
    searchState,
    itemQuantityUpdate,
    isProductSaving,
    removeProductHandler,
    wishlistData,
    isAddingProduct,
    setIsEditing,
    isListVisible,
    wishlistDetailsFetching,
    refetchWishList: refetchWishlist,
    onAddProductHandler,
    fetchingCreateWishlist,
    isEditing,
    selectedProducts,
    setIsAddingProduct,
    setIsListVisible,
    setSelectedProducts,
    fetchingUpdateWishlist,
    shareLink,
    setShareLink,
    setIsShowProducts,
    firstStepData,
    setFirstStepData,
    stepper,
    setStepper,
    isShowProducts,
    paginationState,
    setPaginationState,
    itemPurchasedQuantityUpdate,
    setFormattedDescription,
  };
};

export type UseWishlistActionsReturnType = ReturnType<typeof useWishlistActions>;

export default useWishlistActions;
