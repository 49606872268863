import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, CustomIcons } from '@procurenetworks/procure-component-library';
import usePagination from 'app/hooks/usePagination';
import Contact from 'app/i18n/Contact';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import EntityManager from 'app/modules/components/EntityManager';
import useEntityManager from 'app/modules/components/EntityManager/useEntityManager';
import Stack from 'app/ui-components/Stack';

import { useAccessControl } from '../../../../components/AccessControl';
import { SnackbarService } from '../../../../components/Snackbar';
import {
  AllowedPermissionActionsEnum,
  AllowedPermissionsSubjectEnum,
} from '../../../../types/schema';
import useCurrentUser from '../../../auth/hooks/useCurrentUser';
import useAddressSearchSuggestion from '../../hook/useAddressSearchSuggestion';
import AddressFormModal from '../addressFormModal';
import { useDeleteAddressesMutation } from './graphql/mutations/generated/deleteAddress';
import { useAddressesTableQuery } from './graphql/queries/generated/addressesTable';
import { getContactTableColumns } from './utils/columns';
import { Box, InputAdornment, Typography } from '@mui/material';

const columns = getContactTableColumns();
const NewAddress = (props: RouteComponentProps<{ id: string }>) => {
  const { match } = props;

  const { workspacePermissions } = useCurrentUser();

  const canCreate = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Create,
    AllowedPermissionsSubjectEnum.Address,
  );

  const canEdit = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Edit,
    AllowedPermissionsSubjectEnum.Address,
  );
  const canCreateOrEdit = canCreate || canEdit;

  const disabled = false;
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();
  const [isOpen, setIsOpen] = useState(false);
  const [editAddressId, setEditAddressId] = useState('');

  const [{ fetching: deleteContactLoading }, executeDeleteAddress] = useDeleteAddressesMutation();
  const onDelete = useCallback(
    (id: string) => {
      executeDeleteAddress({ input: { addressIds: [id] } }).then((response) => {
        if (response.data?.deleteAddresses.success) {
          SnackbarService.show({
            message: Contact.SuccessMessages.AddressDeleted,
          });
        }
      });
    },
    [executeDeleteAddress],
  );

  const onEdit = useCallback((id: string) => {
    setEditAddressId(id);
    onOpenModal();
  }, []);

  const { search, table } = useEntityManager({ onEdit, onDelete });

  const { fetching, data, onNextPage, onPrevPage, onReset } = usePagination(
    useAddressesTableQuery,
    {
      filters: { search: search.debouncedSearchText, companyIds: [match.params.id] },
      sorts: table.state.sorts,
    },
    { edgeKey: 'addresses', pageSize: table.state.numberOfRowsPerPage },
  );
  const rows = useMemo(() => {
    return (
      data?.addresses.edges?.map(({ node }) => {
        return {
          ...node,
          name: `${node.addressLineOne} ${node.addressLineTwo || ''} ${node.zipCode}, ${
            node.city
          }, ${node.state}`,
        };
      }) || []
    );
  }, [data?.addresses.edges]);

  const onCloseModal = () => {
    setIsOpen(false);
    setEditAddressId('');
  };

  const onOpenModal = () => {
    setIsOpen(true);
  };

  const { searchSuggestions, setSearchValue } = useAddressSearchSuggestion(match.params.id);

  return (
    <EntityManager subject={AllowedPermissionsSubjectEnum.Address} className="!px-[0px]">
      <Box className="flex flex-col border-b-[1px] border-grey-300 py-[16px]">
        <Typography className="text-[18px] font-semibold text-grey-900">
          {Contact.Address}
        </Typography>
        {canCreate ? (
          <Button theme="success" onClick={onOpenModal} classes="max-w-[204px] h-[44px] mt-[16px]">
            {Contact.NewAddress}
          </Button>
        ) : undefined}
      </Box>

      <Box
        className="mt-[24px] rounded-[12px] border border-grey-300"
        sx={{
          boxShadow:
            '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
        }}>
        <EntityManager.Table
          ignoreRelayPagination
          actions={table.actions}
          columns={columns}
          data={rows}
          loading={fetching || disabled}
          pagination={true}
          persistKey="address_list_table"
          setState={table.setState}
          state={table.state}
          total={data?.addresses?.totalCount}
          onNextPage={onNextPage}
          onPrevPage={onPrevPage}
          onReset={onReset}
          filterNodes={
            <Box className="flex !w-[100%] gap-[16px] md:flex-wrap md:gap-[24px]">
              <EntityManager.Search
                {...search}
                autoFocus
                isDropdown
                removedSearchQoutedValue
                suggestionData={searchSuggestions}
                onChangeSearch={setSearchValue}
                placeholder="Search"
                startAdornment={
                  <InputAdornment position="start" className="mt-[4px] ml-[5px]">
                    <CustomIcons.SearchIcon />
                  </InputAdornment>
                }
                searchFieldWrapper="w-full md:w-[345px] lg:w-[345px]"
              />
            </Box>
          }
          paginationWrapperClass="p-[24px] flex-wrap md:gap-[24px]"
          footerWrapperClass="py-[16px] px-[24px]"
        />
      </Box>

      {isOpen && canCreateOrEdit && (
        <AddressFormModal
          addressId={editAddressId}
          companyId={match.params.id}
          isOpen={isOpen}
          onCancel={onCloseModal}
          onSavedAddress={onCloseModal}
        />
      )}
    </EntityManager>
  );
};
export default withRouter(NewAddress);
