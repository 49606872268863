import { useCallback, useEffect, useMemo } from 'react';
import { useClient } from 'urql';
import Link from 'app/components/Link';
import { SnackbarService } from 'app/components/Snackbar';
import routes from 'app/consts/routes';
import Box from 'app/ui-components/Box';
import PageTitle from 'app/ui-components/Title';

import { ForgotPasswordTokenEnum, LoginInput, LoginTokenResponse } from '../../types';
import { getRedirectTo, isUrlContainsDomain } from '../../utils/redirectTo';
import LoginForm from './components/LoginForm';
import LoginHeader from './components/LoginHeader';
import useEmailPasswordAPI from './hooks/useEmailPasswordLogin';
import { setLoginCookieAndGetUser } from './utils/authTokens';

const InvalidToken = () => (
  <div className="text-[16px] font-semibold">
    The password reset request is not valid. Please click{' '}
    {
      <Link
        className={'text-[##3366CC] cursor-pointer no-underline'}
        href={routes.ForgotPassword()}>
        “Forgot Password”
      </Link>
    } to make a password reset request.
  </div>
);

function Login() {
  const client = useClient();
  const { fetching, execute } = useEmailPasswordAPI();

  const errorCode = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get('errorCode') || '';
  }, []);

  useEffect(() => {
    if (errorCode && errorCode === ForgotPasswordTokenEnum.TOKEN_INVALID) {
      SnackbarService.showError({
        children: InvalidToken(),
        message: '',
      });
    }
  }, [errorCode]);

  const getLoginToken = useCallback(
    async (props: LoginInput) => {
      const { email, password, rememberMe } = props;
      execute({ email, password }).then((result: LoginTokenResponse) => {
        if (result.success) {
          const redirectTo = getRedirectTo();

          if (redirectTo && isUrlContainsDomain(redirectTo)) {
            window.location.href = redirectTo;
          }

          setLoginCookieAndGetUser(client, result.loginToken, rememberMe);
          SnackbarService.clear();
        } else {
          SnackbarService.showError({
            message: result.message || 'Some error occurred. Please try again later.',
          });
        }
      });
    },
    [client, execute],
  );

  return (
    <>
      <LoginHeader />
      <PageTitle title="Login" />
      <Box className="w-100% flex h-full items-center justify-center bg-grey-100">
        <Box className="mx-auto mt-[75px] mb-[0px] min-w-[486px] max-w-[400px] space-y-40  rounded-[16px] bg-white px-20 py-20 shadow-[0px_1px_2px_0px_rgba(16,24,40,0.06),0px_1px_3px_0px_rgba(16,24,40,0.10)]">
          <LoginForm disabled={fetching} onSubmit={getLoginToken} />
        </Box>
      </Box>
    </>
  );
}

export default Login;
