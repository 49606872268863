import { useCallback, useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import { Button } from '@procurenetworks/procure-component-library';
import { SnackbarService } from 'app/components/Snackbar';
import { WarningService } from 'app/components/WarningAlert';
import Common from 'app/i18n/Common';
import Orders from 'app/i18n/Orders';
import { FeatureFlagEnum, useFeatureFlag } from 'app/libs/featureFlag';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import EntityManager from 'app/modules/components/EntityManager';

import { isExternalUserHavePartnerTenants } from '../../utils/externalTenant';
import { findDuplicateOrderItems, getDuplicateOrderItem } from '../../utils/utils';
import BulkOrderItemForm from '../BulkOrderItemForm';
import { UseBulkOrderRequestReturnType } from '../OrderItemRequest/hook/useBulkOrderRequest';
import { UseItemRequestStateReturnType } from '../OrderItemRequest/hook/useItemRequestState';
import OrderPartnerTenantField from '../OrderRequestForm/components/OrderPartnerTenantField';
import { OrderRequestInput } from '../OrderRequestForm/type';
import { getItemByIndex, updateItem } from './hook/useBulkOrderForm/utils';

interface Props {
  state: UseBulkOrderRequestReturnType['bulkOrderState'];
  actions: UseBulkOrderRequestReturnType['bulkOrderActions'];
  onCancelBulkOrderRequest: UseItemRequestStateReturnType['orderItemRequest']['actions']['onCancelBulkOrderRequest'];
  onNextStep: () => void;
  disabled?: boolean;
}

const BulkOrderRequest = (props: Props) => {
  const { state, actions, onNextStep, onCancelBulkOrderRequest, disabled } = props;
  const { value: isProjectFieldEnabled } = useFeatureFlag(
    FeatureFlagEnum.ProjectFieldEnabledInOrderRequestForm,
  );
  const { isExternalTenant } = state;

  const user = useCurrentUser();
  const lgCols = isProjectFieldEnabled && !isExternalTenant ? 7 : 4;
  const mdCols = isProjectFieldEnabled && !isExternalTenant ? 10 : 6;
  const onSubmit = useCallback(
    (values: any) => {
      if (values.orderItems.length > 1) {
        onNextStep();
      } else {
        SnackbarService.showError({
          message: Orders.FormValidationMessages.BulkOrderItemRequired,
        });
      }
    },
    [onNextStep],
  );
  const onFormSubmit = useMemo(() => actions.handleSubmit(onSubmit), [onSubmit, actions]);

  const onCancelClick = useCallback(() => {
    if (state.orderItems.length > 1) {
      WarningService.showWarning({
        message: Orders.FormValidationMessages.UnSavedChangesMessage,
        onConfirm: () => {
          onCancelBulkOrderRequest();
        },
      });
    } else {
      onCancelBulkOrderRequest();
    }
  }, [onCancelBulkOrderRequest, state.orderItems.length]);

  const onUpdateOrderItem = useCallback(
    (index: number, nextState: Partial<OrderRequestInput>) => {
      const currentOrderItem = getItemByIndex(state.orderItems, index);

      if (!currentOrderItem) {
        return;
      }

      // 1.) We update state of order Item
      actions.onUpdateOrderItem(index, nextState);

      // 2.) We check for duplicate Order Request Items
      const { duplicateOrderItem, duplicateUpdatedState } = getDuplicateOrderItem(
        updateItem(state.orderItems, index, nextState),
        currentOrderItem.id as string,
      );

      // 3.) If duplicate Order Request Item found, we show alert
      if (duplicateOrderItem) {
        WarningService.showWarning({
          message: Orders.FormValidationMessages.DuplicateOrderItemInBulkOrderRequest,
          onConfirm: () => {
            actions.onUpdateDuplicateOrderItem({
              update: {
                id: duplicateOrderItem.id,
                ...duplicateUpdatedState,
              },
              delete: [currentOrderItem.id as string],
            });
          },
          onCancel: () => {
            actions.onRemoveOrderItems([index]);
          },
        });
      }

      if (state.orderItems.length - 1 === index) {
        actions.onAddItem();
      }
    },
    [
      actions.onUpdateOrderItem,
      state.orderItems,
      actions.onAddItem,
      actions.onUpdateDuplicateOrderItem,
      actions.onRemoveOrderItems,
    ],
  );

  const disabledFormField = useMemo(() => {
    return !!(isExternalUserHavePartnerTenants(user) && !state.partnerTenantId) || !!disabled;
  }, [disabled, isExternalUserHavePartnerTenants, state.partnerTenantId]);

  return (
    <div>
      <Box className="w-[300px] md:min-w-[380px] md:max-w-[380px]  lg:min-w-[380px] lg:min-w-[380px] xl:max-w-[380px] xl:max-w-[380px]">
        <OrderPartnerTenantField
          control={state.control}
          name="partnerTenantId"
          onPartnerTenantChange={actions.onPartnerTenantChange}
        />
      </Box>

      <EntityManager.Title
        documentTitle={Orders.OrderRequestDocumentTitle}
        title={Orders.BulkOrderRequest}
      />
      <form>
        <Grid container spacing={2}>
          <Grid item lg={lgCols} md={mdCols} xs={12}>
            <div className="mt-[20px]">
              <BulkOrderItemForm
                actions={actions}
                disabled={disabledFormField}
                state={state}
                onUpdateOrderItem={onUpdateOrderItem}
              />
            </div>
            <div className="mt-[30px] lg:max-w-[890px] xl:max-w-[890px]">
              <Grid container justifyContent="flex-end">
                <Grid item xs="auto">
                  <Grid container className="gap-[16px]" justifyContent="flex-end">
                    <Grid item xs="auto">
                      <Button classes="min-w-[94px] h-[44px]" theme="info" onClick={onFormSubmit}>
                        {Common.Actions.Next}
                      </Button>
                    </Grid>
                    <Grid item xs="auto">
                      <div>
                        <Button classes="min-w-[94px] h-[44px]" onClick={onCancelClick}>
                          {Common.Actions.Cancel}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default BulkOrderRequest;
