import { useCallback, useMemo } from 'react';
import { SnackbarService } from 'app/components/Snackbar';
import routes from 'app/consts/routes';
import usePagination from 'app/hooks/usePagination';
import Manufacturer from 'app/i18n/Manufacturers';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import EntityManager from 'app/modules/components/EntityManager';
import useMultiSelectEntityManager from 'app/modules/components/EntityManager/useMultiSelectEntityManager';
import { AllowedPermissionsSubjectEnum } from 'app/types/schema';
import Stack from 'app/ui-components/Stack';

import useSearchSuggestion from '../../hook/useSearchSuggestion';
import { useDeleteManufacturersMutation } from './graphql/mutations/generated/deleteManufacturer';
import { useManufacturersTableQuery } from './graphql/queries/generated/manufacturersTable';
import { getManufacturerTableColumns } from './utils/columns';
import { showReasonsError } from '../../../../utils/reasons';
import { Box, InputAdornment } from '@mui/material';
import { CustomIcons } from '@procurenetworks/procure-component-library';

const columns = getManufacturerTableColumns();

function Manufacturers(props: RouteComponentProps) {
  const { history } = props;
  const [{ fetching: disabled }, deleteManufacturer] = useDeleteManufacturersMutation();

  const onEdit = useCallback(
    (id: string) => {
      history.push(routes.EditManufacturer(id));
    },
    [history],
  );

  const onDelete = useCallback(
    (ids: string[]) => {
      deleteManufacturer({ input: { manufacturerIds: ids } }).then((response) => {
        const { success, reasons } = response.data?.deleteManufacturers || {};
        if (success) {
          SnackbarService.show({ message: 'This manufacturer has been successfully deleted.' });
        } else {
          showReasonsError(reasons);
        }
      });
    },
    [deleteManufacturer],
  );

  const { search, table } = useMultiSelectEntityManager({ onEdit, onDelete });

  const { fetching, data, onNextPage, onPrevPage, onReset } = usePagination(
    useManufacturersTableQuery,
    {
      filters: { search: search.debouncedSearchText },
      sorts: table.state.sorts,
    },
    { edgeKey: 'manufacturers', pageSize: table.state.numberOfRowsPerPage },
  );

  const rows = useMemo(() => {
    return data?.manufacturers.edges?.map(({ node }) => node) || [];
  }, [data?.manufacturers.edges]);

  const { searchSuggestions, setSearchValue } = useSearchSuggestion();

  return (
    <EntityManager
      subject={AllowedPermissionsSubjectEnum.Manufacturer}
      layoutWrapperClass
      className="pt-0">
      <EntityManager.Title
        documentTitle={Manufacturer.Manufacturers}
        title={Manufacturer.Manufacturers}
      />
      <EntityManager.Add label={Manufacturer.NewManufacturer} to={routes.AddManufacturer()} />
      <Box
        sx={{
          boxShadow:
            '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
        }}
        className="mt-[24px] rounded-[12px] border border-grey-300 pb-[24px]">
        <EntityManager.MultiSelectTable
          ignoreRelayPagination
          persistSelectionData
          actions={table.actions}
          columns={columns}
          data={rows}
          loading={fetching || disabled}
          pagination={true}
          persistKey="manufacturers_table"
          setState={table.setState}
          state={table.state}
          total={data?.manufacturers.totalCount}
          onNextPage={onNextPage}
          onPrevPage={onPrevPage}
          onReset={onReset}
          filterNodes={
            <Box className="flex !w-[100%] gap-[16px] md:flex-wrap md:gap-[24px]">
              <EntityManager.Search
                isDropdown
                removedSearchQoutedValue
                suggestionData={searchSuggestions || []}
                onChangeSearch={setSearchValue}
                {...search}
                autoFocus
                placeholder="Search"
                startAdornment={
                  <InputAdornment position="start" className="mt-[4px] ml-[5px]">
                    <CustomIcons.SearchIcon />
                  </InputAdornment>
                }
              />
            </Box>
          }
          paginationWrapperClass="p-[24px] flex-wrap md:gap-[24px]"
        />
      </Box>
    </EntityManager>
  );
}

export default withRouter(Manufacturers);
