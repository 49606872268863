import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { Button } from '@procurenetworks/procure-component-library';
import FormTextArea from 'app/components/Form/FormTextArea';
import Modal from 'app/components/Modal';
import FormDatePickerInput from 'app/components/ProcureForm/FormDatePickerInput';
import Common from 'app/i18n/Common';
import Receivables from 'app/i18n/Receivables';
import { getCurrentDateInGMTMinus7 } from 'app/modules/assets/utils/dto';
import Box from 'app/ui-components/Box';

import { RECEIVE_FORM_RULES } from '../../utils/validate';
import useEditReceiveItemState from '../hook/useEditReceiveItemState';

interface Props {
  transactionData: any;
  onCancel: () => void;
}

const EditReceivableItem = (props: Props) => {
  const { onCancel, transactionData } = props;
  const {
    actions: { handleSubmit, onSubmit },
    control,
    isUpdatingReceivables,
  } = useEditReceiveItemState({ transactionData, onCancel });

  const today = useMemo(() => {
    return getCurrentDateInGMTMinus7();
  }, []);
  const isUnReservingTransaction = false;

  return (
    <Modal
      actions={
        <div>
          <>
            <Button
              disabled={isUpdatingReceivables}
              loading={isUpdatingReceivables}
              theme="success"
              onClick={handleSubmit(onSubmit)}
              classes="h-[44px] w-[94px]">
              {Common.Actions.Save}
            </Button>
            <Button
              classes="ml-[16px] ch-[44px] w-[94px]"
              disabled={isUpdatingReceivables}
              onClick={onCancel}>
              {Common.Actions.Cancel}
            </Button>
          </>
        </div>
      }
      disable={isUnReservingTransaction}
      id="reserve-edit-transaction"
      open={transactionData.open}
      title={Common.Actions.Update}
      onClose={onCancel}>
      <form>
        <Box className="min-w-[375px]">
          <div className="mb-[24px] mt-[16px]">
            <Controller
              control={control}
              name="dueDate"
              render={({ field, fieldState }) => (
                <FormDatePickerInput
                  {...field}
                  key="dueDate"
                  clearable
                  classes="mt-6"
                  error={fieldState.error}
                  label={Receivables.FormLabels.estimateDueDate}
                  minDate={today}
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="notes"
              render={({ field, fieldState }) => (
                <FormTextArea
                  {...field}
                  error={fieldState.error}
                  label={Receivables.FormLabels.notes}
                  placeholder="Enter a note..."
                />
              )}
            />
          </div>
        </Box>
      </form>
    </Modal>
  );
};

export default EditReceivableItem;
