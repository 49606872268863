import { cx } from '@emotion/css';
import times from 'lodash/times';
import Loader from 'app/components/Loader';
import Warehouse from 'app/i18n/Warehouse';
import {
  getWarehouseLocations,
  parseWarehouseLocationName,
  WAREHOUSE_LABEL_CONFIG,
} from 'app/modules/warehouse/views/WarehouseDetails/utils';
import Box from 'app/ui-components/Box';
import Stack from 'app/ui-components/Stack';

import WarehouseItem from '../../../WarehouseDetails/components/WarehouseItem';
import WarehouseItemsContainer from '../../../WarehouseDetails/components/WarehouseItemsContainer';
import WarehouseLabel from '../../../WarehouseDetails/components/WarehouseLabel';
import { useWarehouseLocationsQuery } from '../../graphql/queries/generated/warehouseLocations';
import { WarehouseLocationsProps } from '../../types';

const WarehouseZones = (props: WarehouseLocationsProps) => {
  const { warehouseId, onSelectItem } = props;

  const [{ fetching, data }] = useWarehouseLocationsQuery({
    variables: { filters: { warehouseIds: [warehouseId] }, input: {} },
  });

  if (fetching) {
    return <Loader />;
  }

  const locations = getWarehouseLocations(data);

  if (!locations?.length) {
    return (
      <Stack className="py-32" justifyContent="center">
        <WarehouseLabel>{Warehouse.NoZoneAvailable}</WarehouseLabel>
      </Stack>
    );
  }

  return (
    <Box className={cx('mt-48 flex flex-wrap gap-[24px]')}>
      {locations.map((location) => {
        const config = WAREHOUSE_LABEL_CONFIG.zones;
        const { zone } = parseWarehouseLocationName(location);
        const aisleCount = config.getSubItemCount(location);

        return (
          <Stack key={zone} direction="col" spacing="space-y-10">
            <WarehouseLabel className="text-center">
              {config.labelPrefix} {zone}
            </WarehouseLabel>
            <WarehouseItemsContainer
              className="m-[auto] w-full  cursor-pointer space-y-8 md:w-[158px] lg:w-[158px] xl:w-[158px]"
              onClick={() => onSelectItem(location)}>
              {times(aisleCount, (aisleIndex) => {
                const config = WAREHOUSE_LABEL_CONFIG.aisles;
                return (
                  <WarehouseItem
                    key={aisleIndex}
                    label={config.getLabel(aisleIndex)}
                    className="h-[36px] w-[126px]"
                  />
                );
              })}
            </WarehouseItemsContainer>
          </Stack>
        );
      })}
    </Box>
  );
};

export default WarehouseZones;
