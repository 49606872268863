import './index.css';
import 'font-awesome/css/font-awesome.css';
import '@procurenetworks/procure-component-library/build/index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { LicenseInfo } from '@procurenetworks/procure-component-library';
import { APP_CONFIG } from 'app/consts/config';

import BugReportingService from './app/libs/sentry';
import Bootstrap from './app/pages';

BugReportingService.init();

LicenseInfo.setLicenseKey(APP_CONFIG.muiPremiumLicenseKey);

ReactDOM.render(
  <React.StrictMode>
    <Bootstrap />
  </React.StrictMode>,
  document.getElementById('root'),
);
