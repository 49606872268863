const Warehouse = {
  Warehouse: 'Warehouse',
  WarehouseSetup: 'Warehouse Setup',
  SelectSite: 'Select Site',
  NoZoneAvailable: 'No Zone Available',
  PrintLabels: 'Print Labels',
  GenerateLabels: 'Generate Labels',
  WarehouseSetupSuccess:
    'Locations generated successfully under Locations tab.\nPlease wait for a new browser tab to appear with location labels in PDF format.',
  WarehouseSetupSuccessWithPdf: 'Locations generated successfully under Locations tab.',
  Setup: {
    Placeholder: 'Enter number',
    Required: 'This is required',
    AislesMinError: 'Please enter aisles greater than 0.',
    RacksMinError: 'Please enter racks greater than 0.',
    ZonesMinError: 'Please enter zones greater than 0.',
    ShelvesMinError: 'Please enter shelves greater than 0.',
    BinsMinError: 'Please enter bins greater than 0.',
    Zones: 'How many Zones do you want to add?',
    ZonesError: 'Zones cannot be greater than 26',
    Aisles: 'How many Aisles do you want to add?',
    AislesError: 'Aisles cannot be greater than 20',
    Racks: 'How many Racks do you want to add?',
    RacksError: 'Racks cannot be greater than 20',
    Shelves: 'How many Shelves do you want to add?',
    ShelvesError: 'Shelves cannot be greater than 26',
    Bins: 'How many Bins do you want to add?',
    BinsError: 'Bins cannot be greater than 20',
  },
};

export default Warehouse;
