import { useEffect, useMemo, useState } from 'react';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material';
import Loader from 'app/components/Loader';
import SnackbarProvider from 'app/components/Snackbar';
import { APP_CONFIG, SESSION_STORAGE_KEY, SESSION_STORAGE_VALUE } from 'app/consts/config';
import { UrqlProvider } from 'app/libs/urql';
import { getUrqlClient } from 'app/libs/urql/getClient';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import authModel from 'app/modules/auth/model';
import currentUser from 'app/modules/auth/utils/currentUser';

import Routes from './Routes';
import theme from 'app/theme/muiTheme';

function Bootstrap() {
  const user = useCurrentUser();
  const isLoggedIn = Boolean(user.id);
  const [isLoading, setIsLoading] = useState(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const client = useMemo(() => getUrqlClient(), [isLoggedIn]);

  useEffect(() => {
    const toggle = () => setIsLoading(false);

    if (currentUser.id()) {
      authModel
        .fetchUser(client)
        .then(() => {
          toggle();
        })
        .catch(() => {
          toggle();
          authModel.clearUser();
        });
    } else {
      toggle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      localStorage.setItem(SESSION_STORAGE_KEY, SESSION_STORAGE_VALUE);
    }
  }, [isLoggedIn]);

  if (isLoading) {
    return <Loader fullscreen />;
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          {/*<UnifiedProvider config={config} currentApp="core" env={env} isLoggedIn={isLoggedIn}>*/}
          <UrqlProvider value={client}>
            <Routes isLoggedIn={isLoggedIn} />
          </UrqlProvider>
          {/*</UnifiedProvider>*/}
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default Bootstrap;
