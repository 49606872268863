import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, InputAdornment } from '@mui/material';
import { CustomIcons } from '@procurenetworks/procure-component-library';
import { SnackbarService } from 'app/components/Snackbar';
import routes from 'app/consts/routes';
import usePagination from 'app/hooks/usePagination';
import Common from 'app/i18n/Common';
import Location from 'app/i18n/Location';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import EntityManager from 'app/modules/components/EntityManager';
import useMultiSelectEntityManager from 'app/modules/components/EntityManager/useMultiSelectEntityManager';
import FormSiteSelect from 'app/modules/locations/components/FormSiteSelect';
import { AllowedPermissionsSubjectEnum, LocationTypeEnum } from 'app/types/schema';

import { showReasonsError } from '../../../../utils/reasons';
import useSearchSuggestion from '../../hook/useSearchSuggestion';
import { useDeleteLocationsMutation } from './graphql/mutations/generated/deleteLocations';
import { useLocationsTableQuery } from './graphql/queries/generated/locationTable';
import { getLocationsTableColumns } from './utils/columns';
function Locations(props: RouteComponentProps) {
  const { history } = props;
  const [siteId, setSiteId] = useState<string | undefined>(undefined);
  const [selectedOption, setSelectedOption] = useState(null);
  const [{ fetching: disabled }, executeDeleteLocations] = useDeleteLocationsMutation();

  const onEdit = useCallback(
    (id: string) => {
      history.push(routes.EditLocation(id));
    },
    [history],
  );

  const onDelete = useCallback(
    (ids: string[]) => {
      executeDeleteLocations({ input: { locationIds: ids } }).then((response) => {
        const { success, reasons } = response.data?.deleteLocations || {};
        if (success) {
          SnackbarService.show({ message: 'This location has been successfully deleted.' });
        } else {
          showReasonsError(reasons);
        }
      });
    },
    [executeDeleteLocations],
  );

  const { search, table } = useMultiSelectEntityManager({ onEdit, onDelete });

  const { fetching, data, onNextPage, onPrevPage, onReset } = usePagination(
    useLocationsTableQuery,
    {
      filters: { search: search.debouncedSearchText, types: [LocationTypeEnum.Location], siteId },
      sorts: table.state.sorts,
    },
    { edgeKey: 'locations', pageSize: table.state.numberOfRowsPerPage },
  );

  const rows = useMemo(() => {
    return data?.locations.edges?.map(({ node }) => node) || [];
  }, [data?.locations.edges]);

  useEffect(() => {
    const updatedSelectedRowsData = table.state.selectedRowData.filter((row) => {
      return table.state.selectedRowIds.includes(row.id);
    });

    const newSelectedIds = table.state.selectedRowIds.filter((id) => {
      return !table.state.selectedRowData.find((data) => data.id === id);
    });

    const newRows = rows.filter((row: any) => newSelectedIds.includes(row.id));
    table.setState({
      selectedRowData: [...updatedSelectedRowsData, ...newRows],
    });
  }, [table.state.selectedRowIds, rows, table.setState]);

  const { searchSuggestions, setSearchValue } = useSearchSuggestion();

  return (
    <EntityManager
      layoutWrapperClass
      className="pt-0"
      subject={AllowedPermissionsSubjectEnum.Location}>
      <EntityManager.Title documentTitle={Location.Locations} title={Location.Locations} />
      <EntityManager.Add label={Location.NewLocation} to={routes.AddLocation()} />
      <Box
        className="mt-[24px] rounded-[12px] border border-grey-300 pb-[24px]"
        sx={{
          boxShadow:
            '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
        }}>
        <EntityManager.MultiSelectTable
          ignoreRelayPagination
          pagination
          persistSelectionData
          actions={table.actions}
          columns={getLocationsTableColumns()}
          data={rows}
          filterNodes={
            <Box className="flex !w-[100%] gap-[16px] md:flex-wrap md:gap-[24px]">
              <EntityManager.Search
                isDropdown
                removedSearchQoutedValue
                suggestionData={searchSuggestions || []}
                onChangeSearch={setSearchValue}
                {...search}
                autoFocus
                placeholder="Search"
                startAdornment={
                  <InputAdornment className="mt-[4px] ml-[5px]" position="start">
                    <CustomIcons.SearchIcon />
                  </InputAdornment>
                }
              />
              <FormSiteSelect
                enabledAllOption
                className="w-full md:w-[374px] lg:w-[180px]"
                placeholder={Common.All}
                value={siteId}
                onChange={(value, option) => {
                  setSiteId(value);
                  setSelectedOption(option);
                }}
              />
            </Box>
          }
          loading={fetching || disabled}
          paginationWrapperClass="p-[24px] flex-wrap md:gap-[24px]"
          persistKey="locations_table"
          setState={table.setState}
          state={table.state}
          total={data?.locations.totalCount}
          onNextPage={onNextPage}
          onPrevPage={onPrevPage}
          onReset={onReset}
        />
      </Box>
    </EntityManager>
  );
}

export default withRouter(Locations);
