import { useCallback, useEffect, useMemo } from 'react';
import { Box, InputAdornment } from '@mui/material';
import { Button, CustomIcons } from '@procurenetworks/procure-component-library';
import { SnackbarService } from 'app/components/Snackbar';
import routes from 'app/consts/routes';
import usePagination from 'app/hooks/usePagination';
import Role from 'app/i18n/Role';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import EntityManager from 'app/modules/components/EntityManager';
import useMultiSelectEntityManager from 'app/modules/components/EntityManager/useMultiSelectEntityManager';
import { getAllowedRoleTypes } from 'app/modules/roles/utils';
import { AllowedPermissionActionsEnum, AllowedPermissionsSubjectEnum } from 'app/types/schema';
import Stack from 'app/ui-components/Stack';

import AccessControl, { isAccessible } from '../../../../components/AccessControl';
import useSearchSuggestion from '../../hook/useSearchSuggestion';
import RoleActions from './components/RoleActions';
import { useDeleteRolesMutation } from './graphql/mutations/generated/deleteRoles';
import { useRolesTableQuery } from './graphql/queries/generated/rolesTable';
import { getRolesTableColumns } from './utils/columns';

function Roles(props: RouteComponentProps) {
  const { history } = props;
  const currentUser = useCurrentUser();
  const { workspacePermissions } = currentUser;

  const [{ fetching: disabled }, executeDeleteRoles] = useDeleteRolesMutation();

  const onEdit = useCallback(
    (id: string) => {
      history.push(routes.EditRole(id));
    },
    [history],
  );

  const onDelete = useCallback(
    (ids: string[]) => {
      executeDeleteRoles({ input: { roleIds: ids } }).then((response) => {
        if (response.data?.deleteRoles?.success) {
          SnackbarService.show({ message: 'This role has been successfully deleted.' });
        }
      });
    },
    [executeDeleteRoles],
  );

  const selection =
    isAccessible(
      workspacePermissions,
      AllowedPermissionActionsEnum.Edit,
      AllowedPermissionsSubjectEnum.Role,
    ) ||
    isAccessible(
      workspacePermissions,
      AllowedPermissionActionsEnum.Delete,
      AllowedPermissionsSubjectEnum.Role,
    ) ||
    isAccessible(
      workspacePermissions,
      AllowedPermissionActionsEnum.Read,
      AllowedPermissionsSubjectEnum.Role,
    );

  const { search, table } = useMultiSelectEntityManager({
    selection: selection,
  });

  const { fetching, data, onNextPage, onPrevPage, onReset } = usePagination(
    useRolesTableQuery,
    {
      filters: {
        search: search.debouncedSearchText,
        types: getAllowedRoleTypes(currentUser),
      },
      sorts: table.state.sorts,
    },
    { edgeKey: 'roles', pageSize: table.state.numberOfRowsPerPage },
  );

  const rows = useMemo(() => {
    return data?.roles.edges?.map(({ node }) => node) || [];
  }, [data?.roles.edges]);

  useEffect(() => {
    const updatedSelectedRowsData = table.state.selectedRowData.filter((row) => {
      return table.state.selectedRowIds.includes(row.id);
    });

    const newSelectedIds = table.state.selectedRowIds.filter((id) => {
      return !table.state.selectedRowData.find((data) => data.id === id);
    });

    const newRows = rows.filter((row: any) => newSelectedIds.includes(row.id));
    table.setState({
      selectedRowData: [...updatedSelectedRowsData, ...newRows],
    });
  }, [table.state.selectedRowIds, rows, table.setState]);

  const { searchSuggestions, setSearchValue } = useSearchSuggestion();

  return (
    <EntityManager className="pt-0">
      <EntityManager.Title documentTitle={Role.UserRoles} title={Role.Roles} />
      <AccessControl
        action={AllowedPermissionActionsEnum.Create}
        permissions={workspacePermissions}
        subject={AllowedPermissionsSubjectEnum.Role}>
        <Button
          classes="!mt-16 min-w-[204px] h-[44px]"
          theme="success"
          onClick={() => {
            history.push(routes.AddRole());
          }}>
          {Role.NewRole}
        </Button>
      </AccessControl>

      <Box
        className="mt-[24px] rounded-[12px] border border-grey-300 pb-[24px]"
        sx={{
          boxShadow:
            '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
        }}>
        <EntityManager.MultiSelectTable
          ignoreRelayPagination
          pagination
          persistSelectionData
          actions={table.actions}
          columns={getRolesTableColumns()}
          data={rows}
          filterNodes={
            <Box className="flex !w-[100%] gap-[16px] md:flex-wrap md:gap-[24px]">
              <EntityManager.Search
                {...search}
                autoFocus
                isDropdown
                removedSearchQoutedValue
                placeholder="Search"
                startAdornment={
                  <InputAdornment className="mt-[4px] ml-[5px]" position="start">
                    <CustomIcons.SearchIcon />
                  </InputAdornment>
                }
                suggestionData={searchSuggestions || []}
                onChangeSearch={setSearchValue}
              />
            </Box>
          }
          loading={fetching || disabled}
          paginationWrapperClass="p-[24px] flex-wrap md:gap-[24px]"
          persistKey="roles_table"
          setState={table.setState}
          state={table.state}
          total={data?.roles.totalCount}
          onNextPage={onNextPage}
          onPrevPage={onPrevPage}
          onReset={onReset}
        />
      </Box>
      <RoleActions table={table} onDelete={onDelete} onEdit={onEdit} />
    </EntityManager>
  );
}

export default withRouter(Roles);
