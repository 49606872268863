import routes from 'app/consts/routes';
import { Redirect, RouteComponentProps, withRouter } from 'app/libs/navigation';

function LoginRedirect(props: RouteComponentProps) {
  const { history } = props;
  const pathname = history.location.pathname;
  const search = history.location.search;
  const redirectTo = pathname && pathname !== '/' ? `${pathname}${search}` : '';

  return <Redirect to={`${routes.Login()}${redirectTo ? `?redirectTo=${redirectTo}` : ''}`} />;
}

export default withRouter(LoginRedirect);
