import { useCallback, useMemo } from 'react';
import { cx } from '@emotion/css';
import { TitleBar } from '@procurenetworks/procure-component-library';
import { SnackbarService } from 'app/components/Snackbar';
import routes from 'app/consts/routes';
import Project from 'app/i18n/Project';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import { titlebarStyles } from 'app/modules/components/EntityManager/styles';
import { CreateProjectInput } from 'app/types/schema';
import Box from 'app/ui-components/Box';

import ProjectForm from '../../components/ProjectForm';
import { useUpdateProjectMutation } from './graphql/mutations/generated/updateProject';
import { useEditProjectDataQuery } from './graphql/queries/generated/editProjectData';

type Props = RouteComponentProps & { projectId: string };

const EditProject = (props: Props) => {
  const { projectId, history } = props;

  const [{ fetching: disabled }, onUpdateProject] = useUpdateProjectMutation();
  const [{ data }] = useEditProjectDataQuery({
    variables: { filters: { projectIds: [projectId] } },
    requestPolicy: 'network-only',
  });

  const project = useMemo<CreateProjectInput | null>(() => {
    const projectNode = data?.projects.edges?.[0]?.node;

    if (projectNode) {
      return {
        name: projectNode.name,
        projectCode: projectNode.projectCode ?? '',
        description: projectNode.formattedDescription ?? '',
      };
    }

    return null;
  }, [data?.projects.edges]);

  const onSubmit = useCallback(
    (input: CreateProjectInput) => {
      onUpdateProject({ input: { ...input, projectId } }).then((response) => {
        if (response.data?.updateProject.project?.id) {
          SnackbarService.show({ message: 'This project has been successfully updated.' });
          history.replace(routes.Projects());
        }
      });
    },
    [history, onUpdateProject, projectId],
  );

  const onCancel = useCallback(() => {
    history.replace(routes.Projects());
  }, [history]);

  if (!project) {
    return null;
  }

  return (
    <Box className="bg-white px-24 pt-0 pb-16">
      <Box className={cx('max-w-[382px] space-y-40', titlebarStyles)}>
        <TitleBar title={Project.EditProject} />
        <ProjectForm
          defaultValues={project}
          disabled={disabled}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      </Box>
    </Box>
  );
};

export default withRouter(EditProject);
