import { useCallback, useState } from 'react';
import camelCase from 'lodash/camelCase';
import { ExportReportEntityTypeEnum } from 'app/consts/enum';
import Reports from 'app/i18n/Reports';
import ReportContainer from 'app/modules/reports/components/ReportContainer';
import { SortOrderEnum, TransactionEntityTypeEnum } from 'app/types/schema';
import { getCurrentTimeZoneValue } from 'app/utils/timezone';

import useReportCSVExport from '../../hooks/useReportCSVExport';
import { getItemLocationItemTypesFromTransactionTypes } from '../../utils/itemtypes';
import AgingReportForm from './components/AgingReportForm';
import { AgingReportFormValues } from './components/AgingReportForm/types';
import AssetAgingTable from './components/AssetAgingTable';
import InventoryAgingTable from './components/InventoryAgingTable';

const AgingReport = () => {
  const [values, setValues] = useState<AgingReportFormValues | null>(null);
  const { executeExport } = useReportCSVExport();

  const renderPreviewTable = () => {
    if (values?.type === TransactionEntityTypeEnum.Inventory) {
      return <InventoryAgingTable values={values} />;
    }

    if (values?.type === TransactionEntityTypeEnum.Asset) {
      return <AssetAgingTable values={values} />;
    }

    return null;
  };

  const onSubmit = useCallback(
    (nextValues: AgingReportFormValues | null) => {
      if (nextValues?.format === 'csv') {
        const filters = {
          itemLocationFilters: {
            itemIds: nextValues.skuIds,
            siteIds: nextValues.siteIds,
            categoryIds: nextValues.categoryIds,
            itemTypes: getItemLocationItemTypesFromTransactionTypes(nextValues.itemTypes).map(
              camelCase,
            ),
            nonZeroTotalQuantity: true,
            updatedAt: nextValues.updatedAt,
          },
        };
        const sorts = [{ sortField: 'updatedAt', sortOrder: camelCase(SortOrderEnum.Desc) }];

        if (nextValues.type === TransactionEntityTypeEnum.Inventory) {
          executeExport({
            entityType: ExportReportEntityTypeEnum.INVENTORY_AGING,
            filters,
            sorts,
            userTimezone: getCurrentTimeZoneValue(),
          });
        } else if (nextValues.type === TransactionEntityTypeEnum.Asset) {
          executeExport({
            entityType: ExportReportEntityTypeEnum.ASSET_AGING,
            filters,
            sorts,
            userTimezone: getCurrentTimeZoneValue(),
          });
        }
      }

      setValues(nextValues);
    },
    [executeExport],
  );

  return (
    <ReportContainer
      title={Reports.AgingReport}
      stackWrapperClass="rounded-tr-[12px] rounded-br-[12px]">
      <AgingReportForm onSubmit={onSubmit} />
      {values?.format === 'preview' ? renderPreviewTable() : null}
    </ReportContainer>
  );
};

export default AgingReport;
