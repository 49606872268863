import { memo, useCallback, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { Button } from '@procurenetworks/procure-component-library';
import FormSelect from 'app/components/Form/FormSelect';
import { SelectOption } from 'app/components/Select/types';
import Common from 'app/i18n/Common';
import Label from 'app/i18n/Label';
import EntityManager from 'app/modules/components/EntityManager';
import FormNumberInput from 'app/modules/components/FormNumberInput';
import FormSiteSelect from 'app/modules/locations/components/FormSiteSelect';
import {
  LocationTypeEnum,
  ShippingContainerSchema,
  ShippingContainerTypePrefixEnum,
} from 'app/types/schema';
import Box from 'app/ui-components/Box';
import Stack from 'app/ui-components/Stack';

import useLabelsFrom from '../../../context/useLabelsFrom';
import { sortLabelData } from '../../../hooks/useLabelsTable/utils/utils';
import { useCreateShippingContainerMutation } from '../graphql/mutations/generated/createShippingContainer';
import { LabelStockEnum, LabelTypeEnum, ShippingSetupFormProps } from '../types';
import { getContainerTypeOptions } from '../utils';
import { getValidationRules } from '../utils/validation';

const ShippingSetupForm = (props: ShippingSetupFormProps) => {
  const { control, values, trigger, setData, handleSubmit } = useLabelsFrom();
  const isValid = useMemo(() => getValidationRules(values?.type), [values?.type]);
  const [{ fetching }, onCreateContainer] = useCreateShippingContainerMutation();

  const onPrintLabelsSubmit = useCallback(() => {
    const isValid = trigger && trigger();
    if (values && isValid) {
      onCreateContainer({
        input: {
          containerType: values?.containerType as ShippingContainerTypePrefixEnum,
          count: parseInt(values?.labelsCount as unknown as string),
          destinationSiteId: values?.destinationSite,
        },
      }).then((response: any) => {
        const shippingContainers =
          response?.data && response.data?.createShippingContainer?.shippingContainers;
        if (shippingContainers?.length > 0) {
          const ids = shippingContainers.map((container: ShippingContainerSchema) => container?.id);
          const sortedData = sortLabelData({
            data: shippingContainers,
            type: LabelTypeEnum.ShippingSetUp,
            labelStock: values.labelStock as LabelStockEnum | undefined,
          });
          setData && setData({ ...values, ids: ids, data: sortedData });
          localStorage.setItem(
            'printLabels',
            JSON.stringify({ ...values, ids: ids, data: sortedData }),
          );
          window.open('/admin/print-label', '_blank');
        } else {
          console.error('[Create Location] Failed', response);
        }
      });
    }
  }, [values]);

  const onCancel = useCallback(() => {
    window.location.reload();
  }, []);

  const ContainerTypeOptions = useMemo(() => {
    return getContainerTypeOptions();
  }, [getContainerTypeOptions]);

  const onPrintLabels = useMemo(
    () => handleSubmit?.(onPrintLabelsSubmit),
    [handleSubmit, onPrintLabelsSubmit],
  );

  return (
    <form>
      <Box className="max-w-[382px] space-y-24 py-16">
        <EntityManager.Title title={Label.Shipping.ShippingSetup} />
        <Controller
          control={control}
          name="destinationSite"
          render={({ field, fieldState }) => (
            <FormSiteSelect
              {...field}
              isRequired
              className="flex-1"
              error={fieldState.error}
              label={Label.Form.ShippingSetup.DestinationSite}
              placeholder={Label.Form.SelectOne}
              types={[LocationTypeEnum.Site, LocationTypeEnum.PartnerTenant]}
              selectClassName="mt-[6px]"
            />
          )}
          rules={isValid?.DestinationSite}
        />
        <Controller
          control={control}
          name="containerType"
          render={({ field, fieldState }) => (
            <FormSelect
              {...field}
              isRequired
              className="flex-1"
              error={fieldState.error}
              getOptionDisabled={(option: SelectOption) => option === ContainerTypeOptions[0]}
              label={Label.Form.ShippingSetup.ContainerType}
              options={ContainerTypeOptions}
              placeholder={Label.Form.SelectOne}
            />
          )}
          rules={isValid?.Container}
        />
        <Controller
          control={control}
          name="labelsCount"
          render={({ field, fieldState }) => (
            <FormNumberInput
              {...field}
              isRequired
              className="flex-1"
              error={fieldState.error}
              label={Label.Form.ShippingSetup.LabelsCount}
              maximumLength={10}
              placeholder={Label.Form.EnterNumber}
            />
          )}
          rules={isValid?.LabelCount}
        />
        <Stack className="mt-8 gap-[16px] px-16" justifyContent="end">
          <Button
            loading={fetching}
            theme="success"
            onClick={onPrintLabels}
            classes="min-w-[120px] h-[44px]">
            {Label.PrintLabels}
          </Button>
          <Button classes="min-w-[120px] h-[44px]" onClick={onCancel}>
            {Common.Actions.Cancel}
          </Button>
        </Stack>
      </Box>
    </form>
  );
};

export default memo(ShippingSetupForm);
