import { useCallback, useMemo } from 'react';
import { SnackbarService } from 'app/components/Snackbar';
import routes from 'app/consts/routes';
import AssetModel from 'app/i18n/AssetModel';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import { AssetModelItemSchema } from 'app/types/schema';

import AssetModelForm from '../../../../components/AssetModelForm';
import { AssetModelItemFormInput } from '../../../../components/AssetModelForm/type';
import { updateAssetModelInput } from '../../../../components/AssetModelForm/utils/dto';
import { useUpdateAssetModelItemMutation } from '../../graphql/mutations/generated/updateAssetModelItem';
import { prepareFormDataFromAssetModel } from './utils';

type Props = {
  item: AssetModelItemSchema;
} & RouteComponentProps;
const EditAssetModelContent = (props: Props) => {
  const { item, history } = props;

  const [{ fetching }, onUpdateAssetModelItem] = useUpdateAssetModelItemMutation();

  const formValues = useMemo(() => {
    return prepareFormDataFromAssetModel(item);
  }, [item]);

  const onCancel = useCallback(() => {
    history.push(routes.AssetModels());
  }, [history]);

  const onSubmit = useCallback(
    (values: AssetModelItemFormInput) => {
      onUpdateAssetModelItem({
        input: updateAssetModelInput(values),
      }).then((response) => {
        if (response?.data?.updateAssetModelItem?.success) {
          SnackbarService.show({
            message: AssetModel.SuccessMessages.AssetModelUpdated,
          });
          onCancel();
        }
      });
    },
    [onUpdateAssetModelItem, onCancel],
  );

  return (
    <AssetModelForm
      defaultValues={formValues}
      disabled={fetching}
      fetching={fetching}
      onCancel={onCancel}
      onSubmit={onSubmit}
    />
  );
};

export default withRouter(EditAssetModelContent);
