import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { Button } from '@procurenetworks/procure-component-library';
import { CollapsibleComponentProps } from '@procurenetworks/procure-component-library/build/components/Table/Table.types';
import { SnackbarService } from 'app/components/Snackbar';

import Common from '../../../../i18n/Common';
import Inventory from '../../../../i18n/Inventory';
import { ItemLocationSchema } from '../../../../types/schema';
import EntityManager from '../../../components/EntityManager';
import useEntityManager from '../../../components/EntityManager/useEntityManager';
import { useUpdateQuantityConfigurationsAtItemLocationsMutation } from './graphql/mutations/generated/updateQuantityConfigurationsAtItemLocations';
import useQuantityBySiteState from './hook/UseQuantityBySiteState';
import {
  getLocationColumns,
  getLocationWithQuantityColumns,
  getQuantityBySiteColumns,
} from './utils/columns';

interface Props {
  stockInformation: ItemLocationSchema[];
  itemId: string;
  disabled?: boolean;
}

interface ICollapsibleComponentProps extends CollapsibleComponentProps {
  windowSize?: number;
}
const LocationTable: FunctionComponent<ICollapsibleComponentProps> = (
  props: ICollapsibleComponentProps,
) => {
  const { row, windowSize } = props;
  const { locations = [] } = row;

  const { table } = useEntityManager({
    selection: false,
  });

  return (
    <div className="ml-[60px] mb-[20px] mt-[16px] max-w-[100%]">
      <EntityManager.Table
        actions={table.actions}
        columns={getLocationWithQuantityColumns(window.innerWidth)}
        data={locations}
        minWidth={950}
        setState={table.setState}
        state={table.state}
        total={locations.length}
        tableBorderRadius="12px"
        tableBorder="none"
      />
    </div>
  );
};

interface LocationTableForAssetInventoryProps {
  siteId?: string;
  row: any;
}
export const LocationTableForAssetInventory = (props: LocationTableForAssetInventoryProps) => {
  const { siteId, row } = props;
  const stockInformation = useMemo(
    () => row?.parsedStockInformation || [],
    [row?.parsedStockInformation],
  );
  const { table } = useEntityManager({
    selection: false,
  });

  return (
    <div className="ml-[75px] mb-[20px] mt-[16px] w-[305px] max-w-[850px]">
      <EntityManager.Table
        actions={table.actions}
        columns={getLocationColumns()}
        data={stockInformation}
        extraProps={{
          siteId,
        }}
        setState={table.setState}
        state={{ ...table.state, numberOfRowsPerPage: stockInformation?.length }}
        tableBorderRadius="12px"
        tableBorder="none"
      />
    </div>
  );
};

const QuantityBySite = (props: Props) => {
  const { stockInformation, itemId, disabled } = props;
  const [windowSize, setWindowSize] = useState<number>(window.innerWidth);
  const [{ fetching }, onUpdateQuantityAtItemLocations] =
    useUpdateQuantityConfigurationsAtItemLocationsMutation();

  const {
    table,
    rows,
    toggleEditableSiteState,
    toggleEditableSitesState,
    setEditableSiteState,
    isSiteRowInEditableMode,
    isValidUpdateQuantityAtItemLocation,
    isAllSitesEditable,
    onCancelEditableSitesState,
    updateQuantityAtItemLocationInputs,
  } = useQuantityBySiteState({
    stockInformation,
    itemId,
  });

  const onSaveQuantityBySite = useCallback(() => {
    onUpdateQuantityAtItemLocations({
      input: updateQuantityAtItemLocationInputs,
    }).then((response) => {
      if (response?.data?.updateQuantityConfigurationsAtItemLocations?.success) {
        SnackbarService.show({
          message: Inventory.SuccessMessages.QuantityMinMaxBySiteUpdated,
        });
        onCancelEditableSitesState();
      } else {
        // TODO: show Error message
        console.error('[onSaveQuantityBySite] Failed!', response);
      }
    });
  }, [updateQuantityAtItemLocationInputs, onUpdateQuantityAtItemLocations]);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <EntityManager.Title title={Inventory.QuantityBySite} />

      <EntityManager.CollapsibleTable
        collapsible
        collapsibleNode={(props) => <LocationTable {...props} windowSize={windowSize} />}
        columns={getQuantityBySiteColumns(disabled)}
        data={rows}
        extraProps={{
          toggleEditableSiteState,
          toggleEditableSitesState,
          setEditableSiteState,
          isAllSitesEditable,
        }}
        setState={table.setState}
        state={table.state}
        total={rows.length}
        colSpan={3}
      />

      <Grid container className="mt-12" justifyContent="flex-end">
        <Grid item xs="auto">
          <div className="flex gap-[16px]">
            {isAllSitesEditable || isSiteRowInEditableMode ? (
              <>
                <Button
                  disabled={!isSiteRowInEditableMode || !isValidUpdateQuantityAtItemLocation}
                  loading={fetching}
                  theme="success"
                  onClick={onSaveQuantityBySite}
                  classes="min-w-[94px] h-[44px]">
                  {Common.Actions.Save}
                </Button>
                <Button
                  classes="min-w-[94px] h-[44px]"
                  disabled={!isSiteRowInEditableMode || fetching}
                  onClick={onCancelEditableSitesState}>
                  {Common.Actions.Cancel}
                </Button>
              </>
            ) : null}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default QuantityBySite;
