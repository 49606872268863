import { useCallback } from 'react';
import { cx } from '@emotion/css';
import { TitleBar } from '@procurenetworks/procure-component-library';
import { SnackbarService } from 'app/components/Snackbar';
import routes from 'app/consts/routes';
import Roles from 'app/i18n/Role';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import { titlebarStyles } from 'app/modules/components/EntityManager/styles';
import { CreateRoleInput } from 'app/types/schema';
import Box from 'app/ui-components';
import { removeExtraSpacesAndNewlines } from 'app/utils/removeMultipleSpaces';

import AddRoleForm from './components/AddRoleForm';
import { useCreateRoleMutation } from './graphql/mutations/generated/createRole';

const DEFAULT_VALUES: CreateRoleInput = {
  name: '',
  description: '',
  metadata: { configuredPermissions: [] },
};

const AddRole = ({ history }: RouteComponentProps) => {
  const [{ fetching }, onCreateRole] = useCreateRoleMutation();

  const onSubmit = useCallback(
    ({ description, name, metadata }: CreateRoleInput) => {
      onCreateRole({
        input: {
          description: removeExtraSpacesAndNewlines(description || ''),
          formattedDescription: description,
          name,
          metadata,
        },
      }).then((response) => {
        if (response.data?.createRole?.role?.id) {
          SnackbarService.show({ message: 'This role has been successfully created.' });
          history.replace(routes.Roles());
        }
      });
    },
    [history, onCreateRole],
  );

  const onCancel = useCallback(() => {
    history.replace(routes.Roles());
  }, [history]);

  return (
    <Box className="bg-white px-24 pt-0 pb-16">
      <Box className={cx('space-y-40', titlebarStyles)}>
        <TitleBar hasTopDivider title={Roles.NewRole} />
        <Box className="max-w-[532px] ">
          <AddRoleForm
            defaultValues={DEFAULT_VALUES}
            disabled={fetching}
            onCancel={onCancel}
            onSubmit={onSubmit}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default withRouter(AddRole);
