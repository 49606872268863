import { useCallback, useMemo } from 'react';
import { Box, InputAdornment } from '@mui/material';
import { CustomIcons } from '@procurenetworks/procure-component-library';
import { SnackbarService } from 'app/components/Snackbar';
import routes from 'app/consts/routes';
import usePagination from 'app/hooks/usePagination';
import Organization from 'app/i18n/Organization';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import EntityManager from 'app/modules/components/EntityManager';
import useEntityManager from 'app/modules/components/EntityManager/useEntityManager';
import { AllowedPermissionsSubjectEnum } from 'app/types/schema';

import useSearchSuggestion from '../../hook/useSearchSuggestion';
import { useDeleteOrganizationMutation } from './graphql/mutations/generated/deleteOrganization';
import { useOrganizationsTableQuery } from './graphql/queries/generated/organizationsTable';
import { getOrganizationTableColumns } from './utils/columns';

const columns = getOrganizationTableColumns();

function Organizations({ history }: RouteComponentProps) {
  const [{ fetching: disabled }, deleteOrganization] = useDeleteOrganizationMutation();

  const onEdit = useCallback(
    (id: string) => {
      history.push(routes.EditOrganizations(id));
    },
    [history],
  );

  const onDelete = useCallback(
    (id: string) => {
      deleteOrganization({ input: { tenantId: id } }).then((response) => {
        if (response.data?.deleteTenant?.success) {
          SnackbarService.show({ message: 'This organization has been successfully deleted.' });
        }
      });
    },
    [deleteOrganization],
  );

  const { search, table } = useEntityManager({ onEdit, onDelete });

  const { fetching, data, onNextPage, onPrevPage, onReset } = usePagination(
    useOrganizationsTableQuery,
    {
      filters: { search: search.debouncedSearchText },
      sorts: table.state.sorts,
    },
    { edgeKey: 'tenants', pageSize: table.state.numberOfRowsPerPage },
  );

  const rows = useMemo(() => {
    return data?.tenants.edges.map((edge) => edge.node) || [];
  }, [data]);

  const { searchSuggestions, setSearchValue } = useSearchSuggestion();

  return (
    <EntityManager className="pt-0" subject={AllowedPermissionsSubjectEnum.Tenant}>
      <EntityManager.Title
        documentTitle={Organization.Organizations}
        title={Organization.Organizations}
      />
      <EntityManager.Add label={Organization.NewOrganization} to={routes.AddOrganization()} />

      <Box
        className="mt-[24px] rounded-[12px] border border-grey-300 pb-[24px]"
        sx={{
          boxShadow:
            '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
        }}>
        <EntityManager.Table
          ignoreRelayPagination
          persistSelectionData
          actions={table.actions}
          columns={columns}
          data={rows}
          filterNodes={
            <Box className="flex !w-[100%] gap-[16px] md:mb-[24px] md:flex-wrap md:gap-[24px]">
              <EntityManager.Search
                isDropdown
                removedSearchQoutedValue
                suggestionData={searchSuggestions || []}
                onChangeSearch={setSearchValue}
                {...search}
                autoFocus
                placeholder="Search"
                startAdornment={
                  <InputAdornment className="mt-[4px] ml-[5px]" position="start">
                    <CustomIcons.SearchIcon />
                  </InputAdornment>
                }
              />
            </Box>
          }
          footerWrapperClass="px-[24px] py-[16px]"
          loading={fetching || disabled}
          pagination={true}
          paginationWrapperClass="p-[24px] flex-wrap md:gap-[24px]]"
          persistKey="organizations_table"
          setState={table.setState}
          state={table.state}
          total={data?.tenants.totalCount}
          onNextPage={onNextPage}
          onPrevPage={onPrevPage}
          onReset={onReset}
        />
      </Box>
    </EntityManager>
  );
}

export default withRouter(Organizations);
