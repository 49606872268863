import { useCallback } from 'react';
import { LabelStockEnum, LabelTypeEnum } from 'app/modules/labels/views/Labels/components/Forms/types';
import {
  AddAislesInWarehouseInput,
  AddBinsInWarehouseInput,
  AddRacksInWarehouseInput,
  AddShelvesInWarehouseInput,
  AddZonesInWarehouseInput,
  CreateWarehouseInput,
  GetLastLocationNamesOfChildInput,
} from 'app/types/schema';

import { useAddAislesInWarehouseMutation } from '../../../WarehouseDetails/graphql/mutations/generated/addAislesInWarehouse';
import { useAddBinsInWarehouseMutation } from '../../../WarehouseDetails/graphql/mutations/generated/addBinsInWarehouse';
import { useAddRacksInWarehouseMutation } from '../../../WarehouseDetails/graphql/mutations/generated/addRacksInWarehouse';
import { useAddShelvesInWarehouseMutation } from '../../../WarehouseDetails/graphql/mutations/generated/addShelvesInWarehouse';
import { useAddZonesInWarehouseMutation } from '../../../WarehouseDetails/graphql/mutations/generated/addZonesInWarehouse';
import { useCreateWarehouseMutation } from '../../../WarehouseDetails/graphql/mutations/generated/createWarehouse';
import { Locations } from '../../types';
import { useAddRacksMutation } from '../../../WarehouseDetails/graphql/mutations/generated/addRacksInRackandShelf';
import { useAddShelvesMutation } from '../../../WarehouseDetails/graphql/mutations/generated/addShelvesinRackandShelf';

const useWarehouseSetup = () => {
  const [aisles, addAislesInWarehouse] = useAddAislesInWarehouseMutation();
  const [bins, addBinsInWarehouse] = useAddBinsInWarehouseMutation();
  const [racks, addRacksInWarehouse] = useAddRacksInWarehouseMutation();
  const [addRacks, addRacksInWarehouseRackandShelf] = useAddRacksMutation();
  const [shelves, addShelvesInWarehouse] = useAddShelvesInWarehouseMutation();
  const [addShelves, addShelvesInWarehouseRackandShelf] = useAddShelvesMutation();
  const [zones, addZonesInWarehouse] = useAddZonesInWarehouseMutation();
  const [warehouse, createWarehouse] = useCreateWarehouseMutation();

  const onAddAislesInWarehouse = useCallback(
    (input: AddAislesInWarehouseInput, query: GetLastLocationNamesOfChildInput) => {
      return addAislesInWarehouse({ input, query }).then((response) => {
        return response.data?.addAislesInWarehouse;
      });
    },
    [addAislesInWarehouse],
  );
  const onAddBinsInWarehouse = useCallback(
    (input: AddBinsInWarehouseInput, query: GetLastLocationNamesOfChildInput) => {
      return addBinsInWarehouse({ input, query }).then((response) => {
        return response.data?.addBinsInWarehouse;
      });
    },
    [addBinsInWarehouse],
  );
  const onAddRacksInWarehouse = useCallback(
    (input: AddRacksInWarehouseInput, query: GetLastLocationNamesOfChildInput) => {
      return addRacksInWarehouse({ input, query }).then((response) => {
        return response.data?.addRacksInWarehouse;
      });
    },
    [addRacksInWarehouse],
  );
  const onAddRacksInWarehouseRackandShelf = useCallback(
    (input: AddRacksInWarehouseInput) => {
      return addRacksInWarehouseRackandShelf({ input }).then((response) => {
        return response.data?.addRacksInWarehouse;
      });
    },
    [addRacksInWarehouseRackandShelf],
  ); 
  const onAddShelvesInWarehouse = useCallback(
    (input: AddShelvesInWarehouseInput, query: GetLastLocationNamesOfChildInput) => {
      return addShelvesInWarehouse({ input, query }).then((response) => {
        return response.data?.addShelvesInWarehouse;
      });
    },
    [addShelvesInWarehouse],
  );
  const onAddShelvesInWarehouseRackandShelf = useCallback(
    (input: AddShelvesInWarehouseInput) => {
      return addShelvesInWarehouseRackandShelf({ input }).then((response) => {
        return response.data?.addShelvesInWarehouse;
      });
    },
    [addShelvesInWarehouseRackandShelf],
  );
  const onAddZonesInWarehouse = useCallback(
    (input: AddZonesInWarehouseInput, query: GetLastLocationNamesOfChildInput) => {
      return addZonesInWarehouse({ input, query }).then((response) => {
        return response.data?.addZonesInWarehouse;
      });
    },
    [addZonesInWarehouse],
  );
  const onCreateWarehouse = useCallback(
    (input: CreateWarehouseInput) => {
      return createWarehouse({ input });
    },
    [createWarehouse],
  );

  const printWarehouseLocations = (locations: Locations) => {
    if (locations) {
      localStorage.setItem(
        'printLabels',
        JSON.stringify({
          arrowDisplay: 'up',
          type: LabelTypeEnum.Location,
          ids: [locations[0].id],
          data: locations,
          labelStock: LabelStockEnum.Avery6792
        }),
      );
      window.open('/admin/print-label', '_blank');
    }
  };

  return {
    loading:
      aisles.fetching ||
      bins.fetching ||
      racks.fetching ||
      shelves.fetching ||
      zones.fetching ||
      addShelves.fetching ||
      addRacks.fetching ||
      warehouse.fetching,

    onAddBinsInWarehouse,
    onAddAislesInWarehouse,
    onAddRacksInWarehouse,
    onAddShelvesInWarehouse,
    onAddRacksInWarehouseRackandShelf,
    onAddZonesInWarehouse,
    onAddShelvesInWarehouseRackandShelf,
    onCreateWarehouse,
    printWarehouseLocations,
  };
};

export default useWarehouseSetup;
