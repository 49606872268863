import Loader from 'app/components/Loader';

import { getWarehouseLocations, parseWarehouseLocationName, WAREHOUSE_LABEL_CONFIG, parseWarehouseLocationNameRackAndShelf } from '../../../WarehouseDetails/utils';
import { useWarehouseLocationsQuery } from '../../graphql/queries/generated/warehouseLocations';
import { WarehouseLocationsProps } from '../../types';
import WarehouseItems from '../WarehouseItems';
import { WarehouseTypeEnum } from 'app/types/schema';

const WarehouseShelves = (props: WarehouseLocationsProps) => {
  const { warehouseId, selectedItems, onSelectItem, warehouseType } = props;

  const item = warehouseType == WarehouseTypeEnum.RackAndShelf ? selectedItems[0] : selectedItems[2];
  const { zone, aisle, rack, shelf } = warehouseType == WarehouseTypeEnum.RackAndShelf ? parseWarehouseLocationNameRackAndShelf(item) : parseWarehouseLocationName(item);

  const [{ fetching, data }] = useWarehouseLocationsQuery({
    variables: {
      filters: { warehouseIds: [warehouseId] },
      input: warehouseType === WarehouseTypeEnum.RackAndShelf ? {
        rackNumber: Number(rack),
        maxChildIdentifier: shelf,
      } :  {
        zoneName: zone,
        aisleNumber: Number(aisle),
        rackNumber: Number(rack),
        maxChildIdentifier: shelf,
      },
    },
    requestPolicy: 'cache-and-network',
  });

  if (fetching) {
    return <Loader />;
  }

  const shelves = getWarehouseLocations(data);

  return (
    <WarehouseItems
      itemKey="shelves"
      items={shelves}
      label={warehouseType === WarehouseTypeEnum.RackAndShelf ? rack : WAREHOUSE_LABEL_CONFIG.racks.getLabelFromLocation(item)}
      subItemKey="bins"
      disabled={warehouseType === WarehouseTypeEnum.RackAndShelf}
      {...props}
    />
  );
};

export default WarehouseShelves;
