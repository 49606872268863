import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { cx } from '@emotion/css';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { Button } from '@procurenetworks/procure-component-library';
import moment from 'moment/moment';
import FormLabel from 'app/components/Form/FormLabel';
import FormSelect from 'app/components/Form/FormSelect';
import FormTextArea from 'app/components/Form/FormTextArea';
import FormTextInput from 'app/components/Form/FormTextInput';
import FormUsersSelect from 'app/components/Form/FormUsersSelect';
import FormCheckBoxInput from 'app/components/ProcureForm/FormCheckBoxInput';
import FormDatePickerInput from 'app/components/ProcureForm/FormDatePickerInput';
import Assets from 'app/i18n/Assets';
import Common from 'app/i18n/Common';
import Box from 'app/ui-components';
import Stack from 'app/ui-components/Stack';

import { UseAssetReminderStateReturnType } from '../../hook/useAssetReminderState';
import { ViewReminderContent } from '../../styles';
import { AssetItemReminderCronEnum } from './types';
import {
  ASSET_REMINDER_FORM_RULES,
  getReminderIntervalLimit,
  getReminderRepeatOptions,
} from './utils';
import { useUpdateAssetItemReminderMutation } from 'app/modules/assets/views/EditAsset/graphql/mutations/generated/updateAssetItemReminder';
import {
  createAddAssetItemReminderInput,
  createUpdateAssetItemReminderInput,
} from 'app/modules/assets/utils/dto';
import { useCreateAssetItemReminderMutation } from 'app/modules/assets/views/AddAsset/graphql/mutations/generated/addAssetReminder';
import { useDeleteAssetItemReminderMutation } from 'app/modules/assets/graphql/mutations/generated/deleteAssetItemReminder';

export interface AssetReminderFormProps {
  defaultValues: any;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  isEditMode?: boolean;
  deleting?: boolean;
  hideClose?: boolean;
  onSubmit: (values: any, id?: any) => void;
  mode: 'view' | 'add';
  id?: string | number;
  onDelete?: (id: any) => void;
  state: UseAssetReminderStateReturnType['state'];
  setState: UseAssetReminderStateReturnType['setState'];
  isRemindersViewMode?: boolean;
}

const AssetReminderForm = (props: AssetReminderFormProps) => {
  const {
    defaultValues,
    disabled,
    deleting,
    loading,
    mode,
    isEditMode,
    className,
    hideClose,
    onSubmit,
    onDelete,
    setState,
  } = props;

  const [{ fetching: creatingAssetItemReminders }, onCreateAssetItemReminder] =
    useCreateAssetItemReminderMutation();
  const [{ fetching: updatingAssetItemReminders }, onUpdateAssetItemReminder] =
    useUpdateAssetItemReminderMutation();
  const [{ fetching: deletingAssetItemReminders }, onDeleteAssetItemReminder] =
    useDeleteAssetItemReminderMutation();

  const isViewMode = useMemo(() => mode === 'view', [mode]);
  const [isEditable, setIsEditable] = useState(!isViewMode);
  const [createUpdateing, setCreateUpdateing] = useState(false);
  const [reminderDeleting, setReminderDeleting] = useState(false);

  useEffect(() => {
    setIsEditable(!(mode === 'view'));
  }, [mode, setIsEditable]);

  const editable = useMemo(
    () =>
      (!isViewMode && !defaultValues?.assetItemId) ||
      (isViewMode && defaultValues?.isNew && isEditable) ||
      (!isViewMode && defaultValues?.assetItemId && isEditMode) ||
      isEditable,
    [defaultValues?.assetItemId, defaultValues?.isNew, isEditMode, isViewMode, isEditable],
  );

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: defaultValues,
  });

  const reminderInterval = watch('recurringInterval');
  const reminderCron = watch('cron');
  const intervalLimit = useMemo(() => {
    return getReminderIntervalLimit(reminderCron);
  }, [reminderCron]);

  const onInterValChange = (direction: string) => {
    let _interval = reminderInterval;
    if (direction === 'up') {
      if (reminderCron === AssetItemReminderCronEnum.Year) {
        _interval = _interval + 1;
      } else if (intervalLimit && reminderInterval < intervalLimit) {
        _interval = _interval + 1;
      }
    } else {
      if (_interval > 1) {
        _interval = _interval - 1;
      }
    }
    setValue('recurringInterval', _interval);
  };

  const minStartDate = useMemo(() => {
    const date = new Date();
    return moment(date).format('YYYY-MM-DD');
  }, []);

  const handleClose = useCallback(() => {
    setState({ openViewReminder: false, openAddReminder: false });
  }, [setState]);

  const handleCancel = useCallback(async () => {
    if (isViewMode) {
      if (isEditable) {
        reset(defaultValues);
        setIsEditable(false);
      } else {
        if (isEditMode) {
          setReminderDeleting(true);
          await onDeleteAssetItemReminder({
            input: { reminderId: defaultValues?.id },
          });
        }
        onDelete?.(defaultValues?.id);
        setState({ openAddReminder: false });
        if (isEditMode) setReminderDeleting(false);
      }
    } else {
      handleClose();
    }
  }, [
    defaultValues,
    handleClose,
    isViewMode,
    onDelete,
    setState,
    isEditable,
    setReminderDeleting,
    reset,
    setIsEditable,
    isEditMode,
    onDeleteAssetItemReminder,
  ]);

  const onFormSubmit = useMemo(() => {
    return handleSubmit(async (values) => {
      if (isEditMode || values.assetItemId) {
        setCreateUpdateing(true);
        if (isViewMode) {
          await onUpdateAssetItemReminder({
            input: createUpdateAssetItemReminderInput(values),
          });
        } else {
          await onCreateAssetItemReminder({
            input: createAddAssetItemReminderInput(values, values.assetItemId),
          });
        }
      }
      onSubmit(values, isViewMode && defaultValues?.id);
      if (isEditMode || values.assetItemId) {
        setCreateUpdateing(false);
        setIsEditable(false);
      }
    });
  }, [
    handleSubmit,
    isViewMode,
    onUpdateAssetItemReminder,
    createUpdateAssetItemReminderInput,
    onCreateAssetItemReminder,
    createAddAssetItemReminderInput,
    onSubmit,
    setCreateUpdateing,
    isEditMode,
  ]);

  return (
    <div className={cx('rounded-[4px] bg-white ', ViewReminderContent)}>
      <div className="flex items-center justify-between p-[20px]">
        <span className="text-[24px] font-[600] text-grey-900">
          {isViewMode ? Assets.FormLabels.Reminders.Reminder : Assets.Actions.Reminder.AddReminder}
        </span>
        {!hideClose ? (
          <IconButton aria-label="close" className="text-red-700" onClick={handleClose}>
            <CloseIcon fontSize="medium" />
          </IconButton>
        ) : null}
      </div>
      <div className={className ?? 'px-[24px] pb-[20px]'}>
        <div className="mb-20">
          <Controller
            control={control}
            name="note"
            render={({ field, fieldState }) => (
              <FormTextArea
                {...field}
                isRequired
                disabled={disabled || (isViewMode && !isEditable)}
                error={fieldState.error}
                label={Assets.FormLabels.Note}
                rows={3}
                placeholder="Enter a notes..."
              />
            )}
            rules={ASSET_REMINDER_FORM_RULES.note}
          />
        </div>
        <div className="mb-20">
          <Controller
            control={control}
            name="startReminderAt"
            render={({ field, fieldState }) => (
              <Box testId="date-picker">
                <FormDatePickerInput
                  {...field}
                  clearable
                  isRequired
                  disabled={disabled || !editable}
                  error={fieldState.error}
                  label={Assets.FormLabels.Reminders.Date}
                  minDate={minStartDate}
                  size="medium"
                />
              </Box>
            )}
            rules={ASSET_REMINDER_FORM_RULES.date}
          />
        </div>

        <div className="mb-20">
          <FormLabel isRequired>{Assets.FormLabels.Reminders.RepeatEvery}</FormLabel>
          <Stack
            alignItems="center"
            className={'mt-6 grid grid-cols-2 gap-10'}
            justifyContent="between">
            <div className={`${errors?.cron && 'pb-[22px]'}`}>
              <Controller
                control={control}
                name="recurringInterval"
                render={({ field, fieldState }) => (
                  <Box className={'flex h-[50px] rounded-[8px] border'}>
                    <FormTextInput
                      {...field}
                      name="recurringInterval"
                      isRequired
                      className={'flex-1 '}
                      containerClassName={'mt-[0px]'}
                      error={fieldState.error}
                      inputClassName={'border-none cursor-not-allowed h-[50px] '}
                      inputControlClassName="border-0 rounded-0"
                      isDisabled={true}
                      placeholder="Number"
                      spacing={'none'}
                    />
                    <Box className="flex flex-col">
                      <Box
                        className={` flex h-[25px] items-center justify-center !rounded-tr-[8px] ${
                          editable &&
                          (intervalLimit ? reminderInterval < intervalLimit : true) &&
                          `cursor-pointer bg-grey-500`
                        }`}
                        onClick={() => {
                          if (editable && !disabled) {
                            onInterValChange('up');
                          }
                        }}>
                        <ExpandLess
                          className={'m-0 min-w-[19px] '}
                          sx={{ fill: 'var(--grey900)' }}
                        />
                      </Box>
                      <Box
                        className={`flex h-[25px] cursor-pointer items-center justify-center !rounded-br-[8px] bg-grey-500 ${
                          editable && reminderInterval > 1 && 'h-[25px] bg-[#9ea6ac]'
                        }`}
                        onClick={() => {
                          if (editable && !disabled) {
                            onInterValChange('down');
                          }
                        }}>
                        <ExpandMore
                          className={'m-0 min-w-[19px] text-white'}
                          sx={{ fill: 'var(--grey900)' }}
                        />
                      </Box>
                    </Box>
                  </Box>
                )}
              />
            </div>

            <div>
              <Controller
                control={control}
                name="cron"
                render={({ field, fieldState }) => (
                  <FormSelect
                    {...field}
                    name={'cron'}
                    isRequired
                    disabled={disabled || !editable}
                    error={fieldState.error}
                    options={getReminderRepeatOptions()}
                    selectClassName={'mt-0'}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                  />
                )}
                rules={ASSET_REMINDER_FORM_RULES.corn}
              />
            </div>
          </Stack>
        </div>

        <div className="mb-20">
          <Controller
            control={control}
            name="notifyUserIds"
            render={({ field }) => (
              <FormUsersSelect
                //   menuPosition={'fixed'}
                {...field}
                className="flex-1"
                disabled={disabled || !editable}
                label={Assets.FormLabels.Reminders.Users}
              />
            )}
          />
        </div>
        <div>
          <Controller
            control={control}
            name="isMaintenance"
            render={({ field, fieldState }) => (
              <FormCheckBoxInput
                {...field}
                classNames={'pl-0'}
                disabled={disabled || !editable}
                label={Assets.FormLabels.Reminders.Maintenance}
              />
            )}
          />
        </div>
        <Stack justifyContent="end">
          <Box testId={`${isViewMode ? Common.Actions.Update : Common.Actions.Add}-button`}>
            <Button
              disabled={disabled}
              loading={createUpdateing || loading}
              theme={isViewMode ? (isEditable ? 'success' : 'info') : 'success'}
              onClick={() => {
                if (!isViewMode || isEditable) {
                  onFormSubmit();
                }
                if ((isViewMode && !isEditable) || (isViewMode && defaultValues?.isNew)) {
                  setIsEditable(!isEditable);
                }
              }}
              classes="min-w-[94px] h-[44px]">
              {isViewMode
                ? isEditable
                  ? Common.Actions.Save
                  : Common.Actions.Edit
                : Common.Actions.Add}
            </Button>
          </Box>
          <div className="ml-[16px]">
            <Button
              disabled={disabled}
              loading={(reminderDeleting && isEditMode) || (isViewMode && deleting)}
              theme={isViewMode && !isEditable ? 'danger' : null}
              onClick={handleCancel}
              classes="min-w-[94px] h-[44px]">
              {isViewMode
                ? isEditable
                  ? Common.Actions.Cancel
                  : Common.Actions.Delete
                : Common.Actions.Cancel}
            </Button>
          </div>
        </Stack>
      </div>
    </div>
  );
};
export default AssetReminderForm;
