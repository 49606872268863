import { Controller } from 'react-hook-form';
import FormTextInput from 'app/components/ProcureForm/FormTextInput';
import Orders from 'app/i18n/Orders';
import { FeatureFlagEnum, useFeatureFlag } from 'app/libs/featureFlag';

import useInsertItemChange from '../../hook/useInsertItemChange';
import { ORDER_INSERT_TYPE, OrderFieldProps } from '../../type';
import { ORDER_REQUEST_FORM_RULES } from '../../utils/rules';

const OrderWebsiteField = (props: OrderFieldProps) => {
  const { formState, disabled, isExternalTenant } = props;
  const { control } = formState;

  const { value: isWebsiteFieldEnabled, loading } = useFeatureFlag(
    FeatureFlagEnum.WebsiteFieldEnabledInOrderRequestForm,
  );

  const { onInsertItemChange, setInputRef, isInsertItemTypeSame } = useInsertItemChange({
    insertItemType: ORDER_INSERT_TYPE.NON_STOCKED_ITEMS,
    formState,
  });

  if (loading || !isWebsiteFieldEnabled || isExternalTenant) {
    return null;
  }

  return (
    <div className="mb-10 flex">
      <div className="flex-1 flex-auto">
        <Controller
          control={control}
          name="website"
          render={({ field, fieldState }) => (
            <FormTextInput
              {...field}
              multiline
              disabled={disabled}
              error={fieldState.error}
              getInputReference={setInputRef}
              label={Orders.FormLabels.Website}
              rows={1}
              formLabel={Orders.FormLabels.Website}
              // onFocus={onInsertItemChange}
            />
          )}
          rules={ORDER_REQUEST_FORM_RULES.website(formState.actions.validateAllEmpty)}
        />
      </div>
    </div>
  );
};

export default OrderWebsiteField;
