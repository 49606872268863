import { useCallback, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { Button } from '@procurenetworks/procure-component-library';
import { SnackbarService } from 'app/components/Snackbar';
import { WarningService } from 'app/components/WarningAlert';
import { ReceivableStatusEnum } from 'app/types/schema';

import Receivables from '../../../../i18n/Receivables';
import EntityManager from '../../../components/EntityManager';
import { useDeleteReceivablesMutation } from '../../graphql/mutations/generated/deleteReceivables';
import { getReceivingHistoryOrderTypeColumn } from '../utils/columns';
import ReceivableItemsTable from './components/ReceivableItemsTable';
import useReceivingHistory from './hook/useReceivingHistory';

const Receive = () => {
  const {
    table,
    rows,
    rowCount,
    fetching,
    onPrevPage,
    onNextPage,
    onReset,
    filterState,
    setFilterState,
  } = useReceivingHistory();

  const columns = useMemo(() => getReceivingHistoryOrderTypeColumn(), []);
  const [deletingEntityId, setDeletingEntityId] = useState<string | null>(null);
  const [{ fetching: isDeleting, data: deleteResponse, error: deleteError }, deleteReceivables] =
    useDeleteReceivablesMutation();

  const renderDetailPanel = (data: { row: any }) => {
    return <ReceivableItemsTable receivableId={data.row.id} />;
  };

  // This function is used to delete a single/multiple receivable entries
  const handleBulkDelete = useCallback(
    (rowId?: string) => {
      const isBulkDeleting = !rowId;
      const selectedRowIds = table.state.selectedRowIds;

      const receivableIds = isBulkDeleting ? selectedRowIds : [rowId];

      if (!isBulkDeleting) {
        setDeletingEntityId(rowId);
      }

      WarningService.showWarning({
        message: Receivables.ConfirmationMessage.confirmDelete,
        onConfirm: () => {
          deleteReceivables({ input: { receivableIds } })
            .then((response: any) => {
              if (response.data?.deleteReceivables?.success) {
                SnackbarService.show({
                  message: Receivables.SuccessMessages.ShipmentDeleted,
                });
                if (isBulkDeleting) table.setState({ selectedRowIds: [] });
              }
            })
            .finally(() => {
              if (!isBulkDeleting) {
                setDeletingEntityId(null);
              }
            });
        },
      });
    },
    [table.state.selectedRowIds],
  );

  const isRowSelectable = useCallback((row: any) => {
    return row.status === ReceivableStatusEnum.Completed;
  }, []);

  const isBulkDeleting = table.state.selectedRowIds.length > 1 && isDeleting;

  return (
    <EntityManager className="rounded-bl-[12px] rounded-br-[12px] !p-0">
      <Box className="px-[16px] md:px-[24px] lg:px-[24px]">
        <EntityManager.Title
          hasTopDivider
          documentTitle={Receivables.History}
          title={Receivables.History}
        />
      </Box>
      <EntityManager.MultiSelectTable
        ignoreRelayPagination
        pagination
        actions={table.actions}
        columns={columns}
        data={rows}
        extraProps={{
          filterState,
          setFilterState,
          isDeleting,
          deletingEntityId,
          handleBulkDelete,
        }}
        getDetailPanelContent={renderDetailPanel}
        getDetailPanelHeight={() => 'auto'}
        isRowSelectable={isRowSelectable}
        loading={fetching}
        minWidth={0}
        paginationWrapperClass="p-[24px] flex-wrap md:gap-[24px]"
        persistKey="receive_history_table"
        setState={table.setState}
        state={table.state}
        testId="receive_history_table"
        total={rowCount}
        onNextPage={onNextPage}
        onPrevPage={onPrevPage}
        onReset={onReset}
      />
      <Box className="flex w-full justify-end py-[16px] pr-[24px]">
        <Button
          classes="min-w-[94px] h-[44px]"
          disabled={!table.state.selectedRowIds.length || isDeleting}
          loading={isBulkDeleting}
          theme="danger"
          onClick={() => handleBulkDelete()}>
          Bulk Delete
        </Button>
      </Box>
    </EntityManager>
  );
};

export default Receive;
