import { useEffect, useState } from 'react';
import { Button } from '@procurenetworks/procure-component-library';
import { SnackbarService } from 'app/components/Snackbar';
import Undelete from 'app/i18n/Undelete';
import ConfirmationModal from 'app/modules/components/ConfirmationModal';

import Common from '../../../../i18n/Common';
import Stack from '../../../../ui-components/Stack';
import EntityManager from '../../../components/EntityManager';
import { UndeleteAssetMutation } from '../../views/undeleteAsset/graphql/mutations/generated/undeleteAsset';
import { UndeleteInventoryMutation } from '../../views/undeleteInventory/graphql/mutations/generated/undeleteInventory';
import UnDeleteErrorDialog from '../UnDeleteErrorDialog';
import useUnDeleteErrorDialog from '../UnDeleteErrorDialog/hook/useUnDeleteErrorDialog';
import { UndelteteItemsTableProps } from './types';
import {
  getUnDeleteDialogState,
  getUnDeleteSuccessMessage,
  isUnDeleteHaveFailedRecords,
  isUnDeleteHaveSuccessRecords,
} from './utils';

const UndeleteItemsTable = (props: UndelteteItemsTableProps) => {
  const [isOpenUndeleteConfirm, setIsOpenUndeleteConfirm] = useState<boolean>(false);
  const {
    table,
    fetching,
    undeleting,
    data,
    columns,
    total,
    siteId,
    persistKey,
    onUndeleteItems,
    onNextPage,
    onPrevPage,
    onReset,
    filterNodes,
    paginationWrapper,
  } = props;

  const { state: unDeleteDialogState, setState: setUnDeleteDialogState } = useUnDeleteErrorDialog();

  const isSiteIdSelected = !!siteId;

  const onCancelConfirm = () => {
    setIsOpenUndeleteConfirm(false);
  };

  const onUndelete = async () => {
    let itemIds: Array<string> = [];
    itemIds = table.state.selectedRowData.map((item) => {
      return item?.id;
    });

    onUndeleteItems({
      input: { itemIds },
    })
      .then((res) => {
        const undeleteAssetItemsResponse = (res.data as UndeleteAssetMutation)?.undeleteAssetItems;
        const undeleteInventoryItemsResponse = (res.data as UndeleteInventoryMutation)
          ?.undeleteInventoryItems;

        if (
          isUnDeleteHaveFailedRecords(undeleteAssetItemsResponse || undeleteInventoryItemsResponse)
        ) {
          setUnDeleteDialogState({
            open: true,
            data: getUnDeleteDialogState(
              undeleteAssetItemsResponse || undeleteInventoryItemsResponse,
            ),
          });
        } else {
          const successMessage = getUnDeleteSuccessMessage(
            undeleteAssetItemsResponse || undeleteInventoryItemsResponse,
          );

          if (successMessage) {
            SnackbarService.show({
              message: successMessage,
            });
          }
        }

        if (
          isUnDeleteHaveSuccessRecords(
            undeleteAssetItemsResponse || undeleteInventoryItemsResponse,
          ) &&
          data &&
          onPrevPage
        ) {
          if (itemIds.length === data.length) {
            onPrevPage();
          }
        }
        table.setState({ selectedRowIds: [] });
      })
      .catch((error) => {
        console.log(error);
      });
    setIsOpenUndeleteConfirm(false);
  };

  useEffect(() => {
    const updatedSelectedRowsData = table.state.selectedRowData.filter((row) => {
      return table.state.selectedRowIds.includes(row.id);
    });

    const newSelectedIds = table.state.selectedRowIds.filter((id) => {
      return !table.state.selectedRowData.find((data) => data.id === id);
    });

    const newRows: any = data?.filter((row: any) => newSelectedIds.includes(row.id));
    table.setState({
      selectedRowData: [...updatedSelectedRowsData, ...newRows],
    });
  }, [table.state.selectedRowIds, data, table.setState]);

  return (
    <div>
      <EntityManager.MultiSelectTable
        ignoreRelayPagination
        pagination
        persistSelectionData
        actions={table.actions}
        columns={columns({
          disabledSorting: isSiteIdSelected,
          isSiteIdSelected,
          siteId,
        })}
        data={data}
        defaultSortState={{
          id: undefined,
        }}
        loading={fetching}
        persistKey={persistKey}
        setState={table.setState}
        state={table.state}
        total={total}
        onNextPage={onNextPage}
        onPrevPage={onPrevPage}
        onReset={onReset}
        filterNodes={filterNodes}
        paginationWrapperClass={paginationWrapper}
      />
      <Stack className="gap-[16px] py-[24px] px-[24px]" justifyContent="end">
        <Button
          classes="min-w-[94px] h-[44px]"
          disabled={!table.state.selectedRowIds.length || undeleting || fetching}
          loading={undeleting}
          theme="success"
          onClick={() => setIsOpenUndeleteConfirm(true)}>
          {Common.Actions.Undelete}
        </Button>
        <Button
          classes="min-w-[94px] h-[44px]"
          disabled={!table.state.selectedRowIds.length || undeleting}
          onClick={() => table.setState({ selectedRowIds: [] })}>
          {Common.Actions.Cancel}
        </Button>
      </Stack>
      <ConfirmationModal
        message={Undelete.MultipleItemUndeleteMessage(table.state.selectedRowIds.length)}
        open={isOpenUndeleteConfirm}
        type={Undelete.Undelete}
        onCancel={onCancelConfirm}
        onConfirm={onUndelete}
      />

      <UnDeleteErrorDialog setState={setUnDeleteDialogState} state={unDeleteDialogState} />
    </div>
  );
};

export default UndeleteItemsTable;
