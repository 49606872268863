import { useCallback } from 'react';
import Shop from 'app/i18n/Shop';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';

import { SnackbarService } from '../../../../components/Snackbar';
import routes from '../../../../consts/routes';
import { useDeleteAffiliateMerchantMutation } from '../../view/admin/EditAffiliateMerchant/graphql/mutations/generated/deleteAffiliateMerchant';
import { useUpdateAffiliateMerchantMutation } from '../../view/admin/EditAffiliateMerchant/graphql/mutations/generated/updateAffiliateMerchant';
import { UseEditAffiliateMerchantStateReturnType } from '../../view/admin/EditAffiliateMerchant/hook/useEditAffiliateMerchantState';
import AffiliateMerchantForm from '../AffiliateMerchantForm';
import useAffiliateMerchantForm from '../AffiliateMerchantForm/hook/useAffiliateMerchantForm';

type Props = {
  affiliateConfigurationId: string;
  disabled?: boolean;
  affiliateMerchantFormData: UseEditAffiliateMerchantStateReturnType['affiliateMerchantFormData'];
} & RouteComponentProps;

const EditAffiliateMerchantView = (props: Props) => {
  const { affiliateMerchantFormData, affiliateConfigurationId, disabled, history } = props;

  const [{ fetching: updatingAffiliateMerchant }, onUpdateAffiliateMerchant] = useUpdateAffiliateMerchantMutation();
  const [{ fetching: deletingAffiliateMerchantItems }, executeDeleteAffiliateMerchantItems] =
    useDeleteAffiliateMerchantMutation();

  const { state, createUpdateAffiliateMerchantInput } = useAffiliateMerchantForm({
    defaultValues: affiliateMerchantFormData,
    affiliateConfigurationId,
  });

  const {cancelEditable} = state

  const onSubmit = useCallback(
    (input: any) => {
      onUpdateAffiliateMerchant({
        input: createUpdateAffiliateMerchantInput(affiliateConfigurationId, input),
      }).then((response) => {
        if (response.data?.updateAffiliateConfiguration.affiliateConfiguration?.id) {
          SnackbarService.show({
            message: Shop.Admin.SuccessMessages.AffiliateUpdated,
          });
          cancelEditable()
          history.push(routes.AffiliateConfiguration());
        } else {
          console.error('[Update Affiliate Merchant] Failed', response);
        }
      });
    },
    [history, onUpdateAffiliateMerchant, affiliateConfigurationId],
  );

  const onDelete = useCallback(() => {
    executeDeleteAffiliateMerchantItems({
      input: {
        affiliateConfigurationIds: [affiliateConfigurationId],
      },
    }).then((response) => {
      if (response?.data?.deleteAffiliateConfigurations?.success) {
        SnackbarService.show({
          message: Shop.Admin.SuccessMessages.AffiliateDeleted,
        });
        history.push(routes.AffiliateConfiguration());
      } else {
        console.error('[Delete Affiliate Merchant] Failed', response);
      }
    });
  }, [affiliateConfigurationId, executeDeleteAffiliateMerchantItems, history]);

  const onCancel = useCallback(() => {
    history.push(routes.AffiliateConfiguration());
  }, [history]);

  return (
    <div>
      <AffiliateMerchantForm
        disabled={disabled || updatingAffiliateMerchant || deletingAffiliateMerchantItems}
        state={state}
        onCancel={onCancel}
        onDelete={onDelete}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default withRouter(EditAffiliateMerchantView);
