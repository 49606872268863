import { useCallback, useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { kebabCase } from 'lodash';
import Reports from 'app/i18n/Reports';
import ReportContainer from 'app/modules/reports/components/ReportContainer';
import useReportCSVExport from 'app/modules/reports/hooks/useReportCSVExport';
import { getCurrentTimeZoneValue } from 'app/utils/timezone';

import OrderRequestReportForm from './components/OrderRequestReportForm';
import { OrderRequestReportFormValues } from './components/OrderRequestReportForm/types';
import OrderRequestReportTable from './components/OrderRequestReportTable';
import { DateFilter } from '../../../../types/schema';

const queryClient = new QueryClient();

const OrderRequestReport = () => {
  const [values, setValues] = useState<OrderRequestReportFormValues | null>(null);
  const { executeExport } = useReportCSVExport();

  const onSubmit = useCallback(
    (nextValues: OrderRequestReportFormValues | null) => {
      console.log(nextValues);
      if (nextValues?.format === 'csv') {
        const {
          format,
          orderItemStatuses,
          orderRequestDateEnd,
          orderRequestDateStart,
          orderRequestDueDateStart,
          orderRequestDueDateEnd,
          ...rest
        } = nextValues;
        executeExport({
          entityType: 'orderRequest',
          filters: {
            orderRequestFilters: {
              ...rest,
              leastItemStatuses: orderItemStatuses.map(kebabCase),
              createdAt:
                orderRequestDateStart && orderRequestDateEnd
                  ? ({
                      timezone: getCurrentTimeZoneValue(),
                      absolute: {
                        startTime: orderRequestDateStart,
                        endTime: orderRequestDateEnd,
                      },
                    } as DateFilter)
                  : undefined,
              dueDate:
                orderRequestDueDateStart && orderRequestDueDateEnd
                  ? ({
                      timezone: getCurrentTimeZoneValue(),
                      absolute: {
                        startTime: orderRequestDueDateStart,
                        endTime: orderRequestDueDateEnd,
                      },
                    } as DateFilter)
                  : undefined,
            },
          },
          userTimezone: getCurrentTimeZoneValue(),
        });
      }

      setValues(nextValues);
    },
    [executeExport],
  );

  const renderPreviewTable = () => {
    if (values) {
      return <OrderRequestReportTable values={values} />;
    }

    return null;
  };

  return (
    <ReportContainer title={Reports.OrderRequestReport} stackWrapperClass='rounded-tr-[12px] rounded-br-[12px]'>
      <QueryClientProvider client={queryClient}>
        <OrderRequestReportForm onSubmit={onSubmit} />
        {values?.format === 'preview' ? renderPreviewTable() : null}
      </QueryClientProvider>
    </ReportContainer>
  );
};

export default OrderRequestReport;
