import { useCallback } from 'react';
import { cx } from '@emotion/css';
import { TitleBar } from '@procurenetworks/procure-component-library';
import { SnackbarService } from 'app/components/Snackbar';
import routes from 'app/consts/routes';
import Organization from 'app/i18n/Organization';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import { titlebarStyles } from 'app/modules/components/EntityManager/styles';
import { canSwitchTenants } from 'app/modules/organizations/utils/canSwitchTenants';
import { CreateTenantInput, TenantFeatureTypeEnum, TenantStatusEnum } from 'app/types/schema';
import Box from 'app/ui-components/Box';

import { reloadUnifiedHeaderData } from '../../../../utils/unifiedHeader';
import AddOrganizationForm from './components/AddOrganizationForm';
import { useCreateTenantMutation } from './graphql/mutations/generated/createTenant';

const DEFAULT_VALUES: CreateTenantInput = {
  name: '',
  config: { allowSignUp: false },
  locationDetails: { address: '', city: '', state: '', zipCode: '' },
  primaryPhoneNumber: '',
  secondaryPhoneNumber: '',
  status: TenantStatusEnum.Active,
  adminEmailId: '',
  firstName: '',
  lastName: '',
  moduleVisibilityConfig: {
    assets: TenantFeatureTypeEnum.Show,
    itAsset: TenantFeatureTypeEnum.Show,
    inventory: TenantFeatureTypeEnum.Show,
    contacts: TenantFeatureTypeEnum.Show,
    orderRequest: TenantFeatureTypeEnum.Show,
    shipping: TenantFeatureTypeEnum.Show,
    receiving: TenantFeatureTypeEnum.Show,
    report: TenantFeatureTypeEnum.Show,
    admin: TenantFeatureTypeEnum.Show,
    shop: TenantFeatureTypeEnum.Show,
    wishlist: TenantFeatureTypeEnum.Show,
  },
};

function AddOrganization(props: RouteComponentProps) {
  const { history } = props;
  const [{ fetching }, onCreateTenant] = useCreateTenantMutation();

  const currentUser = useCurrentUser();
  const isSuperAdmin = canSwitchTenants(currentUser.workspacePermissions);

  const onSubmit = useCallback(
    (input: CreateTenantInput) => {
      onCreateTenant({ input }).then((response) => {
        if (response.data?.createTenant.tenant?.id) {
          SnackbarService.show({ message: 'This organization has been successfully created.' });
          reloadUnifiedHeaderData();
          history.replace(routes.Organizations());
        }
      });
    },
    [history, onCreateTenant],
  );

  const onCancel = useCallback(() => {
    history.replace(routes.Organizations());
  }, [history]);

  return (
    <Box className="bg-white px-24 pt-0 pb-16">
      <Box className={cx('space-y-40', titlebarStyles)}>
        <TitleBar title={Organization.NewOrganization} />
        <Box className="max-w-[532px]">
          <AddOrganizationForm
            defaultValues={DEFAULT_VALUES}
            disabled={fetching}
            isSuperAdmin={isSuperAdmin}
            onCancel={onCancel}
            onSubmit={onSubmit}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default withRouter(AddOrganization);
