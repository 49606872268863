import { useCallback, useEffect } from 'react';
import EntityManager from 'app/modules/components/EntityManager';
import Box from 'app/ui-components';

import { SnackbarService } from '../../../../components/Snackbar';
import Common from '../../../../i18n/Common';
import { ImportJobMetadataType, JobStatusEnum } from '../../../../types/schema';
import ImportForm from '../../components/ImportForm';
import useImportFormState from '../../components/ImportForm/hook/useImportFormState';
import { BulkImportStep } from '../../components/ImportForm/type';
import ImportMappingForm from '../../components/ImportMappingForm';
import ImportValidationDialog from '../../components/InvalidImportInputDialog';
import { useCreateImportJobMutation } from '../../graphql/mutations/generated/createImportJob';
import { usePreprocessImportJobMutation } from '../../graphql/mutations/generated/preprocessImportJob';
import useImportSubscription from '../../hooks/useImportSubscription';
import { createJobHeaderMapInput, createPreprocessImportJobInput } from '../../utils/dto';

const BulkImport = () => {
  const {
    formState,
    state,
    setState,
    getImportFormValues,
    onCloseInvalidImportInputDialog,
    resetImportForm,
  } = useImportFormState();
  const { currentStep, loadingJob, importStatusDialog, jobId } = state;

  const [{ fetching: creatingImportJob }, executeCreateImportJob] = useCreateImportJobMutation();

  const [{ fetching: preprocessingImportJob }, executePreprocessImportJob] =
    usePreprocessImportJobMutation();

  const { job } = useImportSubscription({
    pause: false, // Boolean(jobId),
  });

  const onNextImportMappingForm = useCallback(
    (values: any) => {
      executePreprocessImportJob({
        input: createPreprocessImportJobInput(values),
      }).then((response) => {
        if (response?.data?.preprocessImportJob?.success) {
          setState({
            headers: response.data.preprocessImportJob.columnHeaders!,
            entityMap: response.data.preprocessImportJob.entityMap!,
            currentStep: BulkImportStep.ImportMappingForm,
          });
        }
      });
    },
    [executePreprocessImportJob, setState],
  );

  const onBackImportForm = useCallback(() => {
    setState({
      headers: [],
      entityMap: [],
      currentStep: BulkImportStep.ImportForm,
      jobId: undefined,
    });
  }, [setState]);

  const onSaveImportMappings = useCallback(
    (values: any) => {
      executeCreateImportJob({
        input: createJobHeaderMapInput(getImportFormValues(), values),
      }).then((response) => {
        setState({
          loadingJob: true,
          jobId: response?.data?.createImportJob?.job?.id,
        });
      });
    },
    [getImportFormValues, executeCreateImportJob, setState],
  );

  useEffect(() => {
    if (!job || !jobId || job.id !== jobId) {
      return;
    }

    const showImportStatusDialog = (importJobMetadata?: ImportJobMetadataType | null) => {
      setState({
        loadingJob: false,
        importStatusDialog: {
          open: true,
          importJobMetadata: importJobMetadata,
        },
      });
    };

    switch (job.status) {
      case JobStatusEnum.Completed:
        const invalidDataCount = job?.importJobMetadata?.inValidDataCount;
        if (invalidDataCount !== undefined && invalidDataCount !== null && invalidDataCount > 0) {
          showImportStatusDialog(job?.importJobMetadata);
        } else {
          SnackbarService.show({
            message: job?.message || 'Success!',
          });
          resetImportForm();
        }
        break;

      case JobStatusEnum.Failed:
        SnackbarService.showError({ message: job.error || Common.ErrorMessage });
        setState({
          loadingJob: false,
        });
        break;

      case JobStatusEnum.InputValidationFailed:
        showImportStatusDialog(job.importJobMetadata);
        break;
    }
  }, [job, jobId, resetImportForm, setState]);

  const renderStep = (step: BulkImportStep) => {
    switch (step) {
      case BulkImportStep.ImportForm:
        return (
          <ImportForm
            disabled={preprocessingImportJob}
            formState={formState}
            onNext={onNextImportMappingForm}
          />
        );
      case BulkImportStep.ImportMappingForm:
        return (
          <ImportMappingForm
            disabled={creatingImportJob || loadingJob}
            state={state}
            onBack={onBackImportForm}
            onSubmit={onSaveImportMappings}
          />
        );
    }
  };

  return (
    <EntityManager layoutWrapperClass className="pt-0">
      <Box
        className="!mt-[24px] w-full sm:max-w-[382px]  md:min-w-[382px] lg:max-w-[382px] xl:max-w-[382px]"
        data-testid="bulk-import-page">
        {renderStep(currentStep)}
      </Box>
      <ImportValidationDialog
        state={importStatusDialog}
        onClose={onCloseInvalidImportInputDialog}
      />
    </EntityManager>
  );
};

export default BulkImport;
