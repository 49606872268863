import { useCallback, useState } from 'react';
import camelCase from 'lodash/camelCase';
import { ExportReportEntityTypeEnum } from 'app/consts/enum';
import Reports from 'app/i18n/Reports';
import ReportContainer from 'app/modules/reports/components/ReportContainer';
import { SortOrderEnum, TransactionEntityTypeEnum } from 'app/types/schema';
import { getCurrentTimeZoneValue } from 'app/utils/timezone';

import { getExportTransactionFilterValues } from '../../components/TransactionTypeFormField/utils';
import useReportCSVExport from '../../hooks/useReportCSVExport';
import { getItemLocationItemTypesFromTransactionTypes } from '../../utils/itemtypes';
import AssetTransactionTable from './components/AssetTransactionTable';
import InventoryTransactionTable from './components/InventoryTransactionTable';
import TransactionReportForm from './components/TransactionReportForm';
import { TransactionReportFormValues } from './components/TransactionReportForm/types';

const TransactionReport = () => {
  const [values, setValues] = useState<TransactionReportFormValues | null>(null);
  const { executeExport } = useReportCSVExport();

  const renderPreviewTable = () => {
    if (values?.type === TransactionEntityTypeEnum.Inventory) {
      return <InventoryTransactionTable values={values} />;
    }

    if (values?.type === TransactionEntityTypeEnum.Asset) {
      return <AssetTransactionTable values={values} />;
    }

    return null;
  };

  const onSubmit = useCallback(
    (nextValues: TransactionReportFormValues | null) => {
      if (nextValues?.format === 'csv') {
        const filters = {
          transactionsFilters: {
            _and: [
              {
                _or: [
                  { destinationSiteIds: nextValues.siteIds },
                  { sourceSiteIds: nextValues.siteIds },
                ],
              },
              {
                _or: getExportTransactionFilterValues(nextValues.transactionTypes),
              },
            ],
            entityIds: nextValues.skuIds,
            createdAt: nextValues.createdAt,
            createdByIds: nextValues.userIds,
            projectIds: nextValues.projectIds,
            categoryIds: nextValues.categoryIds,
            departmentIds: nextValues.departmentIds,
            entityTypes: getItemLocationItemTypesFromTransactionTypes(nextValues.itemTypes).map(
              camelCase,
            ),
          },
        };
        const sorts = [{ sortField: 'updatedAt', sortOrder: camelCase(SortOrderEnum.Desc) }];

        if (nextValues.type === TransactionEntityTypeEnum.Inventory) {
          executeExport({
            entityType: ExportReportEntityTypeEnum.INVENTORY_TRANSACTION,
            filters,
            sorts,
            userTimezone: getCurrentTimeZoneValue(),
          });
        } else if (nextValues.type === TransactionEntityTypeEnum.Asset) {
          executeExport({
            entityType: ExportReportEntityTypeEnum.ASSET_TRANSACTION,
            filters,
            sorts,
            userTimezone: getCurrentTimeZoneValue(),
          });
        }
      }

      setValues(nextValues);
    },
    [executeExport],
  );

  return (
    <ReportContainer title={Reports.TransactionReport} stackWrapperClass='rounded-tr-[12px] rounded-br-[12px]'>
      <TransactionReportForm onSubmit={onSubmit} />
      {values?.format === 'preview' ? renderPreviewTable() : null}
    </ReportContainer>
  );
};

export default TransactionReport;
