import { useCallback, useEffect, useMemo } from 'react';
import { SnackbarService } from 'app/components/Snackbar';
import routes from 'app/consts/routes';
import usePagination from 'app/hooks/usePagination';
import Category from 'app/i18n/Category';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import EntityManager from 'app/modules/components/EntityManager';
import useMultiSelectEntityManager from 'app/modules/components/EntityManager/useMultiSelectEntityManager';
import { AllowedPermissionsSubjectEnum } from 'app/types/schema';
import Stack from 'app/ui-components/Stack';

import useSearchSuggestion from '../../hook/useSearchSuggestion';
import { useDeleteCategoriesMutation } from './graphql/mutations/generated/deleteCategories';
import { useCategoriesTableQuery } from './graphql/queries/generated/categoriesTable';
import { getCategoriesTableColumns } from './utils/columns';
import { showReasonsError } from '../../../../utils/reasons';
import { Box, InputAdornment } from '@mui/material';
import { CustomIcons } from '@procurenetworks/procure-component-library';

const columns = getCategoriesTableColumns();

function Categories(props: RouteComponentProps) {
  const { history } = props;

  const [{ fetching: disabled }, executeDeleteCategories] = useDeleteCategoriesMutation();

  const onDelete = useCallback(
    (ids: string[]) => {
      executeDeleteCategories({ input: { categoryIds: ids } }).then((response) => {
        const { success, reasons } = response.data?.deleteCategories || {};
        if (success) {
          SnackbarService.show({ message: 'This category has been successfully deleted.' });
        } else {
          showReasonsError(reasons);
        }
      });
    },
    [executeDeleteCategories],
  );

  const onEdit = useCallback(
    (id: string) => {
      history.push(routes.EditCategory(id));
    },
    [history],
  );

  const { search, table } = useMultiSelectEntityManager({ onEdit, onDelete });

  const { fetching, data, onNextPage, onPrevPage, onReset } = usePagination(
    useCategoriesTableQuery,
    {
      filters: { search: search.debouncedSearchText },
      sorts: table.state.sorts,
    },
    { edgeKey: 'categories', pageSize: table.state.numberOfRowsPerPage },
  );

  const rows = useMemo(() => {
    return data?.categories.edges?.map(({ node }) => node) || [];
  }, [data?.categories.edges]);

  useEffect(() => {
    const updatedSelectedRowsData = table.state.selectedRowData.filter((row) => {
      return table.state.selectedRowIds.includes(row.id);
    });

    const newSelectedIds = table.state.selectedRowIds.filter((id) => {
      return !table.state.selectedRowData.find((data) => data.id === id);
    });

    const newRows = rows.filter((row: any) => newSelectedIds.includes(row.id));
    table.setState({
      selectedRowData: [...updatedSelectedRowsData, ...newRows],
    });
  }, [table.state.selectedRowIds, rows, table.setState]);

  const { searchSuggestions, setSearchValue } = useSearchSuggestion();

  return (
    <EntityManager
      subject={AllowedPermissionsSubjectEnum.Category}
      layoutWrapperClass
      className="pt-0">
      <EntityManager.Title documentTitle={Category.Categories} title={Category.Categories} />
      <EntityManager.Add label={Category.NewCategory} to={routes.AddCategory()} />
      <Box
        sx={{
          boxShadow:
            '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
        }}
        className="mt-[24px] rounded-[12px] border border-grey-300 pb-[24px]">
        <EntityManager.MultiSelectTable
          ignoreRelayPagination
          persistSelectionData
          actions={table.actions}
          columns={columns}
          data={rows}
          loading={fetching || disabled}
          pagination={true}
          persistKey="categories_table"
          setState={table.setState}
          state={table.state}
          total={data?.categories.totalCount}
          onNextPage={onNextPage}
          onPrevPage={onPrevPage}
          onReset={onReset}
          filterNodes={
            <Box className="flex !w-[100%] gap-[16px] md:flex-wrap md:gap-[24px]">
              <EntityManager.Search
                isDropdown
                removedSearchQoutedValue
                suggestionData={searchSuggestions || []}
                onChangeSearch={setSearchValue}
                {...search}
                autoFocus
                placeholder="Search"
                startAdornment={
                  <InputAdornment position="start" className="mt-[4px] ml-[5px]">
                    <CustomIcons.SearchIcon />
                  </InputAdornment>
                }
              />
            </Box>
          }
          paginationWrapperClass="p-[24px] flex-wrap md:gap-[24px]"
        />
      </Box>
    </EntityManager>
  );
}

export default withRouter(Categories);
