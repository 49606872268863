import { useCallback } from 'react';
import { SnackbarService } from 'app/components/Snackbar';
import { API_URL } from 'app/consts/config';
import useRest from 'app/hooks/useRest';
import currentUser from 'app/modules/auth/utils/currentUser';

const useReportCSVExport = () => {
  const [, , , execute] = useRest(`${API_URL}/export/report`, undefined, {
    auth: true,
  });

  const executeExport = useCallback(
    async (data: any) => {
      const response = await execute({
        method: 'POST',
        headers: {
          Authorization: `Bearer ${currentUser.at()}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response?.success) {
        SnackbarService.showError({
          message: response?.message || 'Some error occurred. Please try again later.',
        });
      }
    },
    [execute],
  );

  return { executeExport };
};

export default useReportCSVExport;
