const Profile = {
  Organizations: 'Profile',
  ProfileTitle: 'Your Profile',
  FormLabels: {
    FirstName: 'First Name',
    LastName: 'Last Name',
    UserRole: 'User Role(s)',
    DefaultSite: 'Default Site',
    CurrentPassword: 'Current Password',
    NewPassword: 'New Password',
    ConfirmNewPassword: 'Confirm New Password',
    Email: 'Email',
  },
  FormPlaceholders: {
    DefaultSite: 'Site',
  },
  FormValidations: {
    FirstNameInvalid: 'Please enter a First Name up to 30 characters.',
    LastNameInvalid: 'Please enter a Last Name up to 30 characters.',
    CurrentPassword: 'Please enter the Current Password.',
    NewPassword: 'Please enter a New Password.',
    UniquePassword: 'New password must be different from old password',
    InvalidPassword: 'Password should be more then or equal to 6 characters',
    ConfirmNewPassword: 'Please enter the Confirm New Password.',
    ConfirmNewPasswordNotMatched: 'Please re-enter the password, as this password must match.',
    EmailFieldEmpty: 'Please enter an Email Address.',
    EmailInvalid: 'Please enter a valid Email Address.',
    spaceNotAllowed: 'Please enter up to 30 valid characters.',
  },
  SuccessMessages: {
    EmailUpdated:
      'Email updated successfully. Please check your inbox to verify your updated email.',
    PasswordUpdated: 'Password updated successfully',
    ProfileUpdated: 'Profile updated successfully',
  },
};

export default Profile;
