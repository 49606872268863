import { SnackbarService } from 'app/components/Snackbar';
import Warehouse from 'app/i18n/Warehouse';
import { WarehouseTypeEnum } from 'app/types/schema';

import { parseWarehouseLocationName, parseWarehouseLocationNameRackAndShelf } from '../../../WarehouseDetails/utils';
import useWarehouseSetup from '../../hooks/useWarehouseSetup';
import WarehouseSetupDialog from '../WarehouseSetupDialog';

interface Props {
  siteId: string;
  warehouseId?: string;
  selectedItems: string[];
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>;
  onClose: () => void;
  warehouseType?: string;
}

const WarehouseSetup = (props: Props) => {
  const { selectedItems, setSelectedItems, siteId, warehouseId, onClose, warehouseType } = props;
  const {
    loading,
    onAddBinsInWarehouse,
    onAddShelvesInWarehouse,
    onAddShelvesInWarehouseRackandShelf,
    onAddRacksInWarehouse,
    onAddRacksInWarehouseRackandShelf,
    onAddAislesInWarehouse,
    onAddZonesInWarehouse,
    onCreateWarehouse,
    printWarehouseLocations,
  } = useWarehouseSetup();

  if (warehouseType === WarehouseTypeEnum.RackAndShelf) {
    if (selectedItems.length === 1 && warehouseId) {
      const { rack } = parseWarehouseLocationNameRackAndShelf(selectedItems[0]);
      const rackNumber = Number(rack);

      return (
        <WarehouseSetupDialog
          keysToShow={['shelves']}
          loading={loading}
          onClose={onClose}
          onSubmit={({ shelves }) => {
            onAddShelvesInWarehouseRackandShelf(
              { warehouseId, rackNumber, shelves }
            ).then((res) => {
              if (!res?.locations) {
                return;
              }
              SnackbarService.show({
                message: Warehouse.WarehouseSetupSuccess,
              });
              res?.locations?.forEach((updatedLocation) => {
                const updatedInfo = parseWarehouseLocationNameRackAndShelf(updatedLocation?.name);
                const updatedRackNumber = Number(updatedInfo.rack);

                if (updatedRackNumber === rackNumber) {
                  setSelectedItems((prevSelectedItems) => {
                    const nextSelectedItems = [...prevSelectedItems];
                    nextSelectedItems[0] = updatedLocation.name;
                    return nextSelectedItems;
                  });
                }
              });
              if (res?.locations) {
                printWarehouseLocations(res?.locations);
              }
              onClose();
            });
          }}
        />
      );
    }

    if (warehouseId) {
      return (
        <WarehouseSetupDialog
          keysToShow={['racks', 'shelves']}
          loading={loading}
          onClose={onClose}
          onSubmit={({ racks, shelves }) => {
            onAddRacksInWarehouseRackandShelf(
              { warehouseId, racks, shelves }
            ).then((res) => {
              if (!res?.locations) {
                return;
              }
              setSelectedItems([res.locations[0].name]);
              SnackbarService.show({
                message: Warehouse.WarehouseSetupSuccess,
              });
              setSelectedItems([]);
              if (res?.locations) {
                printWarehouseLocations(res?.locations);
              }
              onClose();
            });
          }}
        />
      );
    }
  }

  if (selectedItems.length === 4 && warehouseId) {
    const {
      zone: zoneName,
      aisle,
      rack,
      shelf: shelfName,
    } = parseWarehouseLocationName(selectedItems[3]);
    const aisleNumber = Number(aisle);
    const rackNumber = Number(rack);

    return (
      <WarehouseSetupDialog
        keysToShow={['bins']}
        loading={loading}
        onClose={onClose}
        onSubmit={({ bins }) => {
          onAddBinsInWarehouse(
            { warehouseId, zoneName, aisleNumber, rackNumber, shelfName, bins },
            { zoneName, aisleNumber, rackNumber, maxChildIdentifier: shelfName },
          ).then((res) => {
            if (!res?.success) {
              return;
            }
            SnackbarService.show({
              message: Warehouse.WarehouseSetupSuccess,
            });

            res?.warehouse?.lastLocationNamesOfChild?.forEach((updatedLocation) => {
              const updatedInfo = parseWarehouseLocationName(updatedLocation);
              const updatedAisleNumber = Number(updatedInfo.aisle);
              const updatedRackNumber = Number(updatedInfo.rack);

              if (
                updatedInfo.zone === zoneName &&
                updatedAisleNumber === aisleNumber &&
                updatedRackNumber === rackNumber &&
                updatedInfo.shelf === shelfName
              ) {
                setSelectedItems((prevSelectedItems) => {
                  const nextSelectedItems = [...prevSelectedItems];
                  nextSelectedItems[3] = updatedLocation;

                  return nextSelectedItems;
                });
              }
            });
            if (res?.locations) {
              printWarehouseLocations(res?.locations);
            }
            onClose();
          });
        }}
      />
    );
  }

  if (selectedItems.length === 3 && warehouseId) {
    const { zone: zoneName, aisle, rack } = parseWarehouseLocationName(selectedItems[2]);
    const aisleNumber = Number(aisle);
    const rackNumber = Number(rack);

    return (
      <WarehouseSetupDialog
        keysToShow={['shelves', 'bins']}
        loading={loading}
        onClose={onClose}
        onSubmit={({ shelves, bins }) => {
          onAddShelvesInWarehouse(
            { warehouseId, zoneName, aisleNumber, rackNumber, shelves, bins },
            { zoneName, aisleNumber, maxChildIdentifier: rack },
          ).then((res) => {
            if (!res?.success) {
              return;
            }
            SnackbarService.show({
              message: Warehouse.WarehouseSetupSuccess,
            });
            res?.warehouse?.lastLocationNamesOfChild?.forEach((updatedLocation) => {
              const updatedInfo = parseWarehouseLocationName(updatedLocation);
              const updatedAisleNumber = Number(updatedInfo.aisle);
              const updatedRackNumber = Number(updatedInfo.rack);

              if (
                updatedInfo.zone === zoneName &&
                updatedAisleNumber === aisleNumber &&
                updatedRackNumber === rackNumber
              ) {
                setSelectedItems((prevSelectedItems) => {
                  const nextSelectedItems = [...prevSelectedItems];
                  nextSelectedItems[2] = updatedLocation;

                  return nextSelectedItems;
                });
              }
            });
            if (res?.locations) {
              printWarehouseLocations(res?.locations);
            }
            onClose();
          });
        }}
      />
    );
  }

  if (selectedItems.length === 2 && warehouseId) {
    const { zone: zoneName, aisle } = parseWarehouseLocationName(selectedItems[1]);
    const aisleNumber = Number(aisle);

    return (
      <WarehouseSetupDialog
        keysToShow={['racks', 'shelves', 'bins']}
        loading={loading}
        onClose={onClose}
        onSubmit={({ racks, shelves, bins }) => {
          onAddRacksInWarehouse(
            { warehouseId, zoneName, aisleNumber, racks, shelves, bins },
            { zoneName, maxChildIdentifier: aisle },
          ).then((res) => {
            if (!res?.success) {
              return;
            }
            SnackbarService.show({
              message: Warehouse.WarehouseSetupSuccess,
            });
            res?.warehouse?.lastLocationNamesOfChild?.forEach((updatedLocation) => {
              const updatedInfo = parseWarehouseLocationName(updatedLocation);
              const updatedAisleNumber = Number(updatedInfo.aisle);

              if (updatedInfo.zone === zoneName && updatedAisleNumber === aisleNumber) {
                setSelectedItems((prevSelectedItems) => {
                  const nextSelectedItems = [...prevSelectedItems];
                  nextSelectedItems[1] = updatedLocation;

                  return nextSelectedItems;
                });
              }
            });
            if (res?.locations) {
              printWarehouseLocations(res?.locations);
            }
            onClose();
          });
        }}
      />
    );
  }

  if (selectedItems.length === 1 && warehouseId) {
    const { zone: zoneName } = parseWarehouseLocationName(selectedItems[0]);

    return (
      <WarehouseSetupDialog
        keysToShow={['aisles', 'racks', 'shelves', 'bins']}
        loading={loading}
        onClose={onClose}
        onSubmit={({ aisles, racks, shelves, bins }) => {
          onAddAislesInWarehouse({ warehouseId, zoneName, aisles, racks, shelves, bins }, {}).then(
            (res) => {
              if (!res?.success) {
                return;
              }

              SnackbarService.show({
                message: Warehouse.WarehouseSetupSuccess,
              });

              res?.warehouse?.lastLocationNamesOfChild?.forEach((updatedLocation) => {
                const updatedInfo = parseWarehouseLocationName(updatedLocation);

                if (updatedInfo.zone === zoneName) {
                  setSelectedItems((prevSelectedItems) => {
                    const nextSelectedItems = [...prevSelectedItems];
                    nextSelectedItems[0] = updatedLocation;

                    return nextSelectedItems;
                  });
                }
              });
              if (res?.locations) {
                printWarehouseLocations(res?.locations);
              }
              onClose();
            },
          );
        }}
      />
    );
  }

  if (warehouseId) {
    return (
      <WarehouseSetupDialog
        loading={loading}
        onClose={onClose}
        onSubmit={(values) => {
          onAddZonesInWarehouse({ warehouseId, ...values }, {}).then((res) => {
            if (!res?.success) {
              return;
            }
            SnackbarService.show({
              message: Warehouse.WarehouseSetupSuccessWithPdf,
            });
            if (res?.locations) {
              printWarehouseLocations(res?.locations);
            }
            onClose();
          });
        }}
      />
    );
  }

  return (
    <WarehouseSetupDialog
      loading={loading}
      onClose={onClose}
      isCreateWarehouse={true}
      onSubmit={(values) => {
        const { warehouseType, ...otherValues } = values;
        onCreateWarehouse({ siteId, warehouseType, ...otherValues }).then(
          (response) => {
            if (!response?.data?.createWarehouse?.success) {
              return;
            }

            SnackbarService.show({
              message: Warehouse.WarehouseSetupSuccessWithPdf,
            });
            onClose();
          },
        );
      }}
    />
  );
};

export default WarehouseSetup;
