import { useCallback, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { FormItemInStock } from 'app/modules/assetsInventory/components/FormItemInStock';

import { textValidator } from '../../../../../../../../components/Form/utils/validators';
import Inventory from '../../../../../../../../i18n/Inventory';
import { ItemInStock } from '../../../../type';
import {
  FIELD_ARRAY_KEY,
  UseBulkOrderRequestReturnType,
} from '../../../OrderItemRequest/hook/useBulkOrderRequest';
import { OrderRequestInput } from '../../../OrderRequestForm/type';
import OrderItemInStockInput from '../../../OrderRequestForm/components/OrderItemInStockField/OrderItemInStockInput';
import { format } from '../../../../../../../../utils/cost';
import { Grid } from '@mui/material';
import Orders from '../../../../../../../../i18n/Orders';
import OrderFormItemInStock from '../../../../../../../assetsInventory/components/FormItemInStock/OrderFormItemInStock';

interface Props {
  state: UseBulkOrderRequestReturnType['bulkOrderState'];
  index: number;
  disabledValidation: boolean;
  onUpdateOrderItem: (index: number, nextState: Partial<OrderRequestInput>) => void;
  disabled?: boolean;
}

const BulkOrderItemInStockField = (props: Props) => {
  const { index, onUpdateOrderItem, disabledValidation, state, disabled } = props;
  const { control, trigger, partnerTenantId: partnerTenantSelectId, isExternalTenant } = state;

  const onItemInStockChange = useCallback(
    (itemInStock?: ItemInStock) => {
      onUpdateOrderItem(index, {
        cost: format(itemInStock?.cost || '0.00'),
        itemInStock: itemInStock,
      });
    },
    [onUpdateOrderItem, index, trigger],
  );

  const partnerTenantId = useMemo(
    () => (isExternalTenant ? partnerTenantSelectId : undefined),
    [partnerTenantSelectId, isExternalTenant],
  );

  return (
    <Grid item md xs={12}>
      <Controller
        control={control}
        name={`${FIELD_ARRAY_KEY}.${index}.itemId`}
        render={({ field, fieldState }) => (
          <OrderFormItemInStock
            {...field}
            isRequired
            pickableThroughOrderRequest
            disabled={disabled}
            partnerTenantId={partnerTenantId}
            error={fieldState.error}
            placeholder="Search Item"
            onChange={(value, option) => {
              field.onChange(value);
              onItemInStockChange(option);
            }}
            className="!mt-[2px]"
            renderInput={(params) => (
              <OrderItemInStockInput
                autoFocus={index === 0}
                inputParams={params}
                className="bulk-order-item-in-stock-input"
                label={Orders.FormLabels.ItemsInStock}
                placeholder={Orders.FormPlaceholders.ItemsInStock}
                formLabel="Item In Stock"
              />
            )}
          />
        )}
        rules={
          disabledValidation
            ? undefined
            : {
                validate: textValidator(Inventory.FormValidationMessages.ItemInStockSearchRequired),
              }
        }
      />
    </Grid>
  );
};
export default BulkOrderItemInStockField;
