import useMultiSelectEntityManager from 'app/modules/components/EntityManager/useMultiSelectEntityManager';

import {
  FormState,
  LabelCustomEnum,
  LabelFormat,
  LabelStockEnum,
  LabelTypeEnum,
} from '../../components/Forms/types';
import { sortLabelData } from './utils/utils';

interface Props {
  values: FormState | undefined;
  setIsCustomFieldValid?: (isValid: boolean) => void;
}

const useLabelsTable = ({ values, setIsCustomFieldValid }: Props) => {
  const { search, table } = useMultiSelectEntityManager({});

  return {
    search: search,
    table: table,
    onPrintLabels: () => {
      const sortedData = sortLabelData({
        labelFormat: values?.labelFormat as LabelFormat | undefined,
        labelStock: values?.labelStock as LabelStockEnum | undefined,
        type: values?.type as LabelTypeEnum | undefined,
        data: table.state.selectedRowData,
      });
      if (
        values?.custom === LabelCustomEnum.Custom &&
        values?.firstField === '' &&
        values?.secondField === '' &&
        values?.thirdField === ''
      ) {
        setIsCustomFieldValid && setIsCustomFieldValid(true);
      } else if (
        values?.custom === LabelCustomEnum.Custom &&
        [
          LabelStockEnum.Avery6570,
          LabelStockEnum.Avery5160,
          LabelStockEnum.OneRoundLabels,
          LabelStockEnum.ThreeRoundLabels,
          LabelStockEnum.OneSquareLabels,
          LabelStockEnum.ThreeSquareLabels,
          LabelStockEnum.ZebraPrinter,
        ].includes(values.labelStock as LabelStockEnum) &&
        values?.firstField === ''
      ) {
        setIsCustomFieldValid && setIsCustomFieldValid(true);
      } else {
        setIsCustomFieldValid && setIsCustomFieldValid(false);
        localStorage.setItem(
          'printLabels',
          JSON.stringify({
            ...values,
            ids: table.state.selectedRowIds,
            data: sortedData,
          }),
        );
        window.open('/admin/print-label', '_blank');
      }
    },
  };
};

export type UseLabelsTableReturnType = ReturnType<typeof useLabelsTable>;
export default useLabelsTable;
