import { useMemo } from 'react';
import { FieldError } from 'react-hook-form';
import AsyncMultiSelect from 'app/components/AsyncMultiSelect';
import {
  AsyncMultiSelectProps,
  UseSelectedValueProps,
  UseSelectedValueReturn as UseSelectedValuesReturn,
  UseSelectQueryProps,
  UseSelectQueryReturn,
} from 'app/components/AsyncMultiSelect/types';
import { adaptNodeEdgeToOption } from 'app/components/AsyncMultiSelect/utils';
import { AsyncMultiTableFilterProps } from 'app/components/AsyncMultiTableFilter/types';
import { AsyncSingleSelectProps } from 'app/components/AsyncSingleSelect/types';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import {
  Maybe,
  RoleTypeFilterEnum,
  SortOrderEnum,
  TransactionFilters,
  UserStatusEnum,
  ValueInEntitiesFiltersConfig,
  ValuesInEntitiesDistinctByKeysEnum,
  ValuesInEntitiesTableNameEnum,
} from 'app/types/schema';

import { useUsersSelectQuery } from '../../graphql/queries/generated/usersSelect';

interface UsersQueryVariables {
  roleTypes?: RoleTypeFilterEnum[];
  statuses?: UserStatusEnum[];
  tenantIds?: string[];
  includingPartnerTenantExternalUsers?: boolean;
  excludeCurrentUser?: boolean;
  distinctTableName?: ValuesInEntitiesTableNameEnum;
  distinctKeys?: ValuesInEntitiesDistinctByKeysEnum[];
  filters?: TransactionFilters;
  filterKey?: keyof ValueInEntitiesFiltersConfig;
  filterType?: string;
}

export interface UsersSelectProps {
  name: string;
  value?: Maybe<string[]> | Maybe<string>;
  onBlur?: () => void;
  onChange: AsyncMultiTableFilterProps['onChange'] | AsyncSingleSelectProps['onChange'];
  placeholder?: string;
  disabled?: boolean;
  error?: FieldError;
  queryVariables?: UsersQueryVariables;
  isMultiple?: boolean;
  isDistinct?: boolean;
  filterType?: string;
}

export interface UserSelectProps {
  name: string;
  value?: Maybe<string>;
  onBlur?: () => void;
  onChange: (values: string[]) => void;
  placeholder?: string;
  disabled?: boolean;
  error?: FieldError;
  queryVariables?: UsersQueryVariables;
}

const useSelectedValues = (props: UseSelectedValueProps): UseSelectedValuesReturn => {
  const { value, pause, tenantIds } = props;

  const [{ fetching, data }] = useUsersSelectQuery({
    pause,
    variables: { filters: { userIds: value, tenantIds } },
    requestPolicy: 'network-only',
  });
  const values = useMemo(
    () => data?.users.edges?.map(adaptNodeEdgeToOption) || [],
    [data?.users.edges],
  );

  return { isDisabled: fetching, selectedValues: values };
};

const useSelectQuery = (props: UseSelectQueryProps & UsersQueryVariables): UseSelectQueryReturn => {
  const { inputValue, roleTypes, tenantIds, statuses, excludeCurrentUser } = props;

  const { id } = useCurrentUser();

  const [{ fetching, data }] = useUsersSelectQuery({
    variables: {
      filters: {
        search: inputValue,
        roleTypes,
        tenantIds,
        statuses: statuses ?? [UserStatusEnum.Active],
      },
      limit: 10000,
      sorts: [
        { sortField: 'firstName', sortOrder: SortOrderEnum.Asc },
        { sortField: 'lastName', sortOrder: SortOrderEnum.Asc },
      ],
    },
    requestPolicy: 'network-only',
  });

  const options = useMemo(() => {
    const userOptions = data?.users.edges?.map(adaptNodeEdgeToOption) || [];

    return excludeCurrentUser ? userOptions.filter((option) => option.value !== id) : userOptions;
  }, [data?.users.edges, excludeCurrentUser, id]);

  return { isLoading: fetching, options };
};

const UsersSelect = (props: UsersSelectProps) => {
  const { value, onChange, ...otherProps } = props;

  return (
    <AsyncMultiSelect
      {...otherProps}
      useSelectQuery={useSelectQuery}
      useSelectedValue={useSelectedValues}
      value={value as AsyncMultiSelectProps['value']}
      onChange={onChange as AsyncMultiSelectProps['onChange']}
    />
  );
};

export default UsersSelect;
