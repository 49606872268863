import { useCallback, useMemo, useState } from 'react';
import { cx } from '@emotion/css';
import { TitleBar } from '@procurenetworks/procure-component-library';
import { SnackbarService } from 'app/components/Snackbar';
import routes from 'app/consts/routes';
import Organization from 'app/i18n/Organization';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import { titlebarStyles } from 'app/modules/components/EntityManager/styles';
import { canSwitchTenants } from 'app/modules/organizations/utils/canSwitchTenants';
import { UpdateTenantInput } from 'app/types/schema';
import Box from 'app/ui-components/Box';

import { reloadUnifiedHeaderData } from '../../../../utils/unifiedHeader';
import Layout from '../../layout';
import { OrganizationTabs } from '../../utils/types';
import EditOrganizationForm from './components/EditOrganizationForm';
import { useUpdateTenantMutation } from './graphql/mutations/generated/updateTenant';
import { useEditOrganizationQuery } from './graphql/queries/generated/editOrganization';

type Props = RouteComponentProps & { tenantId: string };

function EditOrganization(props: Props) {
  const { tenantId, history } = props;
  const currentUser = useCurrentUser();
  const isSuperAdmin = canSwitchTenants(currentUser.workspacePermissions);
  const [activePage, setActivePage] = useState<OrganizationTabs>(OrganizationTabs.EDIT);

  const [{ data }] = useEditOrganizationQuery({
    variables: { filters: { tenantIds: [tenantId] } },
    requestPolicy: 'network-only',
  });
  const [{ fetching: disabled }, onUpdateTenant] = useUpdateTenantMutation();

  const tenant = useMemo(() => {
    const tenantNode = data?.tenants.edges?.[0]?.node;

    if (tenantNode) {
      const { id, ...rest } = tenantNode;
      return rest;
    }

    return null;
  }, [data?.tenants.edges]);

  const onSubmit = useCallback(
    (input: Omit<UpdateTenantInput, 'tenantId'>) => {
      onUpdateTenant({ input: { ...input, tenantId } }).then((response) => {
        if (response.data?.updateTenant.tenant?.id) {
          SnackbarService.show({ message: 'This organization has been successfully updated.' });
          reloadUnifiedHeaderData();
          history.replace(routes.Organizations());
        }
      });
    },
    [history, onUpdateTenant, tenantId],
  );

  const onCancel = useCallback(() => {
    history.replace(routes.Organizations());
  }, [history]);

  if (!tenant) {
    return null;
  }

  const getOrganzationLabelName = (value: OrganizationTabs) => {
    return value === OrganizationTabs.EDIT
      ? Organization.EditOrganization
      : Organization.OrganizationSetting;
  };

  return (
    <Layout activePage={activePage} setActivePage={setActivePage}>
      <Box className="bg-white px-24 pt-0 pb-16">
        <Box className={cx('max-w-[768px] space-y-40', titlebarStyles)}>
          <TitleBar hasTopDivider title={getOrganzationLabelName(activePage)} />
          <EditOrganizationForm
            activePage={activePage}
            defaultValues={tenant}
            disabled={disabled}
            isSuperAdmin={isSuperAdmin}
            onCancel={onCancel}
            onSubmit={onSubmit}
          />
        </Box>
      </Box>
    </Layout>
  );
}

export default withRouter(EditOrganization);
