import { useCallback, useState } from 'react';
import camelCase from 'lodash/camelCase';
import { ExportReportEntityTypeEnum } from 'app/consts/enum';
import Reports from 'app/i18n/Reports';
import ReportContainer from 'app/modules/reports/components/ReportContainer';
import { TransactionEntityTypeEnum } from 'app/types/schema';
import { getCurrentTimeZoneValue } from 'app/utils/timezone';

import { getExportTransactionFilterValues } from '../../components/TransactionTypeFormField/utils';
import useReportCSVExport from '../../hooks/useReportCSVExport';
import { getItemLocationItemTypesFromTransactionTypes } from '../../utils/itemtypes';
import CustomReportForm from './components/CustomReportForm';
import { CustomReportFormValues } from './components/CustomReportForm/types';
import CustomReportTable from './components/CustomReportTable';
import {
  parseAssetColumnsHeaderWithMapping,
  parseInventoryColumnsHeaderWithMapping,
} from './utils/columHeaders';

const CustomReport = () => {
  const [values, setValues] = useState<CustomReportFormValues | null>(null);
  const { executeExport } = useReportCSVExport();

  const renderPreviewTable = () => {
    if (values) {
      return <CustomReportTable values={values} />;
    }

    return null;
  };

  const onSubmit = useCallback(
    (nextValues: CustomReportFormValues | null) => {
      if (nextValues?.format === 'csv') {
        const filters = {
          transactionsFilters: {
            _and: [{ _or: getExportTransactionFilterValues(nextValues.transactionTypes) }],
            entityTypes: getItemLocationItemTypesFromTransactionTypes(nextValues.itemTypes).map(
              camelCase,
            ),
            createdAt: nextValues.createdAt,
            categoryIds: nextValues.categoryIds,
            projectIds: nextValues.projectIds,
            entityIds: nextValues.skuIds,
            _or: [
              { billToSiteIds: nextValues.siteIds },
              { sourceSiteIds: nextValues.siteIds },
              { destinationSiteIds: nextValues.siteIds },
            ],
          },
        };
        const sorts = [{ sortField: 'createdAt', sortOrder: camelCase(nextValues.sortOrder) }];
        const columnHeaders = nextValues.columns;

        if (nextValues.type === TransactionEntityTypeEnum.Inventory) {
          executeExport({
            entityType: ExportReportEntityTypeEnum.INVENTORY_CUSTOM,
            filters,
            columnHeaders: parseInventoryColumnsHeaderWithMapping(columnHeaders),
            sorts,
            userTimezone: getCurrentTimeZoneValue(),
          });
        } else if (nextValues.type === TransactionEntityTypeEnum.Asset) {
          executeExport({
            entityType: ExportReportEntityTypeEnum.ASSET_CUSTOM,
            filters,
            columnHeaders: parseAssetColumnsHeaderWithMapping(columnHeaders),
            sorts,
            userTimezone: getCurrentTimeZoneValue(),
          });
        }
      }

      setValues(nextValues);
    },
    [executeExport],
  );

  return (
    <ReportContainer title={Reports.CustomReport} stackWrapperClass='rounded-tr-[12px] rounded-br-[12px]'>
      <CustomReportForm onSubmit={onSubmit} />
      {values?.format === 'preview' ? renderPreviewTable() : null}
    </ReportContainer>
  );
};

export default CustomReport;
