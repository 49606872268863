import { useCallback } from 'react';
import { Content } from '@procurenetworks/procure-component-library';
import { SnackbarService } from 'app/components/Snackbar';
import routes from 'app/consts/routes';
import Shop from 'app/i18n/Shop';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import EntityManager from 'app/modules/components/EntityManager';
import AffiliateMerchantForm from 'app/modules/shop/components/AffiliateMerchantForm';
import useAffiliateMerchantForm from 'app/modules/shop/components/AffiliateMerchantForm/hook/useAffiliateMerchantForm';
import { CreateAffiliateMerchantFormInput } from 'app/modules/shop/components/AffiliateMerchantForm/types';
import { AffiliateConfigurationStatusEnum } from 'app/types/schema';

import { useCreateAffiliateMerchantMutation } from './graphql/mutations/generated/createAffiliateMerchant';

const DEFAULT_VALUES: CreateAffiliateMerchantFormInput = {
  name: '',
  logoUrl: '',
  domain: '',
  affiliateUrl: '',
  commission: '0.00',
  status: AffiliateConfigurationStatusEnum.InActive,
};

const AddAffiliateMerchant = (props: RouteComponentProps) => {
  const { history } = props;

  const [{ fetching }, onCreateAffiliateMerchant] = useCreateAffiliateMerchantMutation();

  const { state, createAffiliateMerchantInput } =
    useAffiliateMerchantForm({
      defaultValues: { ...DEFAULT_VALUES },
    });

  const onSubmit = useCallback(
    async (input: any) => {
      // Creating Affiliate Merchant Item
      const response = await onCreateAffiliateMerchant({
        input: {
          ...createAffiliateMerchantInput(input),
        },
      });
      const entityId =
        response?.data?.createAffiliateConfiguration?.affiliateConfiguration?.id || undefined;
      if (response?.data?.createAffiliateConfiguration?.success && entityId) {
        SnackbarService.show({
          message: Shop.Admin.SuccessMessages.AffiliateCreated,
        });

        history.replace(routes.AffiliateConfiguration());
      } else {
        console.error('[Create Affiliate] Failed', response);
      }
    },
    [history, onCreateAffiliateMerchant],
  );

  const onCancel = useCallback(() => {
    history.push(routes.AffiliateConfiguration());
  }, [history]);

  return (
    <EntityManager>
      <EntityManager.Title
        documentTitle={Shop.Admin.NewAffiliateConfiguration}
        title={Shop.Admin.NewAffiliateConfiguration}
      />
      <Content>
        <AffiliateMerchantForm
          disabled={fetching || state.attachmentInputProps.isUploading}
          state={state}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      </Content>
    </EntityManager>
  );
};

export default withRouter(AddAffiliateMerchant);
