import { TitleBar } from '@procurenetworks/procure-component-library';
import PageTitle from 'app/ui-components/Title';

interface Props {
  title: string;
  documentTitle?: string;
  titleWrapperClass?: string;
  hasTopDivider?: boolean | undefined;
}

function EntityManagerTitle(props: Props) {
  const { title, documentTitle, titleWrapperClass, hasTopDivider } = props;

  return (
    <>
      <TitleBar className={titleWrapperClass} hasTopDivider={hasTopDivider} title={title} />
      {documentTitle ? <PageTitle title={documentTitle} /> : undefined}
    </>
  );
}

export default EntityManagerTitle;
