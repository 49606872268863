import { useCallback, useEffect, useMemo, useState } from 'react';
import { SnackbarService } from 'app/components/Snackbar';

import usePagination from '../../../../../../hooks/usePagination';
import Assets from '../../../../../../i18n/Assets';
import {
  CreateCheckInTransactionInput,
  DateFilter,
  TransactionEntityTypeEnum,
  TransactionSchema,
  TransactionStatusEnum,
  TransactionSubTypeEnum,
  TransactionTypeEnum,
} from '../../../../../../types/schema';
import { getCurrentTimeZoneValue } from '../../../../../../utils/timezone';
import useEntityManager from '../../../../../components/EntityManager/useEntityManager';
import { useCreateCheckInTransactionMutation } from '../../graphql/mutations/generated/createCheckInTransaction';
import { useCheckInTransactionTableQuery } from '../../graphql/query/generated/checkInTransactionTable';
import { removeExtraSpacesAndNewlines } from 'app/utils/removeMultipleSpaces';

export type CheckInAssetRowType = TransactionSchema & {
  editableFields: {
    actual: string | number;
    meta: {
      note: string;
    };
  };
};

type TransactionData = {
  open: boolean;
  transaction: CreateCheckInTransactionInput | null;
  message: string | null;
};

interface FilterState {
  sourceSiteIds: string[];
  actorIds: string[];
  sourceLocationIds: string[];
  dueDate: Date | null;
  destinationSiteIds: string[];
  destinationLocationIds: string[];
}

const useCheckInAssetsState = () => {
  const { table, search } = useEntityManager({
    selection: false,
  });

  const [rows, setRows] = useState<CheckInAssetRowType[]>([]);
  const [transactionData, setTransactionData] = useState<TransactionData>({
    open: false,
    transaction: null,
    message: null,
  });

  const [filterState, _setFilterState] = useState<FilterState>({
    sourceSiteIds: [],
    actorIds: [],
    sourceLocationIds: [],
    dueDate: null,
    destinationSiteIds: [],
    destinationLocationIds: [],
  });

  const setFilterState = useCallback(
    (nextState: Partial<FilterState>) => {
      _setFilterState((prevState) => ({ ...prevState, ...nextState }));
    },
    [_setFilterState],
  );

  const dueDateFilter = useMemo(() => {
    if (!filterState.dueDate) {
      return undefined;
    }
    const startTime = new Date(filterState.dueDate);
    startTime.setUTCDate(filterState.dueDate.getDate() - 1);
    startTime.setUTCHours(23, 59, 59, 999);

    const endTime = new Date(filterState.dueDate);
    endTime.setUTCDate(filterState.dueDate.getDate() + 1);
    endTime.setUTCHours(0, 0, 0, 0);

    return {
      timezone: getCurrentTimeZoneValue(),
      absolute: {
        endTime: endTime.toISOString(),
        startTime: startTime.toISOString(),
      },
    } as DateFilter;
  }, [filterState.dueDate]);

  const { fetching, data, onPrevPage, onNextPage, onReset } = usePagination(
    useCheckInTransactionTableQuery,
    {
      filters: {
        search: search.debouncedSearchText,
        types: [TransactionTypeEnum.Move],
        subTypes: [TransactionSubTypeEnum.Checkout],
        statuses: [TransactionStatusEnum.Pending],
        entityTypes: [TransactionEntityTypeEnum.Asset, TransactionEntityTypeEnum.AssetKit],
        sourceSiteIds: filterState.sourceSiteIds,
        actorIds: filterState.actorIds,
        sourceLocationIds: filterState.sourceLocationIds,
        dueDate: dueDateFilter,
        destinationSiteIds: filterState.destinationSiteIds,
        destinationLocationIds: filterState.destinationLocationIds,
      },
      sorts: table.state.sorts,
    },
    {
      edgeKey: 'transactions',
      pageSize: table.state.numberOfRowsPerPage,
      requestPolicy: 'network-only',
    },
  );

  const [{ fetching: creatingCheckInTransaction }, onCreateCheckInTransaction] =
    useCreateCheckInTransactionMutation();

  useEffect(() => {
    const rowsWithEditableFields: CheckInAssetRowType[] =
      data?.transactions?.edges?.map(({ node }) => {
        return {
          ...node,
          editableFields: {
            actual: '',
            meta: {
              note: '',
            },
          },
        } as CheckInAssetRowType;
      }) || [];
    setRows(rowsWithEditableFields);
  }, [data?.transactions?.edges, setRows]);

  const setEditableFields = useCallback(
    (rowId: string, data: any) => {
      let updatedRows: CheckInAssetRowType[] = rows.map((row) => {
        if (row.id === rowId) {
          return {
            ...row,
            editableFields: {
              ...row.editableFields,
              ...data,
            },
          };
        } else {
          return row;
        }
      });

      setRows(updatedRows);
    },
    [rows, setRows],
  );

  const onConfirmCheckInTransaction = useCallback(
    (transactionId: string) => {
      const transaction = rows.find((row) => row.id === transactionId);

      if (!transaction) {
        return;
      }

      const actual = transaction.editableFields.actual;
      const isEmptyActual = actual === '';
      const actualQuantity = !isEmptyActual ? Number(transaction.editableFields.actual) : undefined;
      const meta = transaction.editableFields.meta;
      const updatedMetaDetails = {
        note: removeExtraSpacesAndNewlines(transaction.editableFields.meta.note),
        formattedNote: transaction.editableFields.meta.note,
      };

      if (isEmptyActual || (actualQuantity && actualQuantity > transaction.quantity)) {
        setTransactionData({
          open: true,
          transaction: null,
          message: isEmptyActual
            ? Assets.FormValidationMessages.CheckInQuantityRequired
            : Assets.FormValidationMessages.CheckInMinQuantityRequired(transaction.quantity),
        });
        return;
      }

      setTransactionData({
        open: true,
        transaction: {
          checkedOutTransactionId: transaction.id,
          quantity: actualQuantity as number,
          meta: updatedMetaDetails,
        },
        message: Assets.FormValidationMessages.CheckInConfirmation,
      });
    },
    [rows, setTransactionData],
  );

  const onCancelTransaction = useCallback(() => {
    setTransactionData({
      open: false,
      transaction: null,
      message: null,
    });
  }, [setTransactionData]);

  const handleCreateCheckInTransaction = useCallback(() => {
    if (transactionData?.transaction) {
      onCreateCheckInTransaction({
        input: transactionData.transaction,
      }).then((response) => {
        if (response?.data?.createCheckInTransaction?.success) {
          SnackbarService.show({
            message: Assets.SuccessMessages.CheckInTransactionCreated,
          });
          setFilterState({
            actorIds: [],
            sourceSiteIds: [],
            sourceLocationIds: [],
            destinationSiteIds: [],
            destinationLocationIds: [],
          });
        } else {
          console.error('[Error in handleCreateCheckInTransaction]', response);
        }
        onCancelTransaction();
      });
    } else {
      onCancelTransaction();
    }
  }, [onCreateCheckInTransaction, transactionData, onCancelTransaction, setTransactionData]);

  return {
    table,
    rows,
    rowCount: data?.transactions?.totalCount || 0,
    search,
    fetching,
    onPrevPage,
    onNextPage,
    onReset,
    setEditableFields,
    creatingCheckInTransaction,
    handleCreateCheckInTransaction,
    transactionData,
    onConfirmCheckInTransaction,
    onCancelTransaction,
    filterState,
    setFilterState,
  };
};

export type UseCheckInAssetsStateReturnType = ReturnType<typeof useCheckInAssetsState>;
export default useCheckInAssetsState;
