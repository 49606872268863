import { useCallback, useMemo } from 'react';
import { cx } from '@emotion/css';
import { TitleBar } from '@procurenetworks/procure-component-library';
import { omit } from 'lodash';
import { SnackbarService } from 'app/components/Snackbar';
import routes from 'app/consts/routes';
import Partner from 'app/i18n/Partner';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import { titlebarStyles } from 'app/modules/components/EntityManager/styles';
import { canSwitchTenants } from 'app/modules/organizations/utils/canSwitchTenants';
import { PartnerTenantTypeEnum } from 'app/types/schema';
import Box from 'app/ui-components/Box';

import EditOrganizationForm from './components/EditOrganizationForm';
import { EditPartnerTenantInput } from './components/EditOrganizationForm/types';
import { useUpdatePartnerTenantMutation } from './graphql/mutations/generated/updatePartnerTenant';
import { useEditPartnerTenantsQuery } from './graphql/queries/generated/editPartnerTenants';

type Props = RouteComponentProps & { tenantId: string };

function EditPartner(props: Props) {
  const { tenantId, history } = props;
  const currentUser = useCurrentUser();
  const isSuperAdmin = canSwitchTenants(currentUser.workspacePermissions);

  const [{ data }] = useEditPartnerTenantsQuery({
    variables: { filters: { partnerTenantIds: tenantId ? [tenantId] : [] } },
    requestPolicy: 'network-only',
  });
  const [{ fetching: loading }, onUpdatePartnerTenant] = useUpdatePartnerTenantMutation();
  const tenantNode = data?.partnerTenants.edges?.[0]?.node;

  const partnerTenant = useMemo(() => {
    if (tenantNode) {
      const node = {
        partnerTenantId: tenantNode.id,
        organization: tenantNode.tenant.id,
        accessibleCategoryIds: tenantNode.accessibleCategoryIds,
        allowedUserIds: tenantNode.allowedUserIds,
      };
      return node as unknown as EditPartnerTenantInput;
    }
    return null;
  }, [data?.partnerTenants.edges]);

  const onSubmit = useCallback(
    (input: Omit<EditPartnerTenantInput, 'partnerTenantId' | 'organization'>) => {
      const inputValues = omit(input, 'organization');
      onUpdatePartnerTenant({ inputs: { ...inputValues, partnerTenantId: tenantId } }).then(
        (response) => {
          if (response.data?.updatePartnerTenants.success) {
            SnackbarService.show({ message: 'This organization has been successfully updated.' });
            history.replace(routes.Partners());
          }
        },
      );
    },
    [history, onUpdatePartnerTenant, tenantId],
  );

  const onCancel = useCallback(() => {
    history.replace(routes.Partners());
  }, [history]);

  if (!partnerTenant) {
    return null;
  }

  return (
    <Box className="bg-white px-24 pt-0 pb-16">
      <Box className={cx('max-w-[768px] space-y-40', titlebarStyles)}>
        <TitleBar title={Partner.EditPartner} hasTopDivider />
        <EditOrganizationForm
          childTenantId={tenantNode?.childTenantId}
          defaultValues={partnerTenant}
          disabled={tenantNode?.partnerType === PartnerTenantTypeEnum.Parent}
          isSuperAdmin={isSuperAdmin}
          loading={loading}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      </Box>
    </Box>
  );
}

export default withRouter(EditPartner);
