import { useCallback, useMemo } from 'react';
import { Button, CustomIcons } from '@procurenetworks/procure-component-library';
import { SnackbarService } from 'app/components/Snackbar';
import routes from 'app/consts/routes';
import usePagination from 'app/hooks/usePagination';
import Common from 'app/i18n/Common';
import Wishlist from 'app/i18n/Wishlist';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import { useCurrentUserWithHelpers } from 'app/modules/auth/hooks/useCurrentUser';
import EntityManager from 'app/modules/components/EntityManager';
import useMultiSelectEntityManager from 'app/modules/components/EntityManager/useMultiSelectEntityManager';
import Stack from 'app/ui-components/Stack';

import { useWishlistsQuery } from '../createList/graphql/queries/generated/wishlist';
import { useDeleteWishlistMutation } from './graphql/mutations/generated/deleteWishlists';
import useSearchSuggestion from './hook/useSearchSuggestion';
import { getWishlistTableColumns } from './utils/columns';
import { Box, InputAdornment } from '@mui/material';

const WishlistListView = (props: RouteComponentProps) => {
  const { history } = props;

  const { user } = useCurrentUserWithHelpers();

  const { searchSuggestions, setSearchValue } = useSearchSuggestion();

  const [{ fetching: deletingWishlists }, executeDeleteWishlists] = useDeleteWishlistMutation();

  const onDelete = useCallback(
    (ids: string[]) => {
      if (!ids.length) {
        return;
      }

      executeDeleteWishlists({
        input: {
          wishlistIds: ids,
        },
      }).then((response) => {
        if (response?.data?.deleteWishlists?.success) {
          SnackbarService.show({
            message: Wishlist.SuccessMessages.WishlistDeleted,
          });
        } else {
          SnackbarService.showError({
            message:
              response.error?.message.replace('[GraphQL] ', '') ||
              Wishlist.ErrorMessages.WishlistDeleteError,
          });
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [executeDeleteWishlists],
  );

  const { search, table } = useMultiSelectEntityManager({
    onDelete,
  });

  const { fetching, data, onNextPage, onPrevPage, onReset } = usePagination(
    useWishlistsQuery,
    {
      filters: {
        search: search.debouncedSearchText.replace(/\s+/g, ' '),
        tenantIds: [user.tenantId],
      },
      sorts: table.state.sorts,
    },
    {
      edgeKey: 'wishlists',
      pageSize: table.state.numberOfRowsPerPage,
      requestPolicy: 'network-only',
    },
  );

  const columns = getWishlistTableColumns();

  const rows = useMemo(() => {
    return data?.wishlists.edges?.map(({ node }) => node) || [];
  }, [data?.wishlists.edges]);

  return (
    <div data-testid="wishlist-view-page">
      <EntityManager className="rounded-bl-[12px] rounded-br-[12px] !px-[24px] pt-0">
        <EntityManager.Title
          documentTitle={Wishlist.ListView.WishlistSearch}
          title={Wishlist.ListView.WishlistSearch}
          hasTopDivider
        />

        <Button
          classes="!mt-16 w-[180px] !px-[12px]"
          theme="success"
          onClick={() => {
            history.push(routes.WishlistCreateList());
          }}>
          {Wishlist.FormActions.NewWishlist}
        </Button>
        <EntityManager.Add
          label={Wishlist.FormActions.NewWishlist}
          to={routes.WishlistCreateList()}
        />
        <Box
          sx={{
            boxShadow:
              '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
          }}
          className="mt-[24px] rounded-[12px] border border-grey-300 pb-[24px]">
          <EntityManager.MultiSelectTable
            ignoreRelayPagination
            pagination
            persistSelectionData
            actions={table.actions}
            columns={columns}
            data={rows}
            loading={fetching || deletingWishlists}
            persistKey="wishlists"
            setState={table.setState}
            state={table.state}
            total={data?.wishlists?.totalCount || 0}
            onNextPage={onNextPage}
            onPrevPage={onPrevPage}
            onReset={onReset}
            filterNodes={
              <Stack justifyContent="between">
                <EntityManager.Search
                  isDropdown
                  removedSearchQoutedValue
                  suggestionData={searchSuggestions || []}
                  onChangeSearch={setSearchValue}
                  {...search}
                  autoFocus
                  placeholder="Search"
                  startAdornment={
                    <InputAdornment position="start" className="mt-[4px] ml-[5px]">
                      <CustomIcons.SearchIcon />
                    </InputAdornment>
                  }
                />
              </Stack>
            }
            paginationWrapperClass="p-[24px]"
          />

          <Stack className="mt-[16px] px-[24px]" justifyContent="end">
            <Button
              classes="!ml-[4px] !px-[24px]"
              disabled={!table.state.selectedRowIds.length}
              theme="danger"
              onClick={table.actions.onShowDeleteConfirmation}>
              {Common.Actions.Delete}
            </Button>
          </Stack>
        </Box>
      </EntityManager>
    </div>
  );
};

export default withRouter(WishlistListView);
