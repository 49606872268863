import { useContext, useMemo } from 'react';
import Orders from 'app/i18n/Orders';
import EntityManager from 'app/modules/components/EntityManager';
import OrderBillToField from 'app/modules/orders/components/OrderDeliveryDetailForm/components/OrderBillToField';
import OrderDateField from 'app/modules/orders/components/OrderDeliveryDetailForm/components/OrderDateField';
import OrderDeliverToField from 'app/modules/orders/components/OrderDeliveryDetailForm/components/OrderDeliverToField';
import OrderDepartmentField from 'app/modules/orders/components/OrderDeliveryDetailForm/components/OrderDepartmentField';
import OrderDueDateField from 'app/modules/orders/components/OrderDeliveryDetailForm/components/OrderDueDateField';
import OrderIdField from 'app/modules/orders/components/OrderDeliveryDetailForm/components/OrderIdField';
import OrderRequestorEmailField from 'app/modules/orders/components/OrderDeliveryDetailForm/components/OrderRequestorEmailField';
import OrderRequestorNameField from 'app/modules/orders/components/OrderDeliveryDetailForm/components/OrderRequestorNameField';
import OrderShipToField from 'app/modules/orders/components/OrderDeliveryDetailForm/components/OrderShipToField';
import OrderTitleField from 'app/modules/orders/components/OrderDeliveryDetailForm/components/OrderTitleField';
import { UseOrderDetailsReturnType } from '../../views/OrderDetail/hook/useOrderDetails';
import { ORDER_VIEW } from '../../views/OrderDetail/type';
import ExternalOrderViewSelect from '../OrderDeliveryDetailForm/components/ExternalOrderViewSelect';
import { OrderDetailContext } from '../../provider/OrderDetailsContext';
import useOrderEditPermissionCheck from '../../hook/useOrderEditPermissionCheck';

interface Props {
  state: UseOrderDetailsReturnType['state'];
  disabled?: boolean;
}

const OrderDetailsForm = (props: Props) => {
  const { state, disabled } = props;
  const { formState, orderView, orderDetail } = state;

  const { isOrderDetailsEditable } = useOrderEditPermissionCheck({
    orderDetail,
  });

  const { isOrderDetailEditable, isOrderDetailEditableWithPackedStatus } = useMemo(() => {
    const isEditOrderView = orderView === ORDER_VIEW.EDIT_ORDER_VIEW;

    if (!isEditOrderView) {
      return {
        isOrderDetailEditable: false,
        isOrderDetailEditableWithPackedStatus: false,
      };
    }

    const { isOrderDetailEditable, isOrderDetailEditableWithPackedStatus } =
      isOrderDetailsEditable();

    return {
      isOrderDetailEditable,
      isOrderDetailEditableWithPackedStatus,
    };
  }, [orderView, isOrderDetailsEditable, orderDetail]);

  const { isParentTenantOrderView } = useContext(OrderDetailContext);

  return (
    <div>
      <EntityManager.Title
        documentTitle={Orders.OrderDetailDocumentTitle}
        title={Orders.OrderDetails}
      />
      <form className="mt-20">
        <OrderIdField formState={formState} />
        <OrderTitleField
          disabled={!isOrderDetailEditable || isOrderDetailEditableWithPackedStatus || disabled}
          formState={formState}
        />
        <OrderDateField formState={formState} />
        <OrderDueDateField disabled formState={formState} />
        <OrderRequestorNameField formState={formState} />
        <OrderRequestorEmailField formState={formState} />

        {!isParentTenantOrderView ? (
          <>
            <OrderDepartmentField
              disabled={!isOrderDetailEditable || isOrderDetailEditableWithPackedStatus || disabled}
              formState={formState}
            />
            <OrderShipToField
              isRequired
              disabled={!isOrderDetailEditable || isOrderDetailEditableWithPackedStatus || disabled}
              formState={formState}
            />
            <OrderDeliverToField
              isRequired
              disabled={!isOrderDetailEditable || isOrderDetailEditableWithPackedStatus || disabled}
              formState={formState}
            />
            <OrderBillToField
              isRequired
              disabled={!isOrderDetailEditable || isOrderDetailEditableWithPackedStatus || disabled}
              formState={formState}
            />
          </>
        ) : (
          <>
            <ExternalOrderViewSelect
              isRequired
              label={Orders.FormLabels.Department}
              selectedValue={orderDetail?.department || undefined}
              options={orderDetail.department ? [orderDetail.department] : []}
            />
            <ExternalOrderViewSelect
              isRequired
              label={Orders.FormLabels.ShipTo}
              selectedValue={orderDetail.destinationSite}
              options={[orderDetail.destinationSite]}
            />
            <ExternalOrderViewSelect
              isRequired
              label={Orders.FormLabels.DeliverTo}
              selectedValue={orderDetail.deliverTo}
              options={[orderDetail.deliverTo]}
            />
            <ExternalOrderViewSelect
              isRequired
              label={Orders.FormLabels.BillTo}
              selectedValue={orderDetail.billToSite}
              options={[orderDetail.billToSite]}
            />
          </>
        )}
      </form>
    </div>
  );
};

export default OrderDetailsForm;
