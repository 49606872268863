import { useCallback, useMemo, useRef } from 'react';
import { cx } from '@emotion/css';
import { TitleBar } from '@procurenetworks/procure-component-library';
import { SnackbarService } from 'app/components/Snackbar';
import routes from 'app/consts/routes';
import Role from 'app/i18n/Role';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import { titlebarStyles } from 'app/modules/components/EntityManager/styles';
import { canSwitchTenants } from 'app/modules/organizations/utils/canSwitchTenants';
import {
  AllowedPermissionActionsEnum,
  AllowedPermissionsSubjectEnum,
  CreateRoleInput,
  RoleTypeEnum,
} from 'app/types/schema';
import Box from 'app/ui-components/Box';

import { isAccessible } from '../../../../components/AccessControl';
import RoleForm from '../AddRole/components/AddRoleForm';
import { useUpdateRoleMutation } from './graphql/mutations/generated/editRole';
import { useEditRoleDataQuery } from './graphql/queries/generated/editRoleData';
import { removeExtraSpacesAndNewlines } from 'app/utils/removeMultipleSpaces';

type Props = RouteComponentProps & { roleId: string };

const EditRole = (props: Props) => {
  const { roleId, history } = props;
  const isSystemRole = useRef(false);

  const { workspacePermissions } = useCurrentUser();

  const canEdit = isAccessible(
    workspacePermissions,
    AllowedPermissionActionsEnum.Edit,
    AllowedPermissionsSubjectEnum.Role,
  );

  const [{ fetching: disabled }, onUpdateRole] = useUpdateRoleMutation();
  const [{ data }] = useEditRoleDataQuery({
    variables: { filters: { roleIds: [roleId] } },
    requestPolicy: 'network-only',
  });

  const role = useMemo<CreateRoleInput | null>(() => {
    const roleNode = data?.roles.edges?.[0]?.node;

    if (roleNode) {
      isSystemRole.current = roleNode.type === RoleTypeEnum.System;
      return {
        name: roleNode.name,
        description: roleNode.formattedDescription || roleNode.description || '',
        metadata: { configuredPermissions: roleNode.metadata?.configuredPermissions || [] },
      };
    }

    return null;
  }, [data?.roles.edges]);

  const onSubmit = useCallback(
    ({ name, description, metadata: metadataInput }: CreateRoleInput) => {
      const metadata = metadataInput || { configuredPermissions: [] };

      onUpdateRole({
        input: {
          metadata,
          roleId,
          name,
          description: removeExtraSpacesAndNewlines(description || ''),
          formattedDescription: description,
        },
      }).then((response) => {
        if (response.data?.updateRole.role?.id) {
          SnackbarService.show({ message: 'This role has been successfully updated.' });
          history.replace(routes.Roles());
        }
      });
    },
    [history, onUpdateRole, roleId],
  );

  const onCancel = useCallback(() => {
    history.replace(routes.Roles());
  }, [history]);

  if (!role) {
    return null;
  }

  return (
    <Box className="bg-white px-24 pt-0 pb-16">
      <Box className={cx('max-w-[768px] space-y-40', titlebarStyles)}>
        <TitleBar title={Role.EditRole} hasTopDivider />
        <RoleForm
          defaultValues={role}
          disableEdit={!canEdit}
          disabled={disabled}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      </Box>
    </Box>
  );
};

export default withRouter(EditRole);
